<template>
  <v-row v-if="reportArray" class="grey lighten-4">
    <v-col v-for="report2d in reportArray" :key="report2d.label">
      <v-card>
        <v-card-text>
          <div class="text-caption">{{ report2d.label }}</div>
          <hr />
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left"
                    v-for="header in report2d.report.headers"
                    :key="header"
                  >
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in report2d.report.items" :key="index">
                  <td v-for="(value, rowIndex) in row" :key="rowIndex">
                    {{ value }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MultiLabelReport",
  props: {
    reportArray: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    metricFormatter: function (value) {
      if (typeof value == "number") return value.toPrecision(3);
      return value;
    },
  },
};
</script>

<style></style>
