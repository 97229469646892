<template>
  <v-row>
    <v-col cols="8">
      <div class="ma-2">
        <validation-observer ref="observer">
          <form @submit.prevent="submit">
            <div v-if="mode == 'api'">
                <validation-provider
                v-slot="{ errors }"
                name="hostname"
                rules="required"
              >
                <v-text-field
                  name="hostname"
                  label="HostName*"
                  v-model="searchConfig.hostname"
                  :error-messages="errors"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="search_endpoint"
                rules="required"
              >
                <v-text-field
                  name="search_endpoint"
                  label="Search Endpoint*"
                  v-model="searchConfig.search_endpoint"
                  :error-messages="errors"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="metadata_endpoint"
                rules="required"
              >
                <v-text-field
                  name="metadata_endpoint"
                  label="Metatdata Endpoint*"
                  v-model="searchConfig.metadata_endpoint"
                  :error-messages="errors"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="health_endpoint"
                rules="required"
              >
                <v-text-field
                  name="health_endpoint"
                  label="Health Endpoint*"
                  v-model="searchConfig.health_endpoint"
                  :error-messages="errors"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="document_endpoint"
                rules="required"
              >
                <v-text-field
                  name="document_endpoint"
                  label="Document Endpoint*"
                  v-model="searchConfig.document_endpoint"
                  :error-messages="errors"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="auth_method"
                rules="required"
              >
                <v-select
                  name="type"
                  label="Authentication*"
                  :items="toolkitTypeChoices"
                  v-model="searchConfig.auth_method"
                  :error-messages="errors"
                  required
                >
                </v-select> 
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="api_key"
                rules="required"
              >
                <v-text-field
                  name="api_key"
                  label="API Key*"
                  v-model="searchConfig.api_key"
                  :append-icon="showAPIKey ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showAPIKey = !showAPIKey"
                  :error-messages="errors"
                  :type="showAPIKey ? 'text' : 'password'"
                  required
                ></v-text-field>
              </validation-provider>
            </div>
            <div v-else>
              <validation-provider
                v-slot="{ errors }"
                name="placeholder_msg"
                rules="required"
              >
                <v-text-field
                  name="placeholder_msg"
                  label="Query Placeholder Message*"
                  v-model="searchConfig.placeholder_msg"
                  :error-messages="errors"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="min_query_words"
                rules="required"
              >
                <v-text-field
                  name="min_query_words"
                  label="Mininum Query Words*"
                  v-model="searchConfig.min_query_words"
                  :error-messages="errors"
                  required
                  type="number"
                >
                </v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="max_query_words"
                rules="required"
              >
                <v-text-field
                  name="max_query_words"
                  label="Maximum Query Words*"
                  v-model="searchConfig.max_query_words"
                  :error-messages="errors"
                  required
                  type="number"
                >
                </v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="feedback_relevance_enabled"
                rules="required"
              >
                <v-checkbox
                  dense
                  name="feedback_relevance_enabled"
                  label="Enable Relevance Feedback"
                  v-model="searchConfig.feedback_relevance_enabled"
                  :error-messages="errors"
                >
                </v-checkbox>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="feedback_freshness_enabled"
                rules="required"
              >
                <v-checkbox
                  dense
                  name="feedback_freshness_enabled"
                  label="Enable Freshness Feedback"
                  v-model="searchConfig.feedback_freshness_enabled"
                  :error-messages="errors"
                >
                </v-checkbox>
              </validation-provider>
            </div>
            <v-btn type="submit" color="primary" :loading="loading"
              >Save</v-btn>
            <v-btn class="ml-2" @click="nextStep()">Next</v-btn>
          </form>
        </validation-observer>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "CreateOrEditSearchConfig",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    mode: {
      type: String,
      required: false,
    }
  },
  watch:{
    id: function(newId){
      this.checkForCreationMode();
    },
    mode: function(newMode){
      console.log(`mode changed to ${this.mode}`);
    }
  },
  data(){
    return {
      toolkitTypeChoices: [
        { text: "API Key", value: "api_key" },
        { text: "None", value: "none" },
      ],
      creationMode: true,
      showAPIKey: false,
      searchConfig: {
        // api settings
        hostname: "http://127.0.0.1:9090",
        search_endpoint: "/search",
        metadata_endpoint: "/metadata",
        health_endpoint: "/health",
        document_endpoint: "/document",
        auth_method: "api_key",
        api_key: null,
        // ui settings
        feedback_relevance_enabled: true,
        feedback_freshness_enabled: false,
        // feedback_recommendation_enabled: false
        max_query_words: 25,
        min_query_words: 2,
        placeholder_msg: "Enter your search query",
        // other settings
        toolkitId: this.id,
      },
      loading: false,
    }
  },
  computed: mapGetters(["getSearchkitConfig"]),
  async created(){
    // this.checkForCreationMode();
  },
  methods:{
    async checkIfSearchConfigExists(){
      try{
        await this.$store.dispatch("fetchSearchkitByToolkitIdV2", this.id);
        this.searchConfig = this.getSearchkitConfig;
        return false;
      }
      catch(err){
        return true;
      }
    },
    async checkForCreationMode(){
    // check if searchkit exists by looking for active id in store
      this.creationMode = this.id == null;
      if(!this.creationMode){
        // check if searchkit exists by querying API via toolkit id
        this.creationMode = await this.checkIfSearchConfigExists();
      }
    },
    async submit(){
      this.loading = true;
      try{
        if(this.creationMode){
          this.searchConfig.toolkitId = this.id;
          await this.$store.dispatch("createSearchkit", this.searchConfig);
          this.creationMode = false;
        }
        else{
          this.searchConfig.toolkitId = this.id;
          await this.$store.dispatch("updateSearchkit", this.searchConfig);
        }
        this.$store.commit(
          "showNotification",
          "Search Configuration details successfully saved"
        );
      }
      catch(err){
        if (err.response.status == 400) {
          this.$refs.observer.setErrors(err.response.data);
        } else {
          this.$store.commit("showNotification", "Something went wrong");
          this.closeDialog();
        }
      }
      finally{
        this.loading = false;
      }
    },
    nextStep(){
      this.$emit("step-completed", true);
    }
  }
}
</script>

<style>

</style>