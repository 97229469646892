<template>
  <v-row>
    <v-col cols="8">
      <div class="ma-2">
        <div v-if="flagTestingIssue">
          <v-alert
            dense
            outlined
            type="error"
          >
            We're unable to fetch the API configuration details. Please try updating the step 2 again.
          </v-alert>
        </div>
        <div>
          <div>
            Start integration tests 
            <v-btn
              color="primary"
              @click="testEndpoints()"
              :loading="uiFlags.testing"
              small
              class="ml-1"
            >
              Start
            </v-btn>
          </div>
          <v-list subheader two-line>
            <EndpointTest v-bind:endpointTest="endpointTests.health"/>
            <EndpointTest v-bind:endpointTest="endpointTests.metadata"/>
            <EndpointTest v-bind:endpointTest="endpointTests.search"/>
            <EndpointTest v-bind:endpointTest="endpointTests.document"/>
          </v-list>
          <v-alert
            prominent
            type="success"
            v-if="uiFlags.ready"
          >
            <v-row align="center">
              <v-col class="grow">
                Your search API has passeed (or almost passed) all the tests. You can now finish the wizard and start using your search API.
              </v-col>
              <v-col class="shrink">
                <v-btn @click="finish()">Finish</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import EndpointTest from "../../components/toolkits/EndpointTest.vue";
import { joinUrls } from "@/store/common/utils";
import axios from "axios";
import { validateMetadata, validateSearch, validateDocument } from "./schemaValidation/validation.js";

import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "IntegrationTests",
  components: {
    ValidationProvider,
    ValidationObserver,
    EndpointTest
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  watch:{
    id: async function(newId){
      await this.checkForSearchConfig();
    }
  },
  data(){
    return {
      flagTestingIssue: false,
      creationMode: true,
      uiFlags: {
        testing: false,
        ready: false,
      },
      endpointTests:{
        health: {
          title: "Health",
          step: 1,
          status: "pending",
          msg: null
        },
        metadata: {
          title: "Metadata",
          step: 2,
          status: "pending",
          msg: null
        },
        search: {
          title: "Search",
          step: 3,
          status: "pending",
          msg: null
        },
        document: {
          title: "Document",
          step: 4,
          status: "pending",
          msg: null
        },
      },
      searchConfig: null
    }
  },
  computed: mapGetters(["getSearchkitConfig"]),
  created(){
  },
  methods:{
    async checkForSearchConfig(){
      if(this.id == null){
        this.flagTestingIssue = true;
        return;
      }
      this.flagTestingIssue = false;
      if(this.getSearchkitConfig != null){
        console.log('fetching searchkit by toolkit id for integration tests');
        await this.$store.dispatch("fetchSearchkitByToolkitId", this.id);
        this.searchConfig = this.getSearchkitConfig;
      }
    },
    async testHealth(){
      this.endpointTests.health.status = "in_progress";
      this.endpointTests.health.statusCode = null;
      // check if health endpoint returns 200
      let response = null;
      try{
        response = await axios.get(joinUrls(this.searchConfig.hostname, this.searchConfig.health_endpoint));
        console.log(response);
        if (response.status == 200) {
          this.endpointTests.health.status = "success";
          this.endpointTests.health.statusCode = response.status;
        }
      }
      catch(error){
        console.log(error);
        this.endpointTests.health.status = "error";
        try { 
          const statusCode = response.status;
          this.endpointTests.health.msg = `Test failed with status code ${statusCode}`
        }
        catch{
          this.endpointTests.health.msg = `Test failed with inexplicable error (possibly CORS issue)`
        }
      }
    },
    async testMetadata(){
      let metadata = null;
      let flag200Ok = false;
      this.endpointTests.metadata.status = "in_progress";
      this.endpointTests.metadata.statusCode = null;
      // check if metadata endpoint returns 200
      let response = null;
      try{
        response = await axios.get(joinUrls(this.searchConfig.hostname, this.searchConfig.metadata_endpoint), {
          headers: {
            Authorization: "token " + this.searchConfig.api_key,
          },
        });
        console.log(response);
        flag200Ok = response.status == 200;
      }
      catch(error){
        console.log(error);
        this.endpointTests.metadata.status = "error";
        try { 
          const statusCode = response.status;
          this.endpointTests.metadata.msg = `Test failed with status code ${statusCode}`
        }
        catch{
          this.endpointTests.metadata.msg = `Test failed with inexplicable error`
        }
      }
      if(flag200Ok){
        // now test the metadata schema
        if(validateMetadata(response.data)){
          this.endpointTests.metadata.status = "success";
          metadata = response.data;
        }
        else{
          this.endpointTests.metadata.status = "error";
          this.endpointTests.metadata.msg = `Test failed during API schema validation`
        }
      }
      return metadata;
    },
    async testSearch(metadata){
      let searchResult = null;
      let flag200Ok = false;
      this.endpointTests.search.status = "in_progress";
      this.endpointTests.search.statusCode = null;
      const requestBody = {
        "query": "*",
        "filters": {},
        "limit": 1,
        "offset": 0
      }
      if(metadata.modes != null && metadata.modes.length > 0){
        requestBody["mode"] = metadata.modes[0].value;
      }
      if(metadata.filters != null && metadata.filters.length > 0){
        for(let i=0; i<metadata.filters.length; i++){
          const filter = metadata.filters[i];
          if(filter.pre_selected_value != null){
            if(filter.multi_select){
              requestBody.filters[filter.name] = filter.choices.map(choice => choice.value);
            }
            else{
              requestBody.filters[filter.name] = filter.pre_selected_value;
            }
          }
        }
      }
      // check if search endpoint returns 200
      let response = null;
      try{
        console.log(requestBody);
        const url = joinUrls(this.searchConfig.hostname, this.searchConfig.search_endpoint);
        response = await axios.post(url, requestBody, {
          headers: {
            Authorization: "token " + this.searchConfig.api_key,
          },
        });
        console.log(response);
        flag200Ok = response.status == 200;
      }
      catch(error){
        console.log(error);
        this.endpointTests.search.status = "error";
        try { 
          const statusCode = response.status;
          this.endpointTests.search.msg = `Test failed with status code ${statusCode}`
        }
        catch{
          this.endpointTests.search.msg = `Test failed with inexplicable error`
        }
      }
      if(flag200Ok){
        // now test the metadata schema
        if(validateSearch(response.data)){
          this.endpointTests.search.status = "success";
          searchResult = response.data;
        }
        else{
          this.endpointTests.search.status = "error";
          this.endpointTests.search.msg = `Test failed during API schema validation`
        }
      }
      return searchResult;
    },
    async testDocument(searchResults){
      let flag200Ok = false;
      if(searchResults.count ==0){
        this.endpointTests.document.status = "skipped";
        this.endpointTests.document.msg = "This test was skipped due to empty results";
        return;
      }
      const documentId = searchResults.results[0].record_id;
      this.endpointTests.document.status = "in_progress";
      this.endpointTests.document.statusCode = null;
      // check if document endpoint returns 200
      let response = null;
      try{
        // const url = new URL(this.searchConfig.document_endpoint, this.searchConfig.hostname);
        const url = joinUrls(this.searchConfig.hostname, this.searchConfig.document_endpoint);
        response = await axios.post(url, {
          "record_id": documentId
        },
          { headers: { Authorization: "token " + this.searchConfig.api_key }
        });
        console.log(response);
        flag200Ok = response.status == 200;
      }
      catch(error){
        console.log(error);
        this.endpointTests.document.status = "error";
        try { 
          const statusCode = response.status;
          this.endpointTests.document.msg = `Test failed with status code ${statusCode}`
        }
        catch{
          this.endpointTests.document.msg = `Test failed with inexplicable error`
        }
      }
      if(flag200Ok){
        // now test the metadata schema
        if(validateDocument(response.data)){
          this.endpointTests.document.status = "success";
        }
        else{
          this.endpointTests.document.status = "error";
          this.endpointTests.document.msg = `Test failed during API schema validation`
        }
      }
    },
    async testEndpoints(){
      // prepare testing data
      const endpoints = [
        {
          name: "health",
          url: this.searchConfig.health_endpoint
        },
        {
          name: "metadata",
          url: this.searchConfig.metadata_endpoint
        },
        {
          name: "search",
          url: this.searchConfig.search_endpoint
        },
        {
          name: "document",
          url: this.searchConfig.document_endpoint
        }
      ]
      // resest all the flags
      this.uiFlags.testing = true;
      this.uiFlags.ready = false;
      // reset all the endpoint tests
      for(let i=0; i<endpoints.length; i++){
        const endpointName = endpoints[i].name;
        this.endpointTests[endpointName].status = "pending";
      }
      // perform testing
      // check health endpoint
      await this.testHealth();
      if (this.endpointTests.health.status == "error") {
        this.uiFlags.testing = false;
        return;
      }
      // check metadata endpoint
      const metadata = await this.testMetadata();
      if (this.endpointTests.metadata.status == "error") {
        this.uiFlags.testing = false;
        return;
      }
      // console.log(metadata);
      // check search endpoint
      const searchResults = await this.testSearch(metadata);
      if (this.endpointTests.search.status == "error") {
        this.uiFlags.testing = false;
        return;
      }
      // console.log(searchResults);
      // check document endpoint
      await this.testDocument(searchResults);
      if (this.endpointTests.document.status == "error") {
        this.uiFlags.testing = false;
        return;
      }
      // all endpoints are tested
      this.uiFlags.testing = false;
      this.uiFlags.ready = true;
    },
    finish(){
      this.$store.commit("SET_ACTIVE_TOOLKIT_CREATION_ID", null);
      // navigate to the search page
      this.$router.push({ name: "toolkits" });
    }
  }
}
</script>

<style>

</style>