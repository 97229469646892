import { TrackingClient } from "@/store/common/axio-api";
import * as utils from "@/store/common/utils";

const state = {
  experiments: null,
  experiment: null,
  experimentColumns: null,
  // selectedColumns: null,
};

const getters = {
  getExperiments: (state) => state.experiments,
  getExperiment: (state) => state.experiment,
  getExperimentColumns: (state) => state.experimentColumns,
  // getSelectedColumns: (state) => state.selectedColumns,
};

const mutations = {
  SET_EXPERIMENTS: (state, experiments) => (state.experiments = experiments),
  SET_EXPERIMENT: (state, experiment) => (state.experiment = experiment),
  SET_EXPERIMENT_COLUMNS: (state, columns) =>
    (state.experimentColumns = columns),
  // SET_SELECTED_COLUMNS: (state, columns) => (state.selectedColumns = columns),
};

const actions = {
  fetchExperimentsByProject({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      var baseUrl = utils.prepareURL(
        `projects/${options.id}/experiments/?`,
        options
      );
      TrackingClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_EXPERIMENTS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchExperiment({ commit, rootGetters }, id) {
    console.log(`experiments/${id}/`);
    return new Promise((resolve, reject) => {
      if (id == null) {
        commit("SET_EXPERIMENT", null);
        resolve(null);
      } else
        TrackingClient.get(`experiments/${id}/`, rootGetters.getAuthHeaders)
          .then(function (response) {
            commit("SET_EXPERIMENT", response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          });
    });
  },
  editExperiment({ rootGetters }, experiment) {
    return new Promise((resolve, reject) => {
      TrackingClient.put(
        `experiments/${experiment.id}/`,
        experiment.data,
        rootGetters.getAuthHeaders
      )
        .then(function (reponse) {
          resolve(reponse);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  validateExperimentDeletion({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      TrackingClient.post(`projects/${options.project_id}/experiments-index/validate-deletion/`,
        options, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchExperimentsIndexByProject({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      var baseUrl = utils.prepareURL(
        `projects/${options.id}/experiments-index/index/?`,
        options
      );
      TrackingClient.post(baseUrl, options, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_EXPERIMENTS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  deleteExperiments({ rootGetters }, options) {
    return new Promise((resolve, reject) => {
      TrackingClient.post(`projects/${options.project_id}/experiments-index/delete/?`, options, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
