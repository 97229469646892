class ResizeHandler {

  constructor(leftPane, rightPane) {
    this.leftPane = leftPane;
    this.rightPane = rightPane;

    this.isResizing = false;
    this.lastDownX = 0;
  }

  onMouseDown = (e) => {
    this.isResizing = true;
    this.lastDownX = e.clientX;
  }

  onMouseUp = () => {
    this.isResizing = false;
  }

  onMouseMove = (e) => {
    if (this.isResizing) {
      const offset = e.clientX - this.lastDownX;
      this.lastDownX = e.clientX;
      this.leftPane.style.width = `${this.leftPane.offsetWidth + offset}px`;
      this.rightPane.style.width = `${this.rightPane.offsetWidth - offset}px`;
    }
  }
}

export default ResizeHandler;