<template>
  <v-card>
    <div class="card-controls">
      <v-btn icon small v-if="config.custom" @click="deleteChart()"
        ><v-icon small>mdi-delete</v-icon></v-btn
      >
      <v-btn
        icon
        small
        v-if="config.custom"
        @click="$emit('edit', config.uri.id)"
        ><v-icon small>mdi-pencil</v-icon></v-btn
      >
      <v-btn icon small @click="resize(-1)"
        ><v-icon small>mdi-arrow-collapse</v-icon></v-btn
      >
      <v-btn icon small @click="resize(1)"
        ><v-icon small>mdi-arrow-expand</v-icon></v-btn
      >
    </div>
    <v-card-text class="chart-container" :style="getStyle">
      <!-- <div class="text-body-2">{{ config.title }}</div> -->
      <canvas :id="chartId"></canvas>
    </v-card-text>
  </v-card>
</template>

<script>
import Chart from "chart.js";
import { mapGetters } from "vuex";

export default {
  name: "LineChart",
  computed: {
    ...mapGetters(["getTrialStepDatasets"]),
    getStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
  props: {
    config: {
      type: Object,
      required: false,
    },
    flagVisibility: {
      type: Boolean,
      required: false,
    },
    chartId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      colors: [
        "#1565c0",
        "#db5e0b",
        "#06aa0e",
        "#cf1c0f",
        "purple",
        "black",
        "teal",
        "yellow",
        "grey",
      ],
      width: 450,
      height: 300,
      chart: null,
    };
  },
  watch: {
    flagVisibility: function (newVal) {
      if (newVal) {
        this.plot();
      }
    },
    // "config.log_scale": function () {
    //   this.plot();
    // },
    // "config.smoothing": function () {
    //   this.plot();
    // },
    // "config.title": function () {
    //   this.plot();
    // },
    // "config.chart_type": function () {
    //   this.plot();
    // },
    // "config.chart_type": function () {
    //   this.plot();
    // },
    // "config.display_config.w2h_ratio": function () {
    //   this.plot();
    // }
    //watch for changes in the config
    config: {
      handler: function () {
        this.plot();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.config != null) this.plot();
  },
  methods: {
    prepareDataset(metric) {
      // console.log(`preparing for:${metric}`);
      let dataset = {
        data: this.getTrialStepDatasets[metric],
        fill: false,
        label: metric,
        borderWidth: 2,
        tension: this.config.smoothing,
      };
      // console.log(dataset);
      return dataset;
    },
    plot() {
      if (this.getTrialStepDatasets == null) return;
      this.resize(0);
      const ctx = document.getElementById(this.chartId);
      // new Chart(ctx, this.planetChartData);
      let datasets = [];
      // console.log(this.config.y);
      this.config.y_series.forEach((metric, i) => {
        let dataset = this.prepareDataset(metric.name);
        dataset.borderColor = this.colors[i];
        datasets.push(dataset);
      });
      const chartData = {
        type:
          this.config.chart_type != undefined ? this.config.chart_type : "line",
        data: {
          labels: this.getTrialStepDatasets.steps,
          datasets: datasets,
        },
      };
      chartData.options = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: this.config.title,
        },
        legend: {
          display: false,
        },
      };
      if (this.config.log_scale) {
        chartData.options.scales = {
          yAxes: [
            {
              display: true,
              type: "logarithmic",
            },
          ],
        };
      }
      if (this.chart != null) this.chart.destroy();
      this.chart = new Chart(ctx, chartData);
    },
    resize(direction) {
      const resize_factor = 100;
      let w2h_ratio = 1.5;
      if (
        this.config.display_config != undefined &&
        this.config.display_config.w2h_ratio != undefined
      )
        w2h_ratio = this.config.display_config.w2h_ratio;
      if (direction == 0) {
        this.height = 300;
        this.width = this.height * w2h_ratio;
      } else {
        this.height += resize_factor * direction;
        this.width += resize_factor * w2h_ratio * direction;
      }
    },
    deleteChart() {
      if (this.config.custom) {
        this.$store.dispatch("deleteChart", this.config.uri.id).then(() => {
          console.log("delete-inside");
          this.$emit("deleted");
        });
      }
    },
  },
};
</script>

<style>
.chart-container {
  width: inherit;
  height: inherit;
}

.card-controls {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
