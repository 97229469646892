import { TrackingClient } from "@/store/common/axio-api";
import * as utils from "@/store/common/utils";

const state = {
  trials: null,
  trial: null,
  trialSteps: null,
  trialStepCount: null,
  trialStepDatasets: null,
  trialTimeStepDatasets: null,
  trialMetrics: null,
};

const getters = {
  getTrials: (state) => state.trials,
  getTrial: (state) => state.trial,
  getTrialSteps: (state) => state.trialSteps,
  getTrialStepDatasets: (state) => state.trialStepDatasets,
  getTrialStepCount: (state) => state.trialStepCount,
  getTrialMetricNames: (state) => state.trialMetrics,
  getTrialTimeStepDatasets: (state) => state.trialTimeStepDatasets,
};

const mutations = {
  SET_TRIALS: (state, trials) => {
    state.trials = trials;
  },
  SET_TRIAL: (state, trial) => (state.trial = trial),
  SET_TRIAL_STEPS: function (state, obj) {
    let items = [];
    let metricsSteps = { steps: [] };
    obj.results.forEach((element) => {
      let entry = utils.reduceObject(element.metrics, null);
      Object.entries(entry).forEach(([key, value], i) => {
        if (!(key in metricsSteps) && key.length > 0) metricsSteps[key] = [];
      });
      metricsSteps.steps.push(element.step_num);
      entry.step_num = element.step_num;
      entry.created_on = element.created_on;
      items.push(entry);
    });
    let uniqueKeys = Object.keys(metricsSteps);
    if (uniqueKeys.indexOf("steps") >= 0)
      uniqueKeys.splice(uniqueKeys.indexOf("steps"), 1);
    let metricsTimeSteps = {};
    uniqueKeys.forEach((e) => (metricsTimeSteps[e] = []));
    items.forEach((entry) => {
      uniqueKeys.forEach((key) => {
        if (key in entry) {
          metricsSteps[key].push({
            y: entry[key],
            x: entry.step_num,
          });
          metricsTimeSteps[key].push({
            y: entry[key],
            x: new Date(entry.created_on),
          });
        }
      });
    });
    state.trialSteps = items;
    state.trialStepDatasets = metricsSteps;
    state.trialStepCount = obj.count;
    state.trialMetrics = uniqueKeys;
    state.trialTimeStepDatasets = metricsTimeSteps;
  },
  CLEAR_DATA(state) {
    state.trial = null;
  },

};

const actions = {
  fetchTrials({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      const baseUrl = utils.prepareURL(
        `projects/${options.project_id}/trials/index/?`,
        options
      );
      TrackingClient.post(baseUrl, options, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_TRIALS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchTrial({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      TrackingClient.get(`trials/${id}/`, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_TRIAL", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  exportTrials({ rootGetters }, options) {
    return new Promise((resolve, reject) => {
      const baseUrl = utils.prepareURL(
        `projects/${options.project_id}/trials/export/?`,
        options
      );
      TrackingClient.post(baseUrl, options, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  patchTrial({ rootGetters }, trialOptions) {
    return new Promise((resolve, reject) => {
      TrackingClient.patch(
        `trials/${trialOptions.id}/`,
        trialOptions.data,
        rootGetters.getAuthHeaders
      )
        .then(function (reponse) {
          resolve(reponse.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchTrialSteps({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      const baseUrl = utils.prepareURL(
        `trials/${options.trial_id}/steps?`,
        options
      );
      TrackingClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_TRIAL_STEPS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  validateDeletion({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      TrackingClient.post(`projects/${options.project_id}/trials/validate-deletion/`,
        options, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_TRIALS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  deleteTrials({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      TrackingClient.post(`projects/${options.project_id}/trials/delete/`,
        options, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  createTrial({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const url = `experiments/${payload.experiment_id}/trials/`;
      TrackingClient.post(url, payload.body, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  logMetricsAndCloseTrial({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const url = `trials/${payload.trial_id}/`;
      const body = {
        metrics: payload.metrics,
        status: 'completed'
      };
      console.log(body);
      TrackingClient.patch(url, body, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  clearTrial({ commit }) {
    commit("CLEAR_DATA");
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
