<template>
	<v-app>
		<v-main>
			<v-row no-gutters class="full-height">
				<v-col cols="6">
					<v-sheet
						color="blue accent-3 mr-1 blue-sheet"
						height="100%"
						elevation="5"
						dark
						class="card-container"
					>
						<!-- horizontally & vertically centered div -->
						<div class="horizontally-centered">
							<div class="text-h2">
								OneML
								<span class="text-caption">v1.3</span>
							</div>
							<div class="text-subtitle-1">
								An end-to-end MlOps platform
								<v-icon class="ml-2">mdi-recycle-variant</v-icon>
							</div>
							<!-- Made with heart -->
							<div class="text-caption mt-4">
								Made with
								<v-icon color="red" small>mdi-heart</v-icon>
								by DXG
							</div>
						</div>
						<div class="text-caption disclaimer mr-3">
							<img
								src="@/assets/wk.svg"
								alt="logo"
								class="logo-img"
								height="25"
							/>
						</div>
					</v-sheet>
				</v-col>
				<v-col cols="6">
					<div class="card-container background">
						<LoginCard
							v-if="cardMode == 'login'"
							@closed="actionCompleted('login')"
							@requestAccess="actionCompleted('requestAccess')"
						/>
						<CreateAccessRequest
							v-else-if="cardMode == 'request-access'"
							@closed="actionCompleted('accessRequested')"
						/>
						<TenantSelection
							v-else-if="cardMode == 'select-tenant'"
							@closed="actionCompleted('selectTenant')"
						/>
					</div>
				</v-col>
			</v-row>
		</v-main>
	</v-app>
</template>

<script>
import { mapGetters } from "vuex";
import LoginCard from "../components/users/LoginCard";
import TenantSelection from "../components/tenant/TenantSelection";
import CreateAccessRequest from "../components/accessRequests/CreateAccessRequest";

export default {
	components: { LoginCard, TenantSelection, CreateAccessRequest },
	data: () => ({
		cardMode: "login",
	}),
	computed: {
		...mapGetters(["userObj", "isAuthenticated"]),
	},
	created() {
		this.cardMode = this.isAuthenticated ? "select-tenant" : "login";
	},
	methods: {
		actionCompleted(action) {
			console.log("actionCompleted: ", action);
			if (action == "login") {
				this.cardMode = "select-tenant";
			} else if (action == "requestAccess") {
				this.cardMode = "request-access";
			} else if (action == "accessRequested") {
				this.cardMode = "login";
			}
		},
	},
};
</script>

<style>
.blue-sheet {
	position: relative;
}

.disclaimer {
	position: absolute;
	bottom: 5px;
	right: 5px;
}

.card-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
</style>
