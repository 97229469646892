<template>
  <v-container fluid>
    <v-banner two-line v-if="getToolkit">
      <v-avatar slot="icon" color="primary" size="40">
        <v-icon color="white"> mdi-google-analytics </v-icon>
      </v-avatar>
      <div>
        <div class="text-body-1">{{ getToolkit.name | status }} Analytics</div>
        <div class="text-caption">
          <v-icon small class="project-icon">mdi-folder-outline</v-icon>
           <span>
            {{ getToolkit.project.name }}
           </span>
        </div>
      </div>
      <template v-slot:actions>
        <v-menu offset-y :disabled="!isUserLevel2OrUp">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              :loading="uiFlags.loadingExport"
            >
              Export
              <v-icon right dark>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="exportFeedbacks('search')">
              <v-list-item-title>Export Search Feedback</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="exportFeedbacks('genai')">
              <v-list-item-title>Export Gen-AI Feedback</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn :disabled="!isUserLevel2OrUp" color="primary" small @click="createSnapshot()"> Snapshot <v-icon right dark>mdi-cloud-upload</v-icon></v-btn>
        <v-btn :disabled="!isUserLevel2OrUp" color="error" small @click="( uiFlags.resetToolkitId = $route.params.id )"> Reset <v-icon right dark>mdi-trash-can-outline</v-icon></v-btn>
      </template>
    </v-banner>
    <div class="d-flex flex-column ma-4">
      <div class="d-flex flex-row">
        <v-card
          class="text-center d-flex flex-column align-content-space-between"
          width="650"
        >
          <v-card-text v-if="analytics.trend">
            <v-sparkline
              :value="analytics.trend.net_rating"
              :labels="analytics.trend.date"
              :gradient="sparklineGradients"
              height="100"
              padding="16"
              stroke-linecap="round"
              smooth
              type="trend"
              :line-width="2"
              :fill="false"
              :show-labels="true"
            >
            </v-sparkline> 
          </v-card-text>
          <v-skeleton-loader type="image" v-else>
          </v-skeleton-loader>
          <v-card-text class="my-auto">
            <div class="text-body-2 font-weight-light">
              Net Rating Trend (10 Days)
            </div>
          </v-card-text>
        </v-card>
        <v-card
          class="text-center ml-4 d-flex flex-column align-content-space-between"
          width="680"
        >
          <v-card-text v-if="analytics.genai">
            <div class="mt-2"></div>
            <div v-for="item in analytics.genai.result" 
              :key="item.feedback_value"
              class="d-flex flex-row"
            >
              <div class="text-body-2 font-weight-light progress-bar-label">
                {{ genAIFeedbackLabelMap[item.feedback_value] }}
              </div>
              <v-progress-linear
                :value="item.percentage"
                height="20"
                class="mb-5"
                rounded
                :color="genAIFeedbackColorMap[item.feedback_value]"
              >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
              </v-progress-linear>
            </div>
          </v-card-text>
          <v-skeleton-loader type="image" v-else>
          </v-skeleton-loader>
          <v-card-text class="my-auto">
            <div class="text-body-2 font-weight-light">
              Gen AI Feedback Ratings
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div class="d-flex flex-row mt-4">
        <HeadingCard
          :loading="uiFlags.loadingOverall"
          :title="'Search Feedbacks'"
          :number="analytics.overall == null ? 0 : analytics.overall.n_submissions"
          :unit="''"
          class="mr-4"
        />
        <HeadingCard
          :loading="uiFlags.loadingGenAI"
          :title="'GenAI Feedbacks'"
          :number="analytics.genai == null ? 0 : analytics.genai.n_submissions"
          :unit="''"
          class="mr-4"
        />
        <HeadingCard
          :loading="uiFlags.loadingOverall"
          :title="'Total Modes'"
          :number="analytics.overall == null ? 0 : analytics.overall.n_modes"
          :unit="''"
          class="mr-4"
        />
        <HeadingCard
          :loading="uiFlags.loadingUserwise"
          :title="'Total Users'"
          :number="analytics.userwise == null ? 0 : analytics.userwise.count"
          :unit="''"
          class="mr-4"
        />
      </div>
      <v-data-table
        v-if="analytics.overall"
        :headers="headers.modewise"
        :items="analytics.overall.result"
        :server-items-length="analytics.overall.result.length"
        :items-per-page="10"
        class="elevation-2 mt-4"
      >
        <template v-slot:[`item.mode`]="{ item }">
          <div>{{ item.mode | status}}</div>
        </template>
        <template v-slot:[`item.feedback_type`]="{ item }">
          <div>{{ item.feedback_type | status}}</div>
        </template>
      </v-data-table>
      <v-data-table
        dense
        v-if="analytics.userwise"
        :headers="headers.userwise"
        :items="analytics.userwise.result"
        :server-items-length="analytics.userwise.result.length"
        :items-per-page="10"
        class="elevation-2 mt-4"
      >
        <template v-slot:[`item.user`]="{ item }">
          <div>{{ item.user.first_name}} {{ item.user.last_name }}</div>
        </template>
      </v-data-table>
    </div>
    <CreateSnapshot 
      :showDialog="uiFlags.dialogCreateSnapshot"
      :snapshotObj="snapshot"
      :toolkit="getToolkit"
      @closed="()=> {
        uiFlags.dialogCreateSnapshot = false
      }"
    />
    <ResetAnalytics :id="uiFlags.resetToolkitId" @closed="dialogClosed('reset')"/>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import HeadingCard from "@/components/toolkits/analytics/HeadingCard.vue";
import CreateSnapshot from "@/components/toolkits/analytics/CreateSnapshot.vue"
import ResetAnalytics from "@/components/toolkits/analytics/ResetAnalytics.vue"
import { reduceObject } from "@/store/common/utils";
export default {
  name: 'SearchAnalytics',
  components: {
    HeadingCard,
    CreateSnapshot,
    ResetAnalytics
  },
  computed: {
    ...mapGetters(["getToolkit", "isUserLevel2OrUp"])
  },
  data(){
    return {
      analytics: {
        overall: null,
        userwise: null,
        trend: null,
        genai: null,
        snapshotObj: null
      },
      uiFlags:{
        loadingOverall: false,
        loadingUserwise: true,
        loadingTrend: true,
        loadingExport: false,
        loadingGenAI: false,
        dialogCreateSnapshot: false,
        resetToolkitId: null,
      },
      sparklineGradients: ['#f72047', '#ffd200', '#1feaea'],
      headers: {
        modewise:[
          { text: 'Mode', value: 'mode' },
          { text: 'Feedback Type', value: 'feedback_type' },
          { text: 'Hits', value: 'hits' },
          // { text: 'Likes', value: 'likes' },
          { text: 'Like %', value: 'like_percentage' },
          { text: 'Dislike %', value: 'dislike_percentage' },
          { text: 'Net Rating %', value: 'net_rating' },
        ],
        userwise: null
      },
      genAIFeedbackLabelMap: {
        0: "Very Bad",
        1: "Bad",
        2: "Neutral",
        3: "Good",
        4: "Very Good"
      },
      genAIFeedbackColorMap:{
        0: "#f72047",
        1: "#ffd200",
        2: "#348feb",
        3: "#11c2b4",
        4: "#36a624"
      },
      snapshot: {
        object: null,
        reducedObject: null,
        serializedString: null
      }
    };
  },
  async created(){
    await this.fetchToolkit();
  },
  async mounted(){
    await this.fetchAllAnalytics();
  },
  methods:{
    async dialogClosed(type){
      if(type == 'reset'){
        this.uiFlags.resetToolkitId = null;
        await this.fetchAllAnalytics();
      }
    },
    async fetchAllAnalytics(){
      // create a new task that awaits all calls
      const task = [
        this.fetchOverallAnalytics(),
        setTimeout(()=> this.fetchUserwiseAnalytics(), 500),
        setTimeout(()=> this.fetchTrendAnalytics(), 750),
        // this.fetchUserwiseAnalytics(),
        // this.fetchTrendAnalytics(),
        setTimeout(()=> this.fetchGenAIAnalytics(), 1250),
      ];
      // execute all calls
      await Promise.all(task);
    },
    async fetchOverallAnalytics(){
      try{
        this.uiFlags.loadingOverall = true;
        this.analytics.overall = await this.$store.dispatch("fetchSearchAnalytics", {
          toolkitId: this.$route.params.id,
          type: "modewise",
        });
      }
      finally{
        this.uiFlags.loadingOverall = false;
      }
    },
    async fetchUserwiseAnalytics(){
      try{
        this.uiFlags.loadingUserwise = true;
        const response = await this.$store.dispatch("fetchSearchAnalytics", {
          toolkitId: this.$route.params.id,
          type: "userwise",
        });
        this.headers.userwise = response.headers;
        this.analytics.userwise = response;
      }
      finally{
        this.uiFlags.loadingUserwise = false;
      }
    },
    async fetchTrendAnalytics(){
      try{
        this.uiFlags.loadingTrend = true;
        this.analytics.trend = await this.$store.dispatch("fetchSearchAnalytics", {
          toolkitId: this.$route.params.id,
          type: "trend",
        });
      }
      finally{
        this.uiFlags.loadingTrend = false;
      }
    },
    async fetchGenAIAnalytics(){
      try{
        this.uiFlags.loadingGenAI = true;
        this.analytics.genai = await this.$store.dispatch("fetchGenAIAnalytics", this.$route.params.id);
      }
      finally{
        this.uiFlags.loadingGenAI = false;
      }
    },
    async fetchToolkit() {
      await this.$store.dispatch("fetchToolkit", this.$route.params.id);
    },
    async exportFeedbacks(type) {
      this.uiFlags.loadingExport = true;
      try{
        let data = null;
        let filename = null;
        if(type=='search'){
          filename = "search-feedback.jsonl";
          data = await this.$store.dispatch("exportSearchFeedback", this.$route.params.id);
        }
        else if(type=='genai'){
          filename = "gen-ai-feedback.jsonl";
          data = await this.$store.dispatch("exportGenAIFeedback", this.$route.params.id);
        }
        else
          return;
        const anchor = document.createElement("a");
        anchor.href =
          "data:application/json-lines;charset=utf-8," + encodeURIComponent(data);
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      }
      finally{
        this.uiFlags.loadingExport = false;
      }
    },
    convertLabelToKey(label){
      return label.toLowerCase().replace(/ /g, "_");
    },
    async createSnapshot(){
      const genAIObj = {
        ratings: {},
        count: this.analytics.genai.n_submissions
      };
      for(let item of this.analytics.genai.result){
        let label = this.convertLabelToKey(this.genAIFeedbackLabelMap[item.feedback_value])
        genAIObj.ratings[label] = item.percentage;
      }
      const overallSummary = {
        count: this.analytics.overall.n_submissions,
        modes: {}
      };
      for(let item of this.analytics.overall.result){
        let modeName = this.convertLabelToKey(item.mode);
        let feedbackName = this.convertLabelToKey(item.feedback_type);
        let _analytics = {
          net_rating: item.net_rating,
          like_per: item.like_percentage,
          dislike_per: item.dislike_percentage
        };
        if (overallSummary.modes[modeName] == null)
          overallSummary.modes[modeName] = {};
        overallSummary.modes[modeName][feedbackName] = _analytics;
      }
      
      this.snapshot.object = {
        genai: genAIObj,
        search: overallSummary
      };
      this.snapshot.reducedObject = reduceObject(this.snapshot.object);
      // serialize the object with necessary whitespace
      this.snapshot.serializedString = JSON.stringify(this.snapshot.reducedObject, null, 2);
      this.uiFlags.dialogCreateSnapshot = true;
    }
  }
}
</script>

<style>

.progress-bar-label{
  width: 100px;
  text-align: start;
}
</style>