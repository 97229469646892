<template>
  <v-card class="mb-3" :loading="cardLoader">
    <v-card-text>
      <div class="text-body-1">
        <a href="" class="document-link"
          @click.prevent="openDocument()"
        >{{ resultItem.rank }}. {{ resultItem.title }} 
          <v-icon small class="document-icon">mdi-open-in-new</v-icon>
        </a>
      </div>
      <div class="result-item-body">{{ resultItem.summary }}</div>
    </v-card-text>
    <div class="result-action-panel">
      <div class="tag-panel">
        <div v-if="resultItem.tags">
        <v-chip
          label small outlined
          v-for="(tag, index) in resultItem.tags" 
          v-bind:key="index"
          class="ml-2 mt-1"
        >
          {{ tag.value }}
        </v-chip>
        </div>
      </div>
      <div class="feedback-panel">
        <div v-if="getSearchkitConfig.feedback_relevance_enabled && canGiveFeedbackInToolkits" class="feedback-group">
          <span class="text-caption">Relevance</span>
          <v-btn icon x-small @click="raiseFeedbackEvent('relevance', 1)"
            :disabled="!enableRelevanceFeedback"
            ><v-icon>mdi-thumb-up-outline</v-icon></v-btn
          >
          <v-btn icon x-small @click="raiseFeedbackEvent('relevance', 0)"
            :disabled="!enableRelevanceFeedback"
            ><v-icon>mdi-thumb-down-outline</v-icon></v-btn
          >
        </div>
        <div v-if="getSearchkitConfig.feedback_freshness_enabled && canGiveFeedbackInToolkits" class="feedback-group">
          <span class="text-caption">Freshness</span>
          <v-btn icon x-small @click="raiseFeedbackEvent('freshness', 1)"
            :disabled="!enableFreshnessFeedback"
            ><v-icon>mdi-thumb-up-outline</v-icon></v-btn
          >
          <v-btn icon x-small @click="raiseFeedbackEvent('freshness', 0)"
            :disabled="!enableFreshnessFeedback"
            ><v-icon>mdi-thumb-down-outline</v-icon></v-btn
          >
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'SearchResult',
  computed: {
    ...mapGetters(["getSearchkitConfig", "canGiveFeedbackInToolkits"])
  },
  props: {
    resultItem: {
      type: Object,
      required: true
    },
    cardLoader: {
      type: Boolean,
      required: false,
      default: false
    },
    cachedObj:{
      type: Object,
      required: false,
      default: null
    }
  },
  // watch:{
  //   cachedObj: {
  //     handler(val){
  //       console.log('cachedObj changed');
  //       this.resetFeedbackButtons(val);
  //     },
  //     deep: true
  //   }
  // },
  data(){
    return {
      enableFreshnessFeedback: true,
      enableRelevanceFeedback: true
    }
  },
  created(){
    this.resetFeedbackButtons(this.cachedObj);
  },
  methods: {
    openDocument(){
      this.$emit('open-document', this.resultItem);
    },
    raiseFeedbackEvent(type, value){
      // assert type in 'freshness', 'relevance'
      // assert value in 'up', 'down'
      value = parseInt(value);
      if(type != 'freshness' && type != 'relevance'){
        console.error('Invalid feedback type: ' + type);
        return;
      }
      if(value < 0 && value > 1){
        console.error('Invalid feedback value: ' + value);
        return;
      }
      if(type == 'freshness'){
        this.enableFreshnessFeedback = false;
      }
      else if(type == 'relevance'){
        this.enableRelevanceFeedback = false;
      }
      this.$emit('feedback-event', {
        type: type,
        value: value,
        document: this.resultItem
      });
    },
    resetFeedbackButtons(val){
      if(val == null) return;
      if(val.relevance != undefined && val.relevance == true){
        this.enableRelevanceFeedback = false;
      }
      if(val.freshness != undefined && val.freshness == true){
        this.enableFreshnessFeedback = false;
      }
    }
  }
}
</script>

<style>

</style>