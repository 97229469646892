<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="6">
        <img
          src="@/assets/construction.svg"
          alt="logo"
          class="generic-page-image"
          height="400"
        />
        <div class="text-center">This feature is not yet available.</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UnderConstruction",
};
</script>

<style></style>
