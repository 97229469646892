<template>
  <v-data-table
    v-if="report"
    class="elevation-1"
    :headers="computedHeaders"
    :items="report.items"
    :footer-props="{
      'items-per-page-options': [50, 75, 100],
    }"
  >
    <template
      v-for="header in computedHeaders.filter((header) =>
        header.hasOwnProperty('formatter')
      )"
      v-slot:[`item.${header.value}`]="{ header, value }"
    >
      {{ header.formatter(value) }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "SingleLabelReport",
  props: {
    report: {
      type: Object,
      required: false,
    },
  },
  computed: {
    computedHeaders: function () {
      if ((this.report != null) && (this.report.headers != undefined)) {
        let newheaders = [];
        this.report.headers.forEach((element) => {
          newheaders.push({
            text: element,
            value: element,
            formatter: this.metricFormatter,
          });
        });
        return newheaders;
      }
      return null;
    },
  },
  data() {
    return {};
  },
  methods: {
    metricFormatter: function (value) {
      if (typeof value == "number" && !Number.isInteger(value))
        return value.toPrecision(3);
      return value;
    },
  },
};
</script>

<style></style>
