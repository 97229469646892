import Vue from "vue";
import Vuex from "vuex";
import authentication from "./auth/authentication";
import { AuthClient, TrackingClient, ToolingClient } from "./common/axio-api";
import router from "../router";
import admin from "./common/admin";
import tenants from "./auth/tenants";
import projects from "./tracking/projects";
import experiments from "./tracking/experiments";
import trials from "./tracking/trials";
import artifacts from "./tracking/artifacts";
import servable from "./tracking/servable";
import modelStore from "./tracking/modelStore";
import users from "./auth/users";
import accessRequests from "./auth/accessRequests";
import toolkits from "./toolkit/toolkits";
import searchkits from "./toolkit/searchkits";
import genaikits from "./toolkit/genaikits";
import templates from "./tracking/templates";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    notificationText: "",
    darkMaster: true,
    miniDrawer: true,
    globalLoading: false,
    allowGlobalLoading: true,
  },
  getters: {
    useDarkTheme: (state) => state.darkMaster,
    getGlobalLoading: (state) => state.globalLoading,
    getAllowGlobalLoading: (state) => state.allowGlobalLoading,
  },
  mutations: {
    showNotification(state, text) {
      state.notificationText = text;
    },
    setGlobalLoading(state, flag) {
      state.globalLoading = flag;
    },
    updateGlobalLoading(state, flag) {
      state.allowGlobalLoading = flag;
    },
    useDarkTheme(state, flag) {
      state.darkMaster = flag;
    },
    hideDrawer(state) {
      state.miniDrawer = false;
    },
  },
  actions: {},
  modules: {
    authentication,
    admin,
    tenants,
    projects,
    experiments,
    trials,
    artifacts,
    servable,
    modelStore,
    users,
    accessRequests,
    toolkits,
    searchkits,
    genaikits,
    templates,
  },
});

export default store;

const requestHandler = (request) => {
  if (request.method == "get") store.commit("setGlobalLoading", true);
  return request;
};

const errorHandler = (error) => {
  if (error.response != undefined && error.response != null) {
    if (error.response.status == 401) {
      store.commit("showNotification", "Looks like you're logged out!");
      store.dispatch("logout").then(() => {
        router.push({ name: "landing" });
      });
    } else if (error.response.status != 400) {
      store.commit("showNotification", "Something went wrong");
    }
  }
  store.commit("setGlobalLoading", false);
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  store.commit("setGlobalLoading", false);
  return response;
};

AuthClient.interceptors.request.use((request) => requestHandler(request));
TrackingClient.interceptors.request.use((request) => requestHandler(request));
ToolingClient.interceptors.request.use((request) => requestHandler(request));

AuthClient.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

TrackingClient.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

ToolingClient.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
)
