import axios from "axios";
import getEnv from "./env";

const baseAuthURL = getEnv("VUE_APP_AUTH_URL");
const baseTrackingURL = getEnv("VUE_APP_TRACKING_URL");
const baseToolingURL = getEnv("VUE_APP_TOOLING_URL");

const ToolingClient = axios.create({ baseURL: baseToolingURL });
const ToolingClientV2 = axios.create({ baseURL: baseToolingURL });
const TrackingClient = axios.create({ baseURL: baseTrackingURL });
const AuthClient = axios.create({ baseURL: baseAuthURL });

export { ToolingClient, ToolingClientV2, TrackingClient, AuthClient };
