<template>
  <div>
    <div class="text-center">
      <v-dialog v-model="enableTenantSearch" max-width="500px" persistent>
        <v-card>
          <v-card-title>Select an Account/Tenant</v-card-title>
          <v-card-text>
            <v-autocomplete
              dense
              v-model="tenantId"
              :loading="loading"
              :items="tenantResults"
              :search-input.sync="tenantSearch"
              cache-items
              flat
              hide-no-data
              @change="onTenantSelect"
              class="mt-2"
            ></v-autocomplete>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text small @click="enableTenantSearch = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-container fluid>
      <v-data-table
        v-if="getProjects && tenantId != null"
        :headers="headers"
        :items="getProjects.results"
        :server-items-length="getProjects.count"
        :options.sync="options"
        class="elevation-1"
        dense
      >
        <template v-slot:top>
          <div class="pa-4 d-flex" v-if="getTenant">
            Projects From
            <TenantSelectionBtn
              :name="getTenant.name"
              class="ml-2"
              @changeTenant="enableTenantSearch = true"
            />
          </div>
          <v-text-field
            dense
            v-model="search"
            label="Search"
            class="mx-4"
            v-on:keyup.enter="fetchProjects()"
            clearable
            prepend-icon="mdi-magnify"
          ></v-text-field>
        </template>
        <template v-slot:[`item.desc`]="{ item }">
          {{ item.desc | charlimit100 }}
        </template>
        <template v-slot:[`item.created_on`]="{ item }">
          {{ item.created_on | localdatetime }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-btn icon v-tooltip="'Project Details'">
            <v-icon> mdi-information-outline </v-icon></v-btn
          > -->
          <v-btn icon v-tooltip="'Edit Project'" :to="{ name: 'edit-project', params: { id: item.uri.id } }"
            ><v-icon> mdi-pencil </v-icon></v-btn
          >
        </template>
      </v-data-table>
      <v-btn
        fab
        dark
        color="primary"
        :to="{ name: 'create-project', params: { id: tenantId } }"
        class="floating-button"
        v-tooltip="'Create New Project'"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
      <NewProject
        v-bind:id="modalProps.tenantIdForProject"
        @closed="modalClosed('newProject')"
      />
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NewProject from "../../components/projects/NewProject.vue";
import TenantSelectionBtn from "../../components/tenant/TenantSelectionBtn.vue";

export default {
  name: "ProjectList",
  components: { NewProject, TenantSelectionBtn },
  computed: mapGetters(["getProjects", "getTenant", "userObj"]),
  data() {
    return {
      tenantSearch: null,
      tenantResults: [],
      lastTenantSearch: null,
      enableTenantSearch: false,
      loading: false,
      tenantId: -1,
      options: {
        init: true,
        itemsPerPage: 10,
      },
      search: "",
      modalProps: {
        tenantIdForProject: -1,
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Description", value: "desc" },
        { text: "Created On", value: "created_on" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  created() {
    this.tenantId = this.$route.params.id
    if (this.tenantId != null &&  this.tenantId != 'undefined') {
      this.onTenantSelect();
    } else {
      this.enableTenantSearch = true;
      this.searchTenantsFn("");
    }
  },
  watch: {
    tenantSearch(val) {
      val && val !== this.lastTenantSearch && this.searchTenantsFn(val);
    },
    options: {
      handler: function (oldVal, newOptions){
        if(!this.options.init){
          this.fetchProjects();
        }
        this.options.init = false;
      },
    },
  },
  methods: {
    searchTenantsFn(value) {
      this.lastTenantSearch = value;
      const options = { search: value, itemsPerPage: 7, id: this.userObj.uri.id }
      this.$store
        .dispatch("fetchUserTenants", options)
        .then((data) => {
          var newResults = [];
          data.results.forEach((item) => {
            newResults.push({
              text: item.tenant.name,
              value: item.tenant.uri.id,
            });
          });
          this.tenantResults = newResults;
        });
    },
    onTenantSelect() {
      this.enableTenantSearch = false;
      this.$store.dispatch("fetchTenant", this.tenantId);
      this.fetchProjects();
    },
    modalClosed() {
      this.modalProps.tenantIdForProject = -1;
    },
    fetchProjects() {
      console.log('fetching projects');
      let optionsCopy = structuredClone(this.options);
      optionsCopy.id = this.tenantId;
      optionsCopy.search = this.search;
      this.loading = true;
      this.$store
        .dispatch("fetchProjectsbyTenant", optionsCopy)
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
.autocomplete-container {
  display: flex;
  flex-direction: row;
}
</style>
