/* eslint-disable */
<template>
  <div>
    <v-row justify="center">
      <v-dialog persistent max-width="600px" v-model="showDialog">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Tenant</span>
          </v-card-title>
          <v-card-text>
            <validation-observer ref="observer">
              <form @submit.prevent="submit">
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                >
                  <v-text-field
                    name="name"
                    label="Name*"
                    v-model="tenant.name"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="max_projects"
                  rules="required"
                >
                  <v-text-field
                    name="max_projects"
                    label="Max Allowed Projects*"
                    v-model="tenant.max_projects"
                    :error-messages="errors"
                    type="number"
                    required
                  ></v-text-field>
                </validation-provider>
                <v-btn type="submit" color="primary" :loading="loading"
                  >Save</v-btn
                >
                <v-btn text @click="closeDialog(false)">Close</v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "NewTenant",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  watch: {
    id: function (newId) {
      if (newId != null) {
        this.$store.dispatch("fetchTenant", newId).then((data) => {
          this.tenant = data;
          this.showDialog = true;
        });
      }
    },
  },
  data: () => ({
    loading: false,
    showDialog: false,
    tenant: {
      name: null,
      max_projects: 20,
    },
  }),
  methods: {
    closeDialog: function (saved) {
      this.showDialog = false;
      this.$emit("closed", saved);
    },
    submit() {
      this.loading = true;
      this.$store
        .dispatch("editTanant", {
          id: this.id,
          data: this.tenant,
        })
        .then(() => {
          this.closeDialog();
          this.$store.commit("showNotification", "Changes successfully saved");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
            this.closeDialog();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
