<template>
	<v-container fluid>
		<div class="d-flex flex-column mt-3 ml-3 mr-3">
			<!-- <div class="dashboard-background width-fill"></div> -->
			<v-alert
				v-if="isSMEOrSpecator"
				border="right"
				colored-border
				type="error"
				elevation="2"
				class="text-caption"
				dense
			>
				As per your role in OneML, some of the features may not be available to
				you.
			</v-alert>
			<v-row class="card-row" v-if="getSelectedProject">
				<v-col cols="9">
					<v-card v-if="getProject">
						<v-card-title
							>{{ getProject.name }} Dashboard
							<v-icon
								color="orange darken-2"
								class="ml-1"
								v-if="getProject.locked"
								>mdi-lock</v-icon
							>
							<!-- Spacer to push the button to the right -->
							<v-spacer></v-spacer>
						</v-card-title>
						<v-card-text>
							<v-list dense>
								<v-list-item two-line>
									<v-list-item-content>
										<v-list-item-title>
											Name: {{ getProject.name }}
										</v-list-item-title>
										<v-list-item-subtitle>
											{{ getProject.desc }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-list-item two-line>
									<v-list-item-content>
										<v-list-item-title>
											<div class="d-flex flex-row align-center">
												<div>Identifier: {{ getSelectedProject.uri.id }}</div>
												<v-btn icon small @click="copyProjectIdentifier()"
													><v-icon>mdi-content-copy</v-icon></v-btn
												>
											</div>
										</v-list-item-title>
										<v-list-item-subtitle>
											Use this identifier to start your experiment.
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-content v-if="getProject">
										<v-list-item-title>
											Servable Metric:
											<span class="primary-text">{{
												getProject.servable_metric
											}}</span>
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>
											Created On:
											{{ getSelectedProject.created_on | localdatetime }}
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-card-text>
						<div class="controls-top-right">
							<v-menu>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on" class="ml-1">
										<v-icon>mdi-dots-vertical</v-icon>
									</v-btn>
								</template>
								<v-list>
									<v-list-item dense link @click="copyProjectLink()">
										<v-list-item-title>Copy Project Link</v-list-item-title>
									</v-list-item>
									<v-list-item
										dense
										link
										:to="{
											name: 'edit-project',
											params: { id: getSelectedProject.uri.id },
										}"
										v-if="isUserLevel3OrUp"
									>
										<v-list-item-title>Edit Project Details</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</div>
					</v-card>
					<v-banner single-line class="mt-3">
						Project Tracking Analytics
					</v-banner>
					<v-row class="mt-3" v-if="getDashboard">
						<v-col>
							<v-card>
								<v-card-text>
									<div class="text-h2">
										{{ getDashboard.realtime_analytics.n_experiments }}
									</div>
									<div>Experiments</div>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col>
							<v-card>
								<v-card-text>
									<div class="text-h2">
										{{ getDashboard.realtime_analytics.n_servables }}
									</div>
									<div>Servables</div>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col>
							<v-card>
								<v-card-text>
									<div class="text-h2">
										{{ getDashboard.offline_analytics.n_engineers }}
									</div>
									<div>Contributing engineers</div>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col>
							<v-card>
								<v-card-text>
									<div class="text-h2">
										{{ getDashboard.offline_analytics.logged_hours }}
									</div>
									<div>Logged hours</div>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
					<v-banner single-line class="mt-3">
						Project Resource Analytics
					</v-banner>
					<v-row class="mt-3" v-if="getDashboard">
						<v-col>
							<v-card>
								<v-card-text>
									<div class="text-h2">
										{{ getDashboard.offline_analytics.n_artifacts }}
									</div>
									<div>Artifacts</div>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="4">
							<v-card class="auto-width">
								<v-card-text>
									<div class="text-h2" v-if="storageUsed">
										{{ storageUsed.value
										}}<span class="text-h5">{{ storageUsed.unit }}</span>
									</div>
									<div>Artifact Storage Size</div>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col></v-col>
						<v-col></v-col>
					</v-row>
					<v-banner single-line class="mt-3"> Project Evolution </v-banner>
					<v-card class="evolution-card" v-if="getDashboard">
						<v-card-text
							v-if="getDashboard.offline_analytics.evolution_steps != null"
						>
							<canvas id="project-evoluation" :style="getStyle"></canvas>
						</v-card-text>
						<v-card-text v-else>
							<div class="text-body-2">No evolution data available</div>
						</v-card-text>
						<div class="text-caption ma-3">
							<sup>*</sup>Calculated based on project servable-metric
						</div>
					</v-card>
					<div v-if="getDashboard">
						<span
							class="text-caption"
							v-if="getDashboard.offline_analytics.updated_at != undefined"
						>
							Analytics calculated on:
							{{ getDashboard.offline_analytics.updated_at | localdatetime }}
							& refreshed every 4 hours
						</span>
						<span class="text-caption" v-else>
							Waiting for analytics to be calculated, Please check in some time.
						</span>
					</div>
				</v-col>
				<v-col>
					<v-card>
						<v-card-text v-if="getSelectedProject">
							<h4>Quick Links</h4>
							<v-list dense>
								<v-list-item link :to="{ name: 'experiments' }">
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>Experiments</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
								<v-list-item link :to="{ name: 'trials' }">
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>Trials</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
								<v-list-item link :to="{ name: 'servables' }">
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>Servables</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									link
									:to="{
										name: 'edit-project',
										params: { id: getSelectedProject.uri.id },
									}"
									v-if="isUserLevel3OrUp"
								>
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>Edit Project</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									link
									@click="navigateTo(getProject.confluence_link)"
								>
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>Confluence Docs
											<v-icon small class="mb-1" color=""
												>mdi-open-in-new</v-icon
											>
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item link @click="navigateTo(getProject.git_repo_link)">
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>Code Repository
											<v-icon small class="mb-1" color=""
												>mdi-open-in-new</v-icon
											>
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list>
							<h4>Documentation</h4>
							<v-list dense>
								<!-- static links to confluence pages -->
								<v-list-item
									link
									target="_blank"
									href="https://confluence.wolterskluwer.io/display/CCAI/1.+OneML%3A+Introduction"
								>
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>What is OneML?</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									link
									target="_blank"
									href="https://confluence.wolterskluwer.io/display/CCAI/4.1+Hello+OneML"
								>
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>Learn how to log trials</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									link
									target="_blank"
									href="https://confluence.wolterskluwer.io/display/CCAI/4.2+Tracking+Your+NLP+Experiment"
								>
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>OneML for NLP</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									link
									target="_blank"
									href="https://confluence.wolterskluwer.io/display/CCAI/4.4+Querying+Your+Trials%3A+The+Right+Way"
								>
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>Learn how to query trials</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									link
									target="_blank"
									href="https://confluence.wolterskluwer.io/display/CCAI/4.5+Using+OneML+with+Auto-Tuning"
								>
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>OneML with AutoML🤖</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									link
									target="_blank"
									href="https://confluence.wolterskluwer.io/display/CCAI/4.7.1+Visualization+with+Custom+Charts"
								>
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>Visualizing experiment results 📈</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									link
									target="_blank"
									href="https://confluence.wolterskluwer.io/display/CCAI/4.6+Interacting+with+Experiments"
								>
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>Interacting with Experiments 🧪</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									link
									target="_blank"
									href="https://confluence.wolterskluwer.io/display/CCAI/4.8.1+Interacting+with+Artifacts+using+a+ArtifactStore"
								>
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>Interacting with Artifact Store 🏭</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									link
									target="_blank"
									href="https://confluence.wolterskluwer.io/display/CCAI/4.7.4+Interacting+with+Trials+using+Trial+Store"
								>
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>Interacting with Trial Store 🔍</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>

								<v-list-item
									link
									target="_blank"
									href="https://confluence.wolterskluwer.io/display/CCAI/4.10+Deploying+OneML+Models"
								>
									<v-list-item-content>
										<v-list-item-title class="primary-link"
											>OneML based deployments 🚀</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";
import copy from "copy-to-clipboard";
import Chart from "chart.js";

export default {
	name: "HomeView",
	computed: {
		...mapGetters([
			"getSelectedProject",
			"getDashboard",
			"getProject",
			"isUserLevel3OrUp",
			"isSMEOrSpecator",
		]),
		getStyle() {
			return {
				width: `100%`,
				height: `${this.height}px`,
			};
		},
	},
	data() {
		return {
			flags: {
				selectProject: false,
			},
			storageUsed: null,
			chartConfig: {
				type: "line",
				data: {
					labels: [],
					datasets: [],
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
				},
			},
			chart: null,
			height: 350,
		};
	},
	created() {},
	mounted() {
		if (this.getSelectedProject != null) {
			this.init();
		}
	},
	watch: {
		getSelectedProject: function (newVal, oldVal) {
			console.log("project changed");
			if (newVal != null) {
				this.init();
			}
		},
	},
	methods: {
		async init() {
			await this.$store.dispatch(
				"getProjectDashboard",
				this.getSelectedProject.uri.id
			);
			await this.$store.dispatch(
				"fetchProject",
				this.getSelectedProject.uri.id
			);
			this.estimateSize(this.getDashboard.offline_analytics.storage_used);
			this.plotEvolution();
		},
		plotEvolution() {
			const steps = this.getDashboard.offline_analytics.evolution_steps;
			if (steps == null || steps == undefined) return;
			const datasetA = {
				label: "Best Metric",
				data: steps.best_metric_1,
				borderColor: "#1565c0",
				fill: false,
			};
			const datasetB = {
				label: "Best Metric Till Date",
				data: steps.best_metric_2,
				borderColor: "green",
				fill: false,
			};
			this.chartConfig.data.labels = steps.x_series;
			this.chartConfig.data.datasets = [datasetA, datasetB];
			const ctx = document.getElementById("project-evoluation");
			if (this.chart != null) this.chart.destroy();
			// console.log(this.chartConfig);
			this.chart = new Chart(ctx, this.chartConfig);
		},
		estimateSize(storageValue) {
			// check if storageValue is a string & is equal to NA
			if (typeof storageValue === "string" && storageValue === "NA") {
				this.storageUsed = {
					value: "<1",
					unit: "Byte",
				};
				return;
			}
			if (storageValue < 1024) {
				this.storageUsed = {
					value: "<1",
					unit: "KB",
				};
				return;
			}
			const valueInKB = storageValue / 1024;
			// console.log("valueInKB:"+valueInKB.toString());
			if (valueInKB < 1024) {
				this.storageUsed = {
					value: valueInKB.toPrecision(3).toString(),
					unit: "KB",
				};
				return;
			}
			const valueInMB = valueInKB / 1024;
			// console.log("valueInMB:"+valueInMB.toString());
			if (valueInMB < 1024) {
				this.storageUsed = {
					value: valueInMB.toPrecision(3).toString(),
					unit: "MB",
				};
			} else {
				this.storageUsed = {
					value: (valueInMB / 1024).toPrecision(3).toString(),
					unit: "GB",
				};
			}
		},
		copyProjectIdentifier() {
			copy(this.getSelectedProject.uri.id);
			this.$store.commit(
				"showNotification",
				"Project identifier copied to clipboard"
			);
		},
		navigateTo(url) {
			//navigate to external site in new window using window
			window.open(url, "_blank", "noopener,noreferrer");
		},
		async copyProjectLink() {
			const projectId = this.getSelectedProject.uri.id;
			const projectLink = `${window.location.origin}/#/projects/${projectId}/landing`;
			copy(projectLink);
			this.$store.commit(
				"showNotification",
				"Project link copied to clipboard!"
			);
		},
	},
};
</script>

<style scoped>
.dashboard-background {
	/* background-color: #2962ff !important; */
	background-color: #bdbdbd !important;
	height: 200px;
}

.card-row {
	/* background-color: red; */
	/* margin: -150px 10px; */
}

.auto-width {
	width: fit-content;
}

.evolution-card {
	margin-top: 25px;
	margin-bottom: 25px;
}
</style>
