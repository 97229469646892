function prepareURL(baseUrl, options) {
  let offset = (options.page - 1) * options.itemsPerPage;
  if (!isNaN(options.itemsPerPage)) baseUrl += `&limit=${options.itemsPerPage}`;
  if (!isNaN(offset)) baseUrl += `&offset=${offset}`;
  if (options.sortBy != null && options.sortBy.length > 0) {
    let sortByColumn = options.sortBy[0];
    sortByColumn = sortByColumn.replace("_uri.", "__");
    if (options.sortDesc[0]) baseUrl += `&ordering=-${sortByColumn}`;
    else baseUrl += `&ordering=${sortByColumn}`;
  }
  if (options.search != null && options.search.length > 0) {
    baseUrl += `&search=${options.search}`;
  }
  if (options.type != null && options.type.length > 0) {
    baseUrl += `&type=${options.type}`;
  }
  if (baseUrl[baseUrl.length - 1] == "?")
    baseUrl = baseUrl.slice(0, baseUrl.length - 1);
  return baseUrl;
}

function retriveLocalObject(name) {
  if (localStorage.getItem(name) != null) {
    try {
      return JSON.parse(localStorage.getItem(name));
    } catch {
      return null;
    }
  }
  return null;
}

function setLocalObject(name, obj) {
  if (obj == null) localStorage.removeItem(name);
  else localStorage.setItem(name, JSON.stringify(obj));
}

function retriveLocalItem(name) {
  return localStorage.getItem(name) || null;
}

function setLocalItem(name, item) {
  if (item == null) localStorage.removeItem(name);
  else localStorage.setItem(name, item);
}

function reduceObject(obj, prefix, displayPrecision) {
  let items = {};
  if (obj == null) return items;
  Object.entries(obj).forEach(([key, value], i) => {
    const newPrefix = prefix != null ? `${prefix}.${key}` : key;
    if (typeof value == "object") {
      const subItems = reduceObject(value, newPrefix, displayPrecision);
      Object.entries(subItems).forEach(([k, v], i) => {
        if (!(k in items) && k.length > 0) {
          items[k] = v;
        }
      });
    } else if (!(key in items) && key.length > 0) {
      if (value == undefined || value == null) {
        value = "null";
      } else if (
        typeof value == "number" &&
        !Number.isInteger(obj[key]) &&
        value.toString().split(".").splice(1)[0].length > displayPrecision
      ) {
        value = value.toFixed(5);
      }
      items[newPrefix] = value;
    }
  });
  return items;
}

function formatTimeSpent(milis) {
  const hours = Math.abs(Math.round(milis / (1000 * 60 * 60)));
  milis -= hours * 60 * 60 * 1000;
  const mins = Math.abs(Math.round(milis / (1000 * 60)));
  milis -= mins * 60 * 1000;
  const seconds = Math.abs(Math.round(milis / 1000));

  let timeStr = "";
  if (hours > 0) timeStr += ` ${hours} hr${hours > 1 ? "s" : ""}`;
  if (mins > 0) timeStr += ` ${mins} min${mins > 1 ? "s" : ""}`;
  if (seconds > 0 && mins <= 0 && hours <= 0) timeStr += ` ${seconds} seconds`;
  return timeStr;
}

function compareObjects(v1, v2, keysToIgnore) {
  if (!(keysToIgnore == null || keysToIgnore instanceof Set)) {
    throw new Error('`keysToIgnore` should be Set')
  }
  if (typeof (v1) !== typeof (v2)) {
    return false;
  }
  if (v1 instanceof Object && v2 instanceof Object) {
    if (Object.keys(v1).length !== Object.keys(v2).length) {
      return false;
    }
    for (let key in v1) {
      if (keysToIgnore != null && keysToIgnore.has(key))
        continue;
      if (v2[key] === undefined) {
        return false;
      }
      if (!compareObjects(v1[key], v2[key], keysToIgnore)) {
        console.log(`Cache id invalidated due to change in ${key}`);
        return false;
      }
    }
    return true;
  }
  else {
    return v1 === v2;
  }
}

function joinUrls(base, relative) {
  const baseTrimmed = base.endsWith("/") ? base.slice(0, -1) : base;
  const relativeTrimmed = relative.startsWith("/") ? relative.slice(1) : relative;
  return `${baseTrimmed}/${relativeTrimmed}`;
}

function hashQuery(query) {
  const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
  var hash = 0,
    i,
    chr;
  if (query.length === 0) return hash;
  query = query.toLowerCase().trim();
  query = query.replace(regex, "");
  let query_ex = query;
  //remove all the whitespace from string
  for (i = 0; i < query_ex.length; i++) {
    const character = query_ex.charAt(i);
    if (character == " ") continue;
    chr = query_ex.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function generateRandomKey() {
  // Generate a random key using a combination of Date and Math.random()
  return Date.now() + Math.random();
}

export {
  prepareURL, retriveLocalObject, reduceObject,
  formatTimeSpent, setLocalObject, retriveLocalItem,
  setLocalItem, compareObjects, joinUrls,
  hashQuery, generateRandomKey
};
