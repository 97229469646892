<template>
  <v-container fluid class="mb-5">
    <v-banner single-line>
      <div class="text-body-1">Trial Comparison ({{ nTrials }} selected)/ Overview</div>
      <template v-slot:actions>
        <v-switch
            v-model="flags.commonAttrsOnly"
            label="Show only common attributes"
            @change="prepareComparisonTable()"
          ></v-switch>
      </template>
    </v-banner>
    <!-- Set max width for each column -->
    <v-simple-table class="mt-3 elevation-1">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Attribute\Trial</th>
            <th v-for="trial in trials" :key="trial.uri.id">
              <router-link :to="{ name: 'trial', params: { id: trial.uri.id } }"
                class="primary-link">{{ trial.name.toUpperCase() }}</router-link>
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Row for experiment -->
          <tr>
            <td>Experiment</td>
            <td v-for="trial in trials" :key="trial.id">
              <v-chip
                    target="_blank"
                    label
                    small
                    color="primary"
                    :to="{
                      name: 'experiments',
                      query: { name: trial.experiment.name },
                    }"
                  >
                    {{ trial.experiment.name.toUpperCase() }}
                    <v-icon small right>mdi-open-in-new</v-icon>
              </v-chip>  
            </td>
          </tr>
          <!-- Row for experiment -->
          <tr>
            <td>Created By</td>
            <td v-for="trial in trials" :key="trial.id">
              <v-chip
                    target="_blank"
                    label
                    small
                    color="primary"
                  >
                    {{ trial.created_by.username }}
                    <v-icon small right>mdi-account</v-icon>
              </v-chip>  
            </td>
          </tr>
          <!-- Row for experiment -->
          <tr>
            <td>Status</td>
            <td v-for="trial in trials" :key="trial.id">
              {{ trial.status | status }}  
            </td>
          </tr>
          <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
            <td v-for="(item, itemIndex) in row" :key="itemIndex">
              <v-tooltip v-if="item.tooltip" bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.text }}</span>
                </template>
                <span>{{ item.tooltip }}</span>
              </v-tooltip>
              <span v-else>{{ item.text }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-body-1 ma-3">Params Comparison</div>
    <!-- Set max width for each column -->
    <v-simple-table class="mt-3 elevation-1">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Attribute\Trial</th>
            <th v-for="trial in trials" :key="trial.uri.id">
              <router-link :to="{ name: 'trial', params: { id: trial.uri.id } }"
                class="primary-link">{{ trial.name.toUpperCase() }}</router-link>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in rowsParams" :key="rowIndex">
            <td v-for="(item, itemIndex) in row" :key="itemIndex" :class="item.className">
              <v-tooltip v-if="item.tooltip" bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.text }}</span>
                </template>
                <span>{{ item.tooltip }}</span>
              </v-tooltip>
              <span v-else>{{ item.text }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-body-1 ma-3">Metrics Comparison</div>
    <!-- Set max width for each column -->
    <v-simple-table class="mt-3 elevation-1">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Attribute\Trial</th>
            <th v-for="trial in trials" :key="trial.uri.id">
              <router-link :to="{ name: 'trial', params: { id: trial.uri.id } }"
                class="primary-link">{{ trial.name.toUpperCase() }}</router-link>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in rowsMetrics" :key="rowIndex">
            <td v-for="(item, itemIndex) in row" :key="itemIndex" :class="item.className">
              <v-tooltip v-if="item.tooltip" bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.text }}</span>
                </template>
                <span>{{ item.tooltip }}</span>
              </v-tooltip>
              <span v-else>{{ item.text }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog
      v-model="flags.loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { formatTimeSpent, reduceObject } from "@/store/common/utils";
 
export default {
  name: "TrialComparisonView",
  components: { formatTimeSpent, reduceObject },
  data() {
    return {
      trials: [],
      nTrials: 0,
      rows:[],
      rowsParams: [],
      rowsMetrics: [],
      flags:{
        commonAttrsOnly: false,
        loading: false
      },
      genericAttributes: [
        // { text: "Experiment", value: "experiment.name" },
        { text: "Tranche", value: "tranche" },
        { text: "Time Taken", value: "timeTaken" },
        // { text: "Created By", value: "created_by" },
        { text: "Dataset", value: "dataset_identifier" },
      ],
    };
  },
  created() {
    let uris = decodeURIComponent(this.$route.query.uris);
    //loop through uris and fetch trials
    uris = uris.split(",");
    // this.flags.loading = true;
    //wait for 550ms in each iteration
    Promise.all(uris.map((uri) => this.fetchTrial(uri))).then(() => {
      this.nTrials = this.trials.length;
      this.prepareComparisonTable();
      
    })
    // .finally(() => {
    //   this.flags.loading = false;
    // });

  },
  methods: {
    async fetchTrial(id){
      return new Promise((resolve) => {
        this.$store.dispatch("fetchTrial", id)
        .then((data) => {
          const d1 = new Date(data.created_on);
          const d2 = data.finished_on == null ? Date.now() : new Date(data.finished_on);
          data.timeTaken = formatTimeSpent(d2 - d1);
          this.trials.push(data);
          data.metrics = reduceObject(data.metrics, null, 4);
          data.params = reduceObject(data.params, null, 4);
          resolve();
        });
      });
    },
    getAttributesByUnion(){
      //create a union of all attributes from metrics & params
      let commonMetricNames = [] , commonParamNames = [];
      for (let i = 0; i < this.trials.length; i++) {
        const trial = this.trials[i];
        const metricNames = Object.keys(trial.metrics);
        const paramNames = Object.keys(trial.params);
        commonMetricNames.push(...metricNames);
        commonParamNames.push(...paramNames);
      }
      //create a union of all attributes from genericAttributes
      commonMetricNames = [...new Set(commonMetricNames)];
      commonParamNames = [...new Set(commonParamNames)];
      return {
        params: commonParamNames,
        metrics: commonMetricNames
      }
    },
    getAttributesByIntersection(){
      let commonMetricNames = [] , commonParamNames = [];
      for (let i = 0; i < this.trials.length; i++) {
        const trial = this.trials[i];
        const metricNames = Object.keys(trial.metrics);
        const paramNames = Object.keys(trial.params);
        if (i == 0) {
          commonMetricNames.push(...metricNames);
          commonParamNames.push(...paramNames);
        } else {
          commonMetricNames = commonMetricNames.filter((name) => metricNames.includes(name));
          commonParamNames = commonParamNames.filter((name) => paramNames.includes(name));
        }
      }
      return {
        params: commonParamNames,
        metrics: commonMetricNames
      }
    },
    gatherAttrValues(attrs, parentField){
      const _rows = [];
      for (let i = 0; i < attrs.length; i++) {
        const attr = attrs[i];
        const _row = [
          {
            text: attr,
            tooltip: null,
            className: ''
          }
        ];
        for (let j = 0; j < this.trials.length; j++) {
          const trial = this.trials[j];
          _row.push({
            text: trial[parentField][attr],
            tooltip: null,
            className: trial[parentField][attr] == undefined ? 'grey-cell' : ''
          });
        }
        _rows.push(_row);
      }
      return _rows;
    },
    prepareComparisonTable(){
      //iterate this.genericAttributes & lookup for this attribute in each trial
      const _rows = [];
      for (let i = 0; i < this.genericAttributes.length; i++) {
        const attrName = this.genericAttributes[i].value;
        const attrText = this.genericAttributes[i].text;
        const row = [
          {
            text: attrText,
            tooltip: null
          }
        ];
        for (let j = 0; j < this.trials.length; j++) {
          const trial = this.trials[j];
          row.push({
            text: trial[attrName],
            tooltip: null
          });
        }
        _rows.push(row);
      }
      var commons = {};
      //prepare common metrics & params
      if (this.flags.commonAttrsOnly) {
        commons = this.getAttributesByIntersection();
      } else {
        commons = this.getAttributesByUnion();
      }
      //populate rows based on common params
      this.rows = _rows;
      this.rowsParams = this.gatherAttrValues(commons.params, "params");
      this.rowsMetrics = this.gatherAttrValues(commons.metrics, "metrics");
    }
  },
};
</script>

<style scoped>

.v-data-table td {
    max-width: 20px;
    white-space: pre-wrap;
}

table thead th {
  border-right: 0.09em rgb(196, 193, 193) solid;
  /* background-color: #e6edf8 !important; */
  /* background-color: #f5f7f7 !important; */
}

table tbody td {
  border-right: 0.09em rgb(196, 193, 193) solid;
  /* background-color: #f5f7f7 !important; */
}
.grey-cell{
  background-color: #f5f7f7 !important;
}

</style>