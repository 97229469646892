<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="showDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title class="">Logout from all devices</v-card-title>
          <v-card-text>
            <p>Are you sure you want to logout from all devices?</p>
          </v-card-text>
          <v-card-actions class="d-flex flex-row justify-end">
            <v-btn color="error" :loading="loading" @click="logoutFromAllDevices()"
              >Logout</v-btn
            >
            <v-btn text @click="closeDialog(false)">Cancel</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LogoutFromAllDevices",
  props: {
    showDialog: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods:{
    closeDialog() {
      this.$emit("closed");
    },
    logoutFromAllDevices() {
      this.loading = true;
      this.$store
        .dispatch("logoutFromAllDevices")
        .then(() => {
          this.$store.commit("showNotification", "You've been logged out from all devices!");
          this.$store.dispatch("logout")
            .then(() => {
              this.$router.push({ name: "landing" });
            });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
            this.closeDialog();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
}
</script>

<style>

</style>