var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"height-fill"},[_c('div',[_c('v-card',[_c('v-banner',{attrs:{"two-line":""}},[_c('v-avatar',{attrs:{"slot":"icon","color":"primary","size":"40"},slot:"icon"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-beaker-outline ")])],1),_c('div',[_c('div',{staticClass:"text-body-1"},[_vm._v("Experiments")]),(_vm.getExperiments)?_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.getExperiments.count)+" experiments found ")]):_vm._e()])],1),_c('div',{staticClass:"experiment-delete"},[(
						_vm.selectedExperiments.length >= 1 &&
						_vm.selectedExperiments.length <= 100
					)?_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.deleteExperiments()}}},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Delete Selected Experiments'),expression:"'Delete Selected Experiments'"}],staticStyle:{"margin-bottom":"12px !important"}},[_vm._v("mdi-delete-outline")])],1):_vm._e()],1),_c('v-card-title',[_c('v-text-field',{staticClass:"query-field",attrs:{"single-line":"","append-icon":_vm.queryIcon,"label":"Search By Experiment Identifier , Name , Description","placeholder":"Search By Experiment Identifier , Name , Description"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.fetchExperiments()}},model:{value:(_vm.experimentData.query),callback:function ($$v) {_vm.$set(_vm.experimentData, "query", $$v)},expression:"experimentData.query"}})],1),(_vm.experimentData.result)?_c('v-data-table',{staticClass:"trial-table mb-2",attrs:{"headers":_vm.experimentSchema.staticHeaders,"items":_vm.experimentData.result,"server-items-length":_vm.experimentData.count,"fixed-header":true,"show-select":"","single-select":false,"options":_vm.experimentOptions,"item-key":"uri.id","footer-props":{
					'items-per-page-options': [25, 50, 75, 100],
				},"dense":""},on:{"update:options":function($event){_vm.experimentOptions=$event}},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('router-link',{staticClass:"primary-link",attrs:{"to":{
							name: 'trials',
							query: { id: item.uri.id },
						}}},[_vm._v(_vm._s(item.name))]),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Copy Experiment ID'),expression:"'Copy Experiment ID'"}],attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.copyToClipboard(item.uri.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}},{key:`item.created_on`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("localdatetime")(item.created_on))+" ")]}},{key:`item.desc`,fn:function({ item }){return [_c('div',{staticClass:"description-cell"},[_vm._v(" "+_vm._s(item.desc)+" ")])]}},{key:`item.action`,fn:function({ item }){return [(_vm.selectedExperiments.length == 0)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editExperiment(item.uri.id)}}},[(_vm.experimentData.experimentEditId !== item.uri.id)?[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])]:[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20","color":"primary","width":2}})]],2):_vm._e(),(_vm.selectedExperiments.length == 0)?_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.deleteExperiments(item.uri.id)}}},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Delete Experiment'),expression:"'Delete Experiment'"}]},[_vm._v("mdi-delete-outline")])],1):_vm._e()]}}],null,true),model:{value:(_vm.selectedExperiments),callback:function ($$v) {_vm.selectedExperiments=$$v},expression:"selectedExperiments"}}):_vm._e(),_c('EditExperiment',{attrs:{"id":_vm.experimentData.experimentEditId},on:{"closed":function($event){return _vm.modalClosed('editExperiment')}}})],1)],1),_c('ExperimentDeletionView',{attrs:{"experiment-ids":_vm.experimentsTobeDeleted},on:{"closed":function($event){return _vm.modalClosed('deletionCancelled')},"deleted":function($event){return _vm.modalClosed('experimentsDeleted')}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }