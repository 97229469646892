<template>
  <div>
    <v-dialog persistent max-width="500px" v-model="uiFlags.showDialog">
      <v-card v-if="uiFlags.confirmApproval">
          <v-card-title>
            <span class="headline">Confirm Approval</span>
          </v-card-title>
          <v-card-text>
            Are you sure you want to approve this access request? <br/>
            If yes, please select an apprpriate role for the user. <br/>
            Requestor Email: <b>{{email}}</b>
            <v-select
              v-model="role"
              :items="roleOptions"
              label="Select Role"
              class="mt-1"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" small @click="approve()">Approve</v-btn>
            <v-btn text small @click="closeDialog()">Cancel</v-btn> 
          </v-card-actions>
      </v-card>
      <v-card v-else>
          <v-card-title>
            <span class="headline">Access Approved</span>
          </v-card-title>
          <v-card-text>
            Access request has been approved successfully.
            <v-text-field class="mt-2" dense 
              outlined append-icon="mdi-content-copy" 
              @click:append="copyUsername()"
              label="Username"
              type="text" v-model="credentials.username">
            </v-text-field>
            <v-text-field class="mt-1" dense 
              outlined append-icon="mdi-content-copy" 
              @click:append="copyPassword()"
              label="Password"
              type="text" v-model="credentials.password">
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text small @click="closeDialog()">Close</v-btn> 
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import copy from "copy-to-clipboard";

export default {
  name: "ApproveAccessRequest",
  props: {
    id: {
      type: Number,
      required: false,
    },
    email: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      uiFlags: {
        showDialog: false,
        confirmApproval: false,
      },
      credentials: {
        username: null,
        password: null,
      },
      roleOptions:[
        {text: "Data Scientist-1", value: "Data Scientist-1"},
        {text: "Data Scientist-2", value: "Data Scientist-2"},
      ],
      role: null,
    };
  },
  watch: {
    id: function (newId) {
      if(newId) {
        this.uiFlags.showDialog = this.uiFlags.confirmApproval = true;
        //username is everything before @ in email
        this.credentials.username = this.email.split("@")[0];
      }
      else {
        this.uiFlags.showDialog = false;
      }
    },
  },
  methods: {
    reject() {
      this.$emit("rejected");
      this.confirmDeletion = false;
    },
    closeDialog() {
      this.$emit("closed");
    },
    approve(){
      const options = {
        id: this.id,
        action: "approve",
        initial_role: this.role,
      };
      this.$store.dispatch("processAccessRequest", options)
        .then((data) => {
          this.credentials.password = data.password;
          this.uiFlags.confirmApproval = false;
        })
        .catch((err) => {
          this.$store.commit("showNotification", "Something went wrong");
        }).finally(() => {
          this.approvalId = null;
        });
    },
    copyUsername(){
      copy(this.credentials.username);
      this.$store.commit("showNotification", 'Username copied to clipboard');
    },
    copyPassword(){
      copy(this.credentials.password);
      this.$store.commit("showNotification", 'Password copied to clipboard');
    }
  },
}
</script>

<style>

</style>