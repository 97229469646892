import ProfileView from "../views/users/ProfileView.vue";
import UserListView from "../views/users/UserListView.vue";
import NewUserView from "../views/users/NewUserView.vue";
import UserDetailView from "../views/users/UserDetailView.vue";
import UserEditView from "../views/users/UserEditView.vue";

export default [
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
  },
  {
    path: "/users",
    name: "users",
    component: UserListView,
  },
  {
    path: "/users/create",
    name: "create-user",
    component: NewUserView,
  },
  {
    path: "/users/:id",
    name: "user",
    component: UserDetailView,
  },
  {
    path: "/users/:id/edit",
    name: "edit-user",
    component: UserEditView,
  }
];

