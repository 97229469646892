<template>
  <div>
    <v-row justify="center">
      <v-dialog persistent max-width="600px" v-model="showDialog">
        <v-card>
          <v-card-title>
            <span class="headline">Add New Chart</span>
          </v-card-title>
          <v-card-text>
            <validation-observer ref="observer">
              <form @submit.prevent="submit">
                <validation-provider
                  v-slot="{ errors }"
                  name="title"
                  rules="required"
                >
                  <v-text-field
                    name="title"
                    label="Title*"
                    v-model="chart.title"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="chart_type"
                  rules="required"
                >
                  <v-select
                    v-model="chart.chart_type"
                    :items="chartTypeOptions"
                    label="Chart Type"
                    :error-messages="errors"
                  >
                  </v-select>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="y_series"
                  rules="required"
                >
                  <v-select
                    v-model="chart.y_series"
                    :items="getTrialMetricNames"
                    label="Y Axis"
                    multiple
                    :error-messages="errors"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip dense color="primary">
                        <span>{{ item }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="x_series"
                  rules="required"
                >
                  <v-select
                    v-model="chart.x_series"
                    :items="x_axis_options"
                    label="X Axis"
                    :error-messages="errors"
                  >
                  </v-select>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="log_scale"
                  rules="required"
                >
                  <v-row>
                    <v-col cols="8">
                      <v-slider
                        label="Y-axis Smoothing"
                        max="1"
                        min="0"
                        step="0.1"
                        thumb-label
                        v-model="chart.smoothing"
                        :error-messages="errors"
                      ></v-slider>
                    </v-col>
                  </v-row>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="log_scale"
                  rules="required"
                >
                  <v-switch
                    class="dense-switch"
                    label="Y-axis Log Scale"
                    v-model="chart.log_scale"
                    :error-messages="errors"
                  >
                  </v-switch>
                </validation-provider>
                <v-expansion-panels flat class="modal-advanced-settings">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Advanced Settings
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-text-field
                        label="Width To Height Ratio"
                        v-model="chart.display_config.w2h_ratio"
                      ></v-text-field>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-btn type="submit" color="primary" :loading="loading"
                  >Save</v-btn
                >
                <v-btn text @click="closeDialog(false)">Close</v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "NewChart",
  computed: {
    ...mapGetters(["getTrialMetricNames"]),
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    flagVisibility: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    flagVisibility: function (newVal) {
      if (newVal) {
        this.showDialog = true;
      }
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      x_axis_options: [
        { text: "step", value: "step_num" },
        { text: "time_stamp", value: "created_on" },
      ],
      chartTypeOptions: [
        { text: "Line Chart", value: "line" },
        { text: "Scatter Plot", value: "scatter" },
      ],
      chart: this.getBlankChart(),
    };
  },
  methods: {
    closeDialog: function (saved) {
      this.loading = false;
      this.clear();
      this.$emit("closed", saved);
      this.showDialog = false;
    },
    clear() {
      this.chart = this.getBlankChart();
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    submit() {
      this.loading = true;
      let chartOptions = JSON.parse(JSON.stringify(this.chart));
      let y_series = [];
      this.chart.y_series.forEach((e) => y_series.push({ name: e }));
      chartOptions.y_series = y_series;
      chartOptions.x_series = { name: this.chart.x_series };
      this.$store
        .dispatch("createNewChart", chartOptions)
        .then(() => {
          this.closeDialog();
          this.clear();
          this.$store.commit(
            "showNotification",
            "New Chart Successfully Created & Saved"
          );
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
            this.closeDialog();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getBlankChart() {
      return {
        title: null,
        chart_type: "line",
        y_series: null,
        x_series: "step_num",
        log_scale: false,
        smoothing: 0,
        display_config: {
          w2h_ratio: 1.5,
        },
      };
    },
  },
};
</script>

<style></style>
