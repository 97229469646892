import { AuthClient } from "@/store/common/axio-api";
import * as utils from "@/store/common/utils";

const state = {
  tenants: null,
  tenant: null,
  tenantUsers: null
};

const getters = {
  getTenants: (state) => state.tenants,
  getTenant: (state) => state.tenant,
  getTenantUsers: (state) => state.tenantUsers,
};

const mutations = {
  SET_TENANTS: (state, tenants) => (state.tenants = tenants),
  SET_TENANT: (state, tenant) => (state.tenant = tenant),
  SET_TENANT_USERS: (state, tenantUsers) => (state.tenantUsers = tenantUsers),
};

const actions = {
  fetchAllTenants({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      var baseUrl = utils.prepareURL(`tenants/?`, options);
      AuthClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_TENANTS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchTenantUsers({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      const baseURL = utils.prepareURL(`tenants/${options.id}/users?`, options);
      AuthClient.get(baseURL, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_TENANT_USERS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchTenant({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      AuthClient.get(`tenants/${id}`, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_TENANT", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  createNewTenant({ rootGetters }, tenant) {
    return new Promise((resolve, reject) => {
      AuthClient.post(`tenants/`, tenant, rootGetters.getAuthHeaders)
        .then(function (reponse) {
          resolve(reponse.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  editTanant({ rootGetters }, tenant) {
    return new Promise((resolve, reject) => {
      AuthClient.put(
        `tenants/${tenant.id}/`,
        tenant.data,
        rootGetters.getAuthHeaders
      )
        .then(function (reponse) {
          resolve(reponse);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  toggleTenantUserAllocation({ rootGetters }, options) {
    return new Promise((resolve, reject) => {
      AuthClient.post(
        `tenants/${options.tenantId}/users/${options.itemId}/${options.action}/`,
        {}, rootGetters.getAuthHeaders
      )
        .then(function (reponse) {
          resolve(reponse);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  addUserToTenant({ rootGetters }, options) {
    return new Promise((resolve, reject) => {
      AuthClient.post(
        `tenants/${options.tenantId}/users/`,
        options.data, rootGetters.getAuthHeaders
      )
        .then(function (reponse) {
          resolve(reponse);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
