import { AuthClient, TrackingClient } from "./axio-api";
// import * as utils from "./utils";

const state = {
  auth_meta: null,
  tracking_meta: null,
};

const getters = {
  getAuthMeta: (state) => state.auth_meta,
  getTrackingMeta: (state) => state.tracking_meta,
};

const mutations = {
  SET_AUTH_META: (state, meta) => (state.auth_meta = meta),
  SET_TRACKING_META: (state, meta) => (state.tracking_meta = meta),
};

const actions = {
  fetchAuthMeta({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      AuthClient.get(`admin/meta`, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_AUTH_META", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchTrackingMeta({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      TrackingClient.get(`admin/meta`, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_TRACKING_META", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
