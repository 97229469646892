<template>
  <v-card
    elevation="2"
    class="mx-auto login-card"
    max-width="450"
    outlined
    :loading="loading"
  >
    <v-card-title>
      <span class="centered-span-title" v-text="cardTitle"></span>
    </v-card-title>
    <v-card-subtitle class="text-center" v-if="!loading">
      or
      <a class="primary-link" href="#" @click.prevent="requestAccess()">Request for access</a>
    </v-card-subtitle>
    <v-card-subtitle class="text-center" v-else
      >While we check your credentials</v-card-subtitle
    >
    <v-card-text v-show="!loading">
      <validation-observer ref="observer">
        <form @submit.prevent="submit">
          <validation-provider
            v-slot="{ errors }"
            name="username"
            rules="required"
          >
            <v-text-field
              v-model="credentails.username"
              :error-messages="errors"
              prepend-icon="mdi-account-circle"
              label="Username"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="password"
            rules="required"
          >
            <v-text-field
              v-model="credentails.password"
              :error-messages="errors"
              type="password"
              prepend-icon="mdi-lock"
              label="Password"
              required
            ></v-text-field>
          </validation-provider>
          <div class="d-flex justify-space-between px-2">
            <v-checkbox label="Remember Me"></v-checkbox>
            <div class="d-flex align-center">
              <a href="" class="primary-link">Forgot your password?</a>
            </div>
          </div>
          <div class="">
            <v-btn
              type="submit"
              :disabled="loading"
              color="primary"
              class="width-fill ma-1"
            >
              Login
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import getEnv from "@/store/common/env";

import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "LoginCard",
  components: {
    ValidationProvider,
    ValidationObserver,
    getEnv
  },
  data() {
    return {
      credentails: {
        username: "",
        password: "",
      },
      loading: false,
    };
  },
  computed: {
    cardTitle() {
      return this.loading ? "Hang on..." : "Sign into your account";
    },
  },
  created(){
    console.log("AUTH_URL: ", getEnv("VUE_APP_AUTH_URL"));
    console.log("TRACKING_URL: ", getEnv("VUE_APP_TRACKING_URL"));
    console.log("TOOLING_URL: ", getEnv("VUE_APP_TOOLING_URL"));
  },
  methods: {
    submit() {
      this.loading = true;
      // setTimeout(() => {
      //   this.login();
      // }, 1000);
      this.login();
    },
    login() {
      this.$refs.observer.validate();
      this.loading = true;
      this.$store
        .dispatch("login", this.credentails)
        .then(() => {
          this.$emit("closed");
        })
        .catch((err) => {
          if (
            err.response.status == 400 ||
            err.response.status == 401 ||
            err.response.status == 404
          ) {
            this.$refs.observer.setErrors({
              username: [""],
              password: ["Invalid Username or Password"],
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clear() {
      this.credentails = {
        username: "",
        password: "",
      };
    },
    requestAccess(){
      this.$emit("requestAccess");
    },
  },
};
</script>

<style>
.controls {
  display: flex;
  flex-direction: row-reverse;
}

.login-card {
  width: 450px;
}

.floating-caption {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
</style>
