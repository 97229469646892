/* eslint-disable */
<template>
  <div>
    <v-row justify="center">
      <v-dialog persistent max-width="600px" v-model="showDialog">
        <v-card v-if="!tokenCreated">
          <v-card-title>
            <span class="headline">Create New Token</span>
          </v-card-title>
          <v-card-text>
            <validation-observer ref="observer">
              <form @submit.prevent="submit">
                <validation-provider v-slot="{ errors }" name="name" rules="required">
                  <v-text-field name="name" label="Name*" v-model="tokenOptions.name" :error-messages="errors"
                    required></v-text-field>
                </validation-provider>
                <validation-provider v-slot="{ errors }" name="ttl" rules="required">
                  <v-text-field name="ttl" label="Valid For" suffix="Days" type="number" v-model="tokenOptions.ttl"
                    :error-messages="errors" required></v-text-field>
                </validation-provider>
                <div class="text-caption mb-2">
                  Note: <ul>
                    <li>Data-Scientist 3/ML Engineers can create tokens with validity upto 1800 days (5 years)</li>
                    <li>For other roles, the limit is 180 days</li>
                  </ul>
                </div>
                <v-btn type="submit" color="primary" :loading="loading">Save</v-btn>
                <v-btn text @click="closeDialog(false)">Close</v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title>Token Ready!</v-card-title>
          <v-card-text>
            Your new Token is ready. Please copy & save it with you.
            <v-text-field class="mt-2" dense outlined append-icon="mdi-content-copy" @click:append="copyToken()"
              type="text" v-model="token"></v-text-field>
            <!-- <div class="d-flex flex-row align-center">
              <div>Identifier: {{ token }}</div>
              <v-btn icon small @click="copyToken()"
                ><v-icon>mdi-content-copy</v-icon></v-btn
              >
            </div> -->
            <div v-if="tokenExpiry">
              Valid Till: {{ tokenExpiry | localdatetime }}
            </div>
            <div>
              Important! Token are visible only once after creation, please
              store them someplace safe.
            </div>
            <v-card-actions><v-btn text @click="closeDialog(false)">Close</v-btn></v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import copy from "copy-to-clipboard";

import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "NewToken",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    showDialog: {
      type: Boolean,
      required: false,
    },
  },
  // watch: {
  //   id: function (newVal) {
  //     this.showDialog = newVal;
  //   },
  // },
  data: () => ({
    loading: false,
    tokenCreated: false,
    token: null,
    tokenExpiry: null,
    tokenOptions: {
      name: null,
      ttl: 30,
    },
  }),
  methods: {
    closeDialog: function (saved) {
      this.clear();
      this.$emit("closed", saved);
    },
    clear() {
      this.tokenOptions.name = null;
      this.tokenOptions.ttl = 30;
      this.tokenCreated = false;
      this.token = null;
      this.tokenExpiry = null;
    },
    submit() {
      this.loading = true;
      this.$store
        .dispatch("createNewToken", this.tokenOptions)
        .then((data) => {
          this.clear();
          this.token = data.token;
          this.tokenExpiry = data.expiry;
          this.tokenCreated = true;
          this.$store.commit(
            "showNotification",
            "New Token Successfully Created"
          );
        })
        .catch((err) => {
          console.log(err.response.status);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
            this.closeDialog();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    copyToken() {
      copy(this.token);
      this.$store.commit("showNotification", "Token copied to clipboard");
    },
  },
};
</script>
