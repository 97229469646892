<template>
  <v-container fluid>
    <v-banner icon="mdi-creation-outline">
      Toolkit Creation Wizard
    </v-banner>
    <v-stepper v-model="creationStep">
      <v-stepper-header>
        <v-stepper-step
          editable
          :complete="creationStep > 1"
          step="1"
        >
          Define Toolkit
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :editable="flagBaseToolkitReady"
          :complete="creationStep > 2"
          step="2"
        >
          API Configuration
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :editable="flagBaseToolkitReady"
          :complete="creationStep > 3"
          step="3"
        >
          UI Configuration
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4">
          Intergration Tests
        </v-stepper-step>
      </v-stepper-header>
        <v-stepper-items>
        <v-stepper-content step="1">
          <CreateOrEditToolkit v-bind:id="getActiveToolkitCreationId"
            @step-completed="stepCompleted"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <CreateOrEditSearchConfig 
            v-bind:id="apiToolkitId"
            v-bind:mode="'api'"
            @step-completed="stepCompleted"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <CreateOrEditSearchConfig 
            v-bind:id="uiToolkitId"
            v-bind:mode="'ui'"
            @step-completed="stepCompleted"
          />
        </v-stepper-content>
        <v-stepper-content step="4">
          <IntegrationTests v-bind:id="intTestsToolkitId"
            @step-completed="stepCompleted"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>

import { mapGetters } from "vuex";
import CreateOrEditToolkit from "../../components/toolkits/CreateOrEditToolkit.vue"
import CreateOrEditSearchConfig from "../../components/toolkits/CreateOrEditSearchConfig.vue"
// import CreateOrEditSearchUIConfig from "../../components/toolkits/CreateOrEditSearchUIConfig.vue"
import IntegrationTests from "../../components/toolkits/IntegrationTests.vue"

export default {
  name: 'CreateToolkit',
  components: {
    CreateOrEditToolkit,
    CreateOrEditSearchConfig,
    // CreateOrEditSearchUIConfig,
    IntegrationTests
  },
  computed: mapGetters(["getActiveToolkitCreationId"]),
  data(){
    return {
      creationStep: 1,
      toolkitId: null,
      apiToolkitId: null,
      intTestsToolkitId: null,
      uiToolkitId: null,
      flagBaseToolkitReady: false,
    }
  },
  created() {
  },
  watch:{
    creationStep: function(newStep){
      this.updateStep();
    }
  },
  methods: {
    stepCompleted(increment){
      if(increment) this.creationStep = parseInt(this.creationStep) + 1;
    },
    updateStep(){
      this.toolkitId = this.getActiveToolkitCreationId;
      if(this.creationStep > 1) this.flagBaseToolkitReady = true;
      if(this.creationStep == 2){
        this.apiToolkitId = this.toolkitId;
        this.uiToolkitId = this.intTestsToolkitId = null;
      }
      else if(this.creationStep == 3){
        this.uiToolkitId = this.toolkitId;
        this.apiToolkitId = this.intTestsToolkitId = null;
      }
      else if(this.creationStep == 4){
        this.intTestsToolkitId = this.toolkitId;
        this.apiToolkitId = this.uiToolkitId = null;
      }
    }
  }
}
</script>

<style>

</style>