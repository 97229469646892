<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            Edit Project
          </v-card-title>
          <v-card-text v-if="project">
            <validation-observer ref="observer">
              <form @submit.prevent="submit">
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                >
                  <v-text-field
                    name="name"
                    label="Name*"
                    v-model="project.name"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="desc"
                  rules="required"
                >
                  <v-text-field
                    name="desc"
                    label="Description*"
                    v-model="project.desc"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="confluence_link"
                  rules="required"
                >
                  <v-text-field
                    name="confluence_link"
                    label="Confluence Page*"
                    v-model="project.confluence_link"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="git_repo_link"
                  rules="required"
                >
                  <v-text-field
                    name="git_repo_link"
                    label="Bitbucket Repo*"
                    v-model="project.git_repo_link"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="servable_metric"
                  rules="required"
                >
                  <v-text-field
                    name="servable_metric"
                    label="Servable Metric*"
                    v-model="project.servable_metric"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="metric_direction"
                  rules="required"
                >
                  <v-select
                    name="metric_direction"
                    label="Metric Direction*"
                    v-model="project.metric_direction"
                    :items="metricDirections"
                    :error-messages="errors"
                    required
                  >
                  </v-select>
                </validation-provider>
                <div class="checkbox-container">
                  <validation-provider
                    v-slot="{ errors }"
                    name="model_store_flag"
                    rules="required"
                  >
                    <v-checkbox 
                      name="model_store_flag"
                      label="Allow Publishing To Store"
                      v-model="project.model_store_flag"
                      :error-messages="errors"
                    >
                    </v-checkbox>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="locked"
                    rules="required"
                  >
                    <v-checkbox 
                      name="locked"
                      label="Lock Project"
                      v-model="project.locked"
                      :error-messages="errors"
                    >
                    </v-checkbox>
                  </validation-provider>
                </div>
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="loading"
                >
                  Submit
                </v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: 'ProjectEditView',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: mapGetters(["getProject"]),
  data() {
    return {
      project: null,
      loading: false,
      metricDirections:[
        { text: 'Minimize', value: 'minimize' },
        { text: 'Maximize', value: 'maximize' }
      ]
    };
  },
  created() {
    console.log(this.$route.params.id);
    this.$store.dispatch("fetchProject", this.$route.params.id)
    .then((data) => {
      this.project = data;
    });
  },
  methods:{
    submit() {
      this.loading = true;
      this.$store
        .dispatch("editProject", {
          id: this.$route.params.id,
          data: this.project,
        })
        .then(() => {
          this.$store.commit("showNotification", "Changes successfully saved");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>

<style scoped>
.checkbox-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 70%;
}
</style>>
