<template>
  <!-- <v-sheet v-if="loading" class="card-loading">
    <v-skeleton-loader
      class="card-loading"
      type="image"
    ></v-skeleton-loader>
  </v-sheet> -->
  <v-card class="heading-card-md">
    <v-skeleton-loader
      v-if="loading"
      class="card-loading"
      type="image"
    ></v-skeleton-loader>
    <v-card-text v-else>
      <div class="text-h2">
        {{ number }}
        <span class="text-caption">{{ unit }}</span>
      </div>
      <div>{{ title }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'HeadingCard',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    number: {
      type: Number,
      default: 0
    },
    unit: {
      type: String,
      default: ""
    },
  }
}
</script>

<style>

.card-loading{
  width: 120px;
  height: 120px;
}
</style>