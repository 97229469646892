import ToolkitList from "../views/toolkits/ToolkitList.vue";
import CreateToolkit from "../views/toolkits/CreateToolkit.vue"
import EditToolkit from "../views/toolkits/EditToolkit.vue"
import SearchToolkit from "../views/toolkits/SearchToolkit.vue"
import SearchAnalytics from "../views/toolkits/SearchAnalytics.vue"

export default [
  {
    path: "/toolkits",
    name: "toolkits",
    component: ToolkitList,
  },
  {
    path: "/toolkits/create",
    name: "create-toolkit",
    component: CreateToolkit,
  },
  {
    path: "/toolkits/:id/edit",
    name: "edit-toolkit",
    component: EditToolkit,
  },
  {
    path: "/toolkits/search/:id",
    name: "search-toolkit",
    component: SearchToolkit,
  },
  {
    path: "/toolkits/analytics/search/:id",
    name: "search-analytics",
    component: SearchAnalytics,
  }
];