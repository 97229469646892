<template>
  <v-container fluid>
    <v-data-table
      v-if="getAccessRequests"
      :headers="headers"
      :items="getAccessRequests.results"
      :server-items-length="getAccessRequests.count"
      :options.sync="options"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <div class="pa-4">Manage Access Requests</div>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
              v-on:keyup.enter="fetchAccessRequests()"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="status"
              :items="trialStatusItems"
              label="Filter By Status"
              class="mx-4"
              @change="fetchAccessRequests()"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.requested_on`]="{ item }">
        {{ item.requested_on | localdatetime }}
      </template>
      <template v-slot:[`item.full_name`]="{ item }">
        {{ item.first_name }} {{ item.last_name }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip v-if="item.status=='pending'" color="secondary" small>Pending</v-chip>
        <v-chip v-else-if="item.status=='approved'" color="success" small>Approved</v-chip>
        <v-chip v-else-if="item.status=='rejected'" color="error" small>Rejected</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div v-if="item.status == 'pending'">
          <v-btn small icon color="success" class="mr-2" 
            v-tooltip="'Approve'"
            :loading="item.uri.id == uiFlags.approvalId"
            @click="{
              uiFlags.approvalId = item.uri.id,
              uiFlags.requestorEmail = item.email
            }"
          >
            <v-icon>mdi-account-check</v-icon>
          </v-btn>
          <v-btn small icon color="error" v-tooltip="'Reject'" 
            :loading="item.uri.id == uiFlags.rejectionId"
            @click="reject(item.uri.id)"
          >
            <v-icon>mdi-account-remove</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <ApproveAccessRequest 
      :id="uiFlags.approvalId"
      :email="uiFlags.requestorEmail"
      @closed="modalClosed()"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ApproveAccessRequest from "../../components/accessRequests/ApproveAcccessRequest.vue"; 

export default {
  name: "AccessRequestAdminView",
  components: {ApproveAccessRequest},
  computed: {
    ...mapGetters(["getAccessRequests"]),
  },
  data() {
    return {
      options: {
        init: true,
        itemsPerPage: 10,
      },
      search: "",
      status: null,
      headers:[
        { text: "Email", value: "email" },
        { text: "Full Name", value: "full_name" },
        { text: "Team", value: "team_name" },
        { text: "Status", value: "status" },
        { text: "Requested On", value: "requested_on" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      trialStatusItems: [
        { text: "All", value: null },
        { text: "Pending", value: "pending" },
        { text: "Approved", value: "approved" },
        { text: "Rejected", value: "rejected" },
      ],
      uiFlags:{
        approvalId: null,
        requestorEmail: null,
        rejectionId: null,
      }
    };
  },
  created() {
    this.fetchAccessRequests();
  },
  watch:{
    options: {
      handler: function (oldVal, newOptions){
        if(!this.options.init){
          this.fetchAccessRequests();
        }
        this.options.init = false;
      },
    },
  },
  methods: {
    fetchAccessRequests() {
      console.log("fetching access requests");
      let optionsCopy = structuredClone(this.options);
      optionsCopy.search = this.search;
      optionsCopy.status = this.status;
      this.$store.dispatch("fetchAllAccessRequests", optionsCopy);
    },
    reject(id){
      this.approvalId = id;
      const options = {
        id: id,
        action: "reject",
      };
      this.$store.dispatch("processAccessRequest", options)
        .then(() => {
          this.$store.commit("showNotification", "Request rejected successfully");
          this.fetchAccessRequests();
        })
        .catch((err) => {
          this.$store.commit("showNotification", "Something went wrong");
        }).finally(() => {
          this.approvalId = null;
        });
    },
    modalClosed(){
      this.uiFlags.approvalId = null;
      this.uiFlags.requestorEmail = null;
      this.fetchAccessRequests();
    }
  },
}
</script>

<style>

</style>