<template>
  <v-container fluid>
    <div class="text-h5 pa-4">
      Admin Dashboard
      <div class="text-caption">Select an item to get started</div>
    </div>
    <!-- <div class="dashboard-grid">
      
    </div> -->
    <v-row class="dashboard-row-1">
      <v-col>
        <v-card width="160" v-if="getAuthMeta">
          <v-card-text>
            <div class="text-body-1">Tenants</div>
            <div class="text-h2 text-center">{{ getAuthMeta.n_tenants }}</div>
          </v-card-text>
          <v-btn icon class="controls-top-right" :to="{ name: 'tenants' }">
            <v-icon>mdi-pencil-box-multiple-outline</v-icon>
          </v-btn>
        </v-card>
      </v-col>
      <v-col>
        <v-card width="160" v-if="getTrackingMeta">
          <v-card-text>
            <div class="text-body-1">Projects</div>
            <div class="text-h2 text-center">{{ getTrackingMeta.n_projects }}</div>
          </v-card-text>
          <v-btn
            icon
            class="controls-top-right"
            :to="{ name: 'projects', params: { id: 'undefined' } }"
          >
            <v-icon>mdi-pencil-box-multiple-outline</v-icon>
          </v-btn>
        </v-card>
      </v-col>
      <v-col>
        <v-card width="160" v-if="getAuthMeta" :to="{ name: 'users' }">
          <v-card-text>
            <div class="text-body-1">Users</div>
            <div class="text-h2 text-center">{{ getAuthMeta.n_users }}</div>
          </v-card-text>
          <v-btn icon class="controls-top-right">
            <v-icon>mdi-pencil-box-multiple-outline</v-icon>
          </v-btn>
        </v-card>
      </v-col>
      <v-col>
        <v-card width="160" v-if="getTrackingMeta" :disabled="true">
          <v-card-text>
            <div class="text-body-1">Servables</div>
            <div class="text-h2 text-center">{{ getTrackingMeta.n_servables }}</div>
          </v-card-text>
          <v-btn icon class="controls-top-right">
            <v-icon>mdi-pencil-box-multiple-outline</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="dashboard-row-2">
      <v-col>
        <v-card width="190" v-if="getAuthMeta" :to="{ name: 'access-requests' }">
          <v-card-text>
            <div class="text-body-1">Access Requests</div>
            <div class="text-h2 text-center">{{ getAuthMeta.n_pending_requests }}</div>
          </v-card-text>
          <v-btn icon class="controls-top-right">
            <v-icon>mdi-pencil-box-multiple-outline</v-icon>
          </v-btn>
        </v-card>
      </v-col>
      <v-col>
        <v-card width="160" :disabled="true">
          <v-card-text>
            <div class="text-body-1">Environments</div>
            <div class="text-h2 text-center">0</div>
          </v-card-text>
          <v-btn icon class="controls-top-right">
            <v-icon>mdi-pencil-box-multiple-outline</v-icon>
          </v-btn>
        </v-card>
      </v-col>
      <v-col>
        <v-card width="160" :disabled="true">
          <v-card-text>
            <div class="text-body-1">Deployments</div>
            <div class="text-h2 text-center">0</div>
          </v-card-text>
          <v-btn icon class="controls-top-right">
            <v-icon>mdi-pencil-box-multiple-outline</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AdminView2",
  computed: mapGetters(["getAuthMeta", "getTrackingMeta", "isStaff"]),
  data() {
    return {
      cardWidth: "170",
    };
  },
  created() {
    this.$store.dispatch("fetchAuthMeta")
    .then(() => {
      this.$store.dispatch("fetchTrackingMeta");
    })
  },
  methods: {},
};
</script>

<style>
.dashboard-row-1 {
  max-width: 750px;
}

.dashboard-row-2 {
  max-width: 650px;
}
</style>
