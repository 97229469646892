import { ToolingClient } from "@/store/common/axio-api";
import * as utils from "@/store/common/utils";

const state = {
  toolkits: null,
  toolkit: null,
  activeToolkitCreationId: localStorage.getItem("activeToolkitCreationId") || null,
};

const getters = {
  getToolkits: (state) => state.toolkits,
  getToolkit: (state) => state.toolkit,
  getActiveToolkitCreationId: (state) => state.activeToolkitCreationId,
};

const mutations = {
  SET_TOOLKITS: (state, toolkits) => (state.toolkits = toolkits),
  SET_TOOLKIT: (state, toolkit) => (state.toolkit = toolkit),
  SET_ACTIVE_TOOLKIT_CREATION_ID: (state, id) => {
    state.activeToolkitCreationId = id;
    if (id == null) localStorage.removeItem("activeToolkitCreationId");
    else localStorage.setItem("activeToolkitCreationId", id);
  }
};

const actions = {
  createToolkit({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const tenantId = rootGetters.getSelectedTenant.uri.id;
      const url = `tenants/${tenantId}/toolkits/`;
      ToolingClient.post(url, payload, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit('SET_TOOLKIT', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchToolkit({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      const url = `toolkits/${id}/`;
      ToolingClient.get(url, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit('SET_TOOLKIT', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  updateToolkit({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const url = `toolkits/${payload.uri.id}/`;
      ToolingClient.put(url, payload, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit('SET_TOOLKIT', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  resetToolkit({ rootGetters }, id) {
    return new Promise((resolve, reject) => {
      const url = `toolkits/${id}/feedbacks/clear/`;
      console.log('resetToolkit');
      ToolingClient.post(url, {}, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchToolkits({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      const tenantId = rootGetters.getSelectedTenant.uri.id;
      var baseUrl = utils.prepareURL(`tenants/${tenantId}/toolkits/?`, options);
      ToolingClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit('SET_TOOLKITS', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
