<template>
  <v-container fluid>
    <v-banner icon="mdi-package-variant-closed"
      >Project Servables
      <span v-if="getServables">({{ getServables.count }} found)</span>
    </v-banner>
    <v-row v-if="getServables" justify="start">
      <v-col
        class="shrink-col"
        v-for="(servable, index) in getServables.results"
        :key="'key-1-chart-' + index"
      >
        <v-card class="servable-card mt-3">
          <div class="controls-top-right">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link @click="setFlag(servable)">
                  <v-list-item-subtitle>
                    {{ servable.activated ? "De-activate" : "Activate" }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item link @click="( editServableId = servable.uri.id )">
                  <v-list-item-subtitle>Edit</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <v-card-text>
            <div>
              <router-link
                class="primary-link mr-2"
                :to="{
                  name: 'servable',
                  params: { id: servable.uri.id },
                }"
                >{{ servable.name }}</router-link
              >
              <v-chip outlined small>
                v{{ servable.major_version }}.{{ servable.minor_version }}
              </v-chip>
            </div>
            <v-divider class="my-3"></v-divider>
            <div>Created On {{ servable.created_on | localdatetime }}</div>
            <div class="text-caption">{{ servable.desc | charlimit100 }}</div>
            <div class="text-caption">
              By {{ servable.created_by.username }}
            </div>
            <div class="mt-2">
              <v-chip
                small
                class=""
                color="green"
                text-color="white"
                v-if="servable.activated"
              >
                Active
              </v-chip>
              <v-chip small class="" color="" text-color="white" v-else>
                Inactive
              </v-chip>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center mt-3" v-if="options.numberOfPages > 1">
      <v-pagination
        v-model="options.page"
        :length="options.numberOfPages"
        :total-visible="6"
        @next="fetchServables()"
        @previous="fetchServables()"
      ></v-pagination>
    </div>
    <ServableActivation v-bind:id="activationId" @closed="resetFlags()" />
    <ServableDeactivation v-bind:id="deactivationId" @closed="resetFlags()" />
    <EditServable
      @closed="modalClosed('edit')"
      v-bind:id="editServableId"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ServableActivation from "../../components/servables/ServableActivation.vue";
import ServableDeactivation from "../../components/servables/ServableDeactivation.vue";
import EditServable from "@/components/servables/EditServable.vue"

export default {
  name: "ServableList",
  computed: mapGetters(["getServables"]),
  components: { ServableActivation, ServableDeactivation, EditServable },
  data() {
    return {
      loading: false,
      activationId: null,
      deactivationId: null,
      editServableId: null,
      options: {
        page: 1,
        itemsPerPage: 12,
        numberOfPages: 0,
        search: null,
      },
    };
  },
  async created() {
    await this.fetchServables();
  },
  methods: {
    resetFlags() {
      this.activationId = null;
      this.deactivationId = null;
      this.fetchServables();
    },
    setFlag(servable) {
      if (servable.activated) this.deactivationId = servable.uri.id;
      else this.activationId = servable.uri.id;
    },
    async fetchServables() {
      this.loading = false;
      try{
        await this.$store.dispatch("fetchServables", this.options);
        this.options.numberOfPages = Math.ceil(
          this.getServables.count / this.options.itemsPerPage
        );
      }
      finally{
        this.loading = false;
      }
    },
    async modalClosed(type){
      if(type=="edit"){
        this.editServableId = null;
        await this.fetchServables();
      }
    }
  },
};
</script>

<style>
.servable-card {
  min-width: 350px;
}
</style>
