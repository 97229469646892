import ModelCardView from "../views/store/ModelCardView.vue";
import ModelStoreView from "../views/store/ModelStoreView.vue";

export default [
  {
    path: "/store/:id",
    name: "model-card",
    component: ModelCardView,
  },
  {
    path: "/store",
    name: "store",
    component: ModelStoreView,
  }
];