import openAPIDef from "./openapi.json";
const Ajv = require("ajv");
const ajv = new Ajv({ strict: false, allErrors: false });

const metadataSchema = ajv.compile(openAPIDef.components.schemas.OneMLMetadata);
const searchSchema = ajv.compile(openAPIDef.components.schemas.OneMLSearchResponse);
const documentSchema = ajv.compile(openAPIDef.components.schemas.OneMLDocumentResponse);

const validateMetadata = (metadata) => {
  const valid = metadataSchema(metadata);
  if (!valid) {
    console.log(metadataSchema.errors);
  }
  return valid;
}

const validateSearch = (search) => {
  const valid = searchSchema(search);
  if (!valid) {
    console.log(searchSchema.errors);
  }
  return valid;
}

const validateDocument = (document) => {
  const valid = documentSchema(document);
  if (!valid) {
    console.log(documentSchema.errors);
  }
  return valid;
}

export { validateMetadata, validateSearch, validateDocument };