function localdatetime_fn(value) {
  if (!value) return "";
  value = value.toString();
  let dateObj = new Date(value);
  return (
    dateObj.toLocaleDateString() +
    " " +
    dateObj.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );
}

function charLimit_n(value, limit) {
  if (!value) return "";
  value = value.toString();
  if (value.length > limit) {
    return value.substring(0, limit) + "...";
  }
  return value;
}

function charLimit_100(value) {
  return charLimit_n(value, 100);
}

function charLimit_30(value) {
  return charLimit_n(value, 30);
}

function charLimit_20(value) {
  return charLimit_n(value, 20);
}

function fileSize(value) {
  if (value == 0) return "<1 Byte";
  if (value < 1024) return value.toPrecision(3).toString() + " Bytes";
  const valueInKB = value / 1024;
  if (valueInKB < 1024) return valueInKB.toPrecision(3).toString() + " KB";
  const valueInMB = valueInKB / 1024;
  if (valueInMB < 1024) return valueInMB.toPrecision(3).toString() + " MB";
  return (valueInMB / 1024).toPrecision(3).toString() + " GB";
}

function status(value) {
  if (!value) return "";
  value = value.replaceAll("_", " ");
  return value
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
}

export {
  localdatetime_fn,
  charLimit_30,
  charLimit_20,
  charLimit_100,
  status,
  fileSize,
};
