<template>
  <v-container fluid>
    <v-banner two-line>
      <v-avatar slot="icon" color="primary" size="40">
        <v-icon color="white"> mdi-list-box-outline </v-icon>
      </v-avatar>
      <div>
        <div class="text-body-1">Tenant Servables</div>
        <div class="text-caption" v-if="getTenantServables">
          {{ getTenantServables.project_count }} projects found
        </div>
      </div>
    </v-banner>
    <v-text-field
      v-model="searchData.search"
      label="Search"
      single-line
      hide-details
      @keyup.enter="()=> {
        resetPagination();
        fetchTenantServables();
      }"
      class="mt-2"
    ></v-text-field>
    <v-chip-group
      active-class="primary--text"
      v-model="chipSelection"
      :multiple="true"
      @change="applyFilter()"
    >
      <v-chip filter outlined key="servable" value="servable">
        Projects with Servables
      </v-chip>
      <v-chip filter outlined key="deployable" value="deployable">
        Deployable Servables
      </v-chip>
    </v-chip-group>
    <v-data-iterator 
      v-if="getTenantServables"
      :items="getTenantServables.results"
      :server-items-length="getTenantServables.project_count"
      :options.sync="iteratorOptions"
      :footer-props="{
        'items-per-page-options': [20, 30, 40, 50],
      }"
    >
      <template v-slot:default="props">
        <div v-for="projectItem in props.items" :key="projectItem.uri.id">
          <v-card class="mt-3">
            <v-card-title>
              {{ projectItem.name }}
            </v-card-title>
            <v-card-subtitle>
              {{ projectItem.desc }}
            </v-card-subtitle>
            <!-- <v-card-subtitle>
              {{ projectItem.created_on | localdatetime }}
            </v-card-subtitle> -->
            <v-card-text v-if="!projectItem.flag_servable_available">
              <v-chip color="red" outlined>
                <v-icon left small>mdi-alert</v-icon>
                No Servable Found</v-chip>
            </v-card-text>
            <v-simple-table v-else>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Description</th>
                    <th class="text-left">Version</th>
                    <th class="text-left">Created On</th>
                    <th class="text-left">Created By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(servableItem, index) in projectItem.servables" :key="servableItem.uri.id">
                    <td>
                      <router-link
                        tag="a"
                        target="_blank"
                        :to="{ name: 'servable', params: { id: servableItem.uri.id } }"
                        class="secondary-link"
                      >
                        {{ servableItem.name | charlimit30 }}
                      </router-link>
                      <v-icon color="success" v-if="index == 0">
                        mdi-check-circle
                      </v-icon>
                    </td>
                    <td>{{ servableItem.desc | charlimit30 }}</td>
                    <td>{{ servableItem.major_version }}.{{ servableItem.minor_version }}</td>
                    <td>{{ servableItem.created_on | localdatetime}}</td>
                    <td>{{ servableItem.created_by.username }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </div>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { charLimit_100, localdatetime_fn } from "@/filters";
import { mapGetters } from "vuex";

export default {
  name: "TenantServables",
  computed: mapGetters(["getTenantServables"]),
  data() {
    return {
      dialog: false,
      loading: false,
      iteratorOptions: {
        init: true,
        page: 1,
        itemsPerPage: 20,
      },
      searchData:{
        search: "",
        deployable: false,
        servable: true,
      },
      chipSelection: ["servable"],
    };
  },
  watch:{
    iteratorOptions:{
      handler: async function (oldVal, newOptions){
        if(!this.iteratorOptions.init){
          await this.fetchTenantServables();
        }
        this.iteratorOptions.init = false;
      },
    }
  },
  created(){
    this.fetchTenantServables();
  },
  methods:{
    resetPagination(){
      this.iteratorOptions.init = true;
      this.iteratorOptions.page = 1;
    },
    async applyFilter(){
      this.searchData.deployable = this.chipSelection.includes("deployable");
      this.searchData.servable = this.chipSelection.includes("servable");
      this.resetPagination();
      await this.fetchTenantServables();
    },
    async fetchTenantServables(){
      let optionsCopy = structuredClone(this.iteratorOptions);
      optionsCopy.search = this.searchData.search;
      optionsCopy.deployable = this.searchData.deployable;
      optionsCopy.servable = this.searchData.servable;
      await this.$store.dispatch("fetchTenantServables", optionsCopy);
    }
  }
}
</script>

<style>

</style>