import { TrackingClient } from "@/store/common/axio-api";
import * as utils from "@/store/common/utils";

const state = {
  servables: null,
  tenantServables: null,
  servable: null,
};

const getters = {
  getServables: (state) => state.servables,
  getTenantServables: (state) => state.tenantServables,
  getServable: (state) => state.servable,
};

const mutations = {
  SET_SERVABLES: (state, servables) => {state.servables = servables;},
  SET_TENANT_SERVABLES: (state, servables) => (state.tenantServables = servables),
  SET_SERVABLE: (state, servable) => (state.servable = servable),
};

const actions = {
  fetchNewServableMetadata({ commit, rootGetters }, artifactId) {
    return new Promise((resolve, reject) => {
      TrackingClient.get(
        `artifacts/${artifactId}/servables/metadata`,
        rootGetters.getAuthHeaders
      )
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  createNewServable({ rootGetters }, servable) {
    return new Promise((resolve, reject) => {
      TrackingClient.post(
        `artifacts/${servable.artifactId}/servables/`,
        servable,
        rootGetters.getAuthHeaders
      )
        .then(function (reponse) {
          resolve(reponse.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchServables({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      const baseUrl = utils.prepareURL(
        `projects/${rootGetters.getSelectedProject.uri.id}/servables?`,
        options
      );
      TrackingClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_SERVABLES", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  async fetchTenantServables({ commit, rootGetters }, options) {
    let baseUrl = utils.prepareURL(`tenants/${rootGetters.getSelectedTenant.uri.id}/servables?`, options);
    baseUrl += `&deployable=${(options.deployable.toString())}&servable=${(options.servable.toString())}`;
    console.log(baseUrl);
    const response = await TrackingClient.get(baseUrl, rootGetters.getAuthHeaders);
    commit("SET_TENANT_SERVABLES", response.data);
    return response.data;
  },
  fetchServable({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      TrackingClient.get(`servables/${id}`, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_SERVABLE", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  patchServable({ rootGetters }, servable) {
    return new Promise((resolve, reject) => {
      TrackingClient.patch(
        `servables/${servable.id}/activation/`,
        servable,
        rootGetters.getAuthHeaders
      )
        .then(function (reponse) {
          resolve(reponse);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  async editServable({ commit, rootGetters }, servable) {
    const response = await TrackingClient.put(
      `servables/${servable.id}/`,
      servable.data,
      rootGetters.getAuthHeaders
    );
    await commit("SET_SERVABLE", response.data);
    return response.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
