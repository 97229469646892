<template>
  <v-dialog v-model="showDialog" width="1000" persistent>
    <v-card>
      <v-card-title>
        Create Feedback Analytics Snapshot
      </v-card-title>
      <v-card-text v-if="snapshotObj">
        <hr>
        <v-row>
          <v-col>
            <validation-observer ref="observer">
              <form @submit.prevent="submit">
                <validation-provider
                  v-slot="{ errors }"
                  name="project_name"
                  rules="required"
                >
                  <v-text-field
                    class="mt-4"
                    v-if="toolkit"
                    name="project_name"
                    label="Project Name*"
                    v-model="toolkit.project.name"
                    :error-messages="errors"
                    :disabled="true"
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="experiment"
                  rules="required"
                >
                  <v-autocomplete
                    v-if="getExperiments"
                    :items="getExperiments.results"
                    item-text="name"
                    item-value="uri.id"
                    cache-items
                    flat
                    hide-no-data
                    v-model="trackingData.experiment"
                    :search-input.sync="exp_search"
                    :error-messages="errors"
                    label="Experiment*"
                    hint="Select an experiment to associate with this snapshot"
                    persistent-hint
                    required
                  ></v-autocomplete>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="tranche"
                  rules="required"
                >
                  <v-text-field
                    name="tranche"
                    label="Tranche*"
                    v-model="trackingData.tranche"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="trial_name"
                >
                  <v-text-field
                    name="trial_name"
                    label="Trial Name"
                    v-model="trackingData.trial_name"
                    :error-messages="errors"
                    hint="Optional. If not provided, the trial name will be generated automatically."
                    persistent-hint
                  ></v-text-field>
                </validation-provider>
                <div class="mt-4">
                  <v-btn type="submit" color="primary" 
                    :loading="loading"
                  >Save</v-btn
                  >
                  <v-btn text @click="closeDialog(false)">Close</v-btn>
                </div>
              </form>
            </validation-observer>
          </v-col>
          <v-col>
            <v-sheet
              dark
              color="grey darken-2"
              elevation="4"
              class="serialized-object"
            >
              <b>Feedback Data being sent to OneML Tracking</b>
              <br>
              {{ snapshotObj.serializedString }}
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapGetters } from "vuex";

setInteractionMode("eager");

export default {
  name: 'CreateSnapshot',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ...mapGetters(["getExperiments",]),
  },
  props:{
    showDialog: {
      type: Boolean,
      default: false
    },
    snapshotObj:{
      type: Object,
      default: null
    },
    toolkit: null
  },
  data() {
    return {
      loading: false,
      trackingData: {
        project: null,
        experiment: null,
        tranche: "search-feedback",
        trial_name: null,
      },
      experimentOptions: {
        id: null,
        page: 1,
        itemsPerPage: 2,
        search: null,
        lastSearch: null,
      },
      exp_search: null,
      uiFlags:{
        loadingExperiments: false,
        loading: false,
      }
    }
  },
  watch:{
    showDialog: {
      handler: async function(val){
        if(val){
          await this.fetchExperiments();
        }
      }
    },
    exp_search: {
      handler: async function(val){
        if(val && val != this.experimentOptions.lastSearch){
          await this.fetchExperiments();
        }
      }
    }
  },
  methods:{
    closeDialog(refresh){
      this.$emit('closed', refresh);
    },
    async fetchExperiments(){
      console.log("fetching experiments");
      this.experimentOptions.search = this.exp_search;
      this.experimentOptions.id = this.toolkit.project.uri.id;
      await this.$store.dispatch("fetchExperimentsByProject", this.experimentOptions);
      this.experimentOptions.lastSearch = this.experimentOptions.search;
    },
    createTrial(){
      
    },
    logMetricsAndCloseTrial(trial, metrics){
      try{
        this.$store.dispatch("logMetricsAndCloseTrial", {
          trial_id: trial.uri.id,
          metrics: metrics,
        });
      }
      catch(err){
        this.$store.commit("showNotification", "Something went wrong");
        throw err;
      }
    },
    submit(){
      try{
        this.$store.dispatch("createTrial", {
          experiment_id: this.trackingData.experiment,
          body: {
            name: this.trackingData.trial_name,
            tranche: this.trackingData.tranche,
            host_details: {
              'host': 'OneML Tools Service'
            },
            params: {
              'toolkit': this.toolkit.name,
              'toolkit_type': this.toolkit.type,
            }
          }
        })
        .then((trial) => {
          this.logMetricsAndCloseTrial(trial, this.snapshotObj.object);
          this.$store.commit("showNotification", "A snapshot of the feedback data has been created");
          this.closeDialog(true);
        });
      }
      catch(err){
        if (err.response.status == 400) {
          this.$refs.observer.setErrors(err.response.data);
        } else {
          this.$store.commit("showNotification", "Something went wrong");
          this.closeDialog();
        }
        throw err;
      }
      finally{
        this.uiFlags.loading = false;
      }
    }
  }
}
</script>

<style>

.serialized-object {
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 400px;
  padding: 10px 10px;
  margin: 20px 20px;
  height: 362px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}

</style>