<template>
	<div>
		<v-row justify="center">
			<!-- Confirmation Dialog -->
			<v-dialog
				persistent
				max-width="500px"
				v-model="uiFlags.dialogConfirmDeletion"
			>
				<v-card>
					<v-card-title>
						<span class="headline">Confirm Deletion</span>
					</v-card-title>
					<v-card-text>
						<div v-if="uiFlags.isValidating" class="d-flex">
							<span>
								Verifying if your access to delete these experiments...
							</span>
							<v-progress-circular
								indeterminate
								color="primary"
							></v-progress-circular>
						</div>
						<div v-else>
							<div v-if="uiFlags.hasValidationErrors">
								<div>
									We've found the following issues with your deletion request:
								</div>
								<div class="font-weight-medium red--text">
									{{ validationError }}
								</div>
							</div>
							<div v-else>
								<p>
									Are you sure you want to delete the following experiments?
								</p>
							</div>
						</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="error"
							small
							v-if="!uiFlags.isValidating && !uiFlags.hasValidationErrors"
							@click="deleteExperiments()"
							:loading="uiFlags.isDeleting"
							>Delete
						</v-btn>
						<v-btn text small @click="closeDialog(false)">Cancel</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<!-- Success Dialog -->
			<v-dialog
				persistent
				max-width="500px"
				v-model="uiFlags.dialogDeletionSuccess"
			>
				<v-card>
					<v-card-title>
						<span class="headline">Alert</span>
					</v-card-title>
					<v-card-text>
						A request to delete the selected experiments has been raised and
						will be processed shortly.
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn text small @click="closeDialog(true)">Close</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
	</div>
</template>

<script>
// Import Vuex getters
import { mapGetters } from "vuex";

export default {
	name: "ExperimentDeletionView",
	computed: {
		...mapGetters(["getSelectedProject"]),
	},
	props: {
		// Accept a list of experiment ids
		experimentIds: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			uiFlags: {
				isValidating: false,
				isDeleting: false,
				hasValidationErrors: false,
				dialogConfirmDeletion: false,
				dialogDeletionSuccess: false,
			},
			validationError: null,
		};
	},
	watch: {
		experimentIds() {
			if (this.experimentIds.length > 0) {
				this.validateDeletion();
			} else {
				this.resetFlags();
			}
		},
	},
	methods: {
		closeDialog(deleted) {
			if (deleted) {
				this.$emit("deleted");
			} else {
				this.$emit("closed");
			}
		},
		resetFlags() {
			this.uiFlags.isDeleting = false;
			this.uiFlags.isValidating = false;
			this.uiFlags.dialogConfirmDeletion = false;
			this.uiFlags.dialogDeletionSuccess = false;
			this.uiFlags.hasValidationErrors = false;
		},
		validateDeletion() {
			this.resetFlags();
			this.uiFlags.dialogConfirmDeletion = true;
			this.uiFlags.isValidating = true;
			// Delay for UI jitters
			setTimeout(() => {
				this.$store
					.dispatch("validateExperimentDeletion", {
						project_id: this.getSelectedProject.uri.id,
						experiment_ids: this.experimentIds,
					})
					.catch((error) => {
						this.uiFlags.isValidating = false;
						this.uiFlags.hasValidationErrors = true;
						console.log(error);
						this.validationError = error.response.data["experiment_ids"];
					})
					.finally(() => {
						this.uiFlags.isValidating = false;
					});
			}, 1000);
		},
		deleteExperiments() {
			this.uiFlags.isDeleting = true;
			this.$store
				.dispatch("deleteExperiments", {
					project_id: this.getSelectedProject.uri.id,
					experiment_ids: this.experimentIds,
				})
				.catch((err) => {
					console.error(err);
					this.$store.commit("showNotification", "Something went wrong");
					this.closeDialog(false);
				})
				.finally(() => {
					this.uiFlags.dialogDeletionSuccess = true;
				});
		},
	},
};
</script>
