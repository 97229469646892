import Vue from "vue";
import VueRouter from "vue-router";
import MasterLayout from "../master/MasterView.vue";
import AdminView from "../views/admin/AdminView.vue";
import ExperimentList from "../views/experiments/ExperimentList.vue";
import ArtifactPublishView from "../views/artifacts/ArtifactPublishView.vue";
import userViews from "./users";
import projectViews from "./projects";
import trialViews from "./trials";
import modelStoreViews from "./modelStore";
import servableViews from "./servables";
import tenantViews from "./tenants";
import genericViews from "./generics";
import accessRequests from "./accessRequests";
import anonymousViews from "./anonymous";
import toolkitViews from "./toolkits";
import artifactViews from "./artifacts";
Vue.use(VueRouter);

const childrenToMaster = [
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
  },
  {
    path: "/experiments",
    name: "experiments",
    component: ExperimentList,
  },
  {
    path: "/artifacts/:id/publish",
    name: "publish-artifact",
    component: ArtifactPublishView,
  },
  ...genericViews,
  ...tenantViews,
  ...userViews,
  ...projectViews,
  ...trialViews,
  ...modelStoreViews,
  ...servableViews,
  ...accessRequests,
  ...toolkitViews,
  ...artifactViews,
]

const routes = [
  {
    path: "/",
    component: MasterLayout,
    children: childrenToMaster
  },
  ...anonymousViews
];

const router = new VueRouter({
  routes,
  scrollBehavior(to) {
    if (to.query.section == undefined) {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.name == undefined || to.name == null) {
    next({ name: "about" });
  } else {
    next();
  }
});

export default router;
