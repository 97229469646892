<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" max-width="550px" persistent>
      <v-card v-if="flags.confirmPasswordChange">
        <v-card-title>
          <div class="text-body-1">Change Password</div>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="observer">
            <form @submit.prevent="submit">
              <validation-provider
                v-slot="{ errors }"
                name="existing_password"
                rules="required"
              >
                <v-text-field
                  name="existing_password"
                  label="Existing Password*"
                  type="password"
                  v-model="user.existing_password"
                  :error-messages="errors"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="new_password"
                rules="required"
              >
                <v-text-field
                  name="new_password"
                  label="New Password*"
                  type="password"
                  v-model="user.new_password"
                  :error-messages="errors"
                  hint="Password must be at least 8 characters long without space and must contain at least one uppercase letter & one lowercase letter."
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="confirm_password"
                rules="required"
              >
                <v-text-field
                  name="confirm_password"
                  label="Confirm Password*"
                  type="password"
                  v-model="user.confirm_password"
                  :error-messages="errors"
                  required
                ></v-text-field>
              </validation-provider>
              <div class="d-flex flex-row justify-end">
                <v-btn @click="submit" color="primary" :loading="loading"
                      >Save</v-btn
                    >
                <v-btn text @click="closeDialog(false)">Cancel</v-btn>
              </div>
            </form>
          </validation-observer>
        </v-card-text>
      </v-card>
      <v-card v-if="flags.confirmLoginRedirection">
        <v-card-title>
          <div class="text-body-1">Redirecting to Login</div>
        </v-card-title>
        <v-card-text>
          <p>
            Your password has been changed successfully. 
            Please login again to continue. 
          </p>
        </v-card-text>
        <v-card-actions class="d-flex flex-row justify-end">
          <v-btn text @click="redirectToLogin()">Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "ChangePassword",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    showDialog: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    showDialog: function (newVal) {
      if (newVal) {
        this.flags.confirmPasswordChange = true;
        this.flags.confirmLoginRedirection = false;
        this.user.existing_password = "";
        this.user.new_password = "";
        this.user.confirm_password = "";
      }
    },
  },
  data() {
    return {
      flags: {
        confirmPasswordChange: true,
        confirmLoginRedirection: false
      },
      user: {
        existing_password: "",
        new_password: "",
        confirm_password: "",
      },
      loading: false,
    };
  },
  methods:{
    closeDialog() {
      this.$emit("closed");
    },
    submit() {
      this.loading = true;
      this.$store
        .dispatch("changePassword", this.user)
        .then(() => {
          this.$store.commit("showNotification", "Password changed successfully");
          this.$store.dispatch("logout")
            .then(() => {
              this.flags.confirmPasswordChange = false;
              this.flags.confirmLoginRedirection = true;
            });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
            this.closeDialog();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    redirectToLogin() {
      this.$router.push({ name: "landing" });
    },
  }
}
</script>

<style>

</style>