
class CacheWithExpiry {
  constructor(ttlInSeconds) {
    this.cache = new Map();
    this.ttl = ttlInSeconds * 1000; // Convert seconds to milliseconds
  }

  set(key, value) {
    const expirationTime = Date.now() + this.ttl;
    this.cache.set(key, { value, expirationTime });
  }

  get(key) {
    const entry = this.cache.get(key);
    if (!entry) return undefined;
    return entry.value;
  }

  has(key) {
    return this.cache.has(key);
  }

  clear() {
    this.cache.clear();
  }

  size() {
    return this.cache.size;
  }

  cleanupExpired() {
    let deleted = 0;
    const now = Date.now();
    for (const [key, entry] of this.cache.entries()) {
      if (now >= entry.expirationTime) {
        this.cache.delete(key);
        deleted++;
      }
    }
    return deleted;
  }

  toJSON() {
    const serializedCache = Array.from(this.cache.entries()).reduce((obj, [key, entry]) => {
      obj[key] = { value: entry.value, expirationTime: entry.expirationTime };
      return obj;
    }, {});
    return JSON.stringify(serializedCache);
  }

  static fromJSON(jsonString, ttlInSeconds) {
    const cacheData = JSON.parse(jsonString);
    const cache = new CacheWithExpiry(ttlInSeconds);

    for (const key in cacheData) {
      const { value, expirationTime } = cacheData[key];
      if (expirationTime > Date.now()) {
        cache.cache.set(key, { value, expirationTime });
      }
    }

    return cache;
  }
}

export default CacheWithExpiry;