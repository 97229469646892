<template>
  <v-container fluid>
    <v-data-table
      v-if="getTenants"
      :headers="headers"
      :items="getTenants.results"
      :server-items-length="getTenants.count"
      :options.sync="options"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <div class="pa-4">Manage Tenants</div>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
          v-on:keyup.enter="fetchAllTenants()"
          clearable
        ></v-text-field>
      </template>
      <template v-slot:[`item.created_on`]="{ item }">
        {{ item.created_on | localdatetime }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          small
          v-tooltip="'Manage Projects'"
          :to="{ name: 'projects', params: { id: item.uri.id } }"
          class="ml-2"
        >
          <v-icon> mdi-format-list-bulleted </v-icon></v-btn
        >
        <v-btn
          icon
          small
          v-tooltip="'Edit Tenant'"
          @click="modalFlags.tenantEditId = item.uri.id"
          class="ml-2"
          ><v-icon> mdi-pencil </v-icon></v-btn
        >
        <v-btn small icon v-tooltip="'Manage Tenant Users'" class="ml-2"
          :to="{ name: 'tenant-users', params: { id: item.uri.id } }"
          ><v-icon> mdi-account-multiple-plus </v-icon></v-btn
        >
      </template>
    </v-data-table>
    <v-btn
      fab
      dark
      color="primary"
      @click="modalFlags.newTenant = true"
      class="floating-button"
      v-tooltip="'Create New Tenant'"
    >
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
    <NewTenant
      v-bind:showDialog="modalFlags.newTenant"
      @closed="modalClosed('newTenant')"
    />
    <EditTenant
      v-bind:id="modalFlags.tenantEditId"
      @closed="modalClosed('editTenant')"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import NewTenant from "../../components/tenant/NewTenant.vue";
import EditTenant from "../../components/tenant/EditTenant.vue";

export default {
  name: "TenantList",
  computed: mapGetters(["getTenants"]),
  components: { NewTenant, EditTenant },
  data() {
    return {
      modalFlags: {
        newTenant: false,
        tenantEditId: null,
      },
      loading: false,
      search: "",
      options: {
        init: true,
        itemsPerPage: 10,
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Created On", value: "created_on" },
        { text: "Max Allowed Projects", value: "max_projects" },
        { text: "Action", value: "actions" },
      ],
    };
  },
  created() {
    this.fetchAllTenants();
  },
  watch:{
    options: {
      handler: function (oldVal, newOptions){
        if(!this.options.init){
          this.fetchAllTenants();
        }
        this.options.init = false;
      },
    },
  },
  methods: {
    fetchAllTenants() {
      console.log('fetching all tenants');
      this.loading = true;
      let optionsCopy = structuredClone(this.options);
      optionsCopy.search = this.search;
      this.$store
        .dispatch("fetchAllTenants", optionsCopy)
        .finally(() => (this.loading = false));
    },
    modalClosed(type) {
      if (type == "newTenant") {
        this.modalFlags.newTenant = false;
        this.fetchAllTenants();
      } else if (type == "editTenant") {
        this.modalFlags.tenantEditId = null;
        this.fetchAllTenants();
      }
    },
  },
};
</script>

<style></style>
