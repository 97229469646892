<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="showDialog" max-width="700" persistent>
        <v-card>
          <v-card-title>
            <div class="text-body-1">Edit Servable</div>
          </v-card-title>
          <v-card-text v-if="getServable">
            <validation-observer ref="observer">
              <form @submit.prevent="submit">
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                >
                  <v-text-field
                    name="name"
                    label="Name*"
                    v-model="servable.name"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="desc"
                  rules="required"
                >
                  <v-textarea
                    name="desc"
                    label="Description*"
                    v-model="servable.desc"
                    :error-messages="errors"
                    required
                  ></v-textarea>
                </validation-provider>
                <v-btn type="submit" color="primary" :loading="loading"
                  >Submit</v-btn
                >
                <v-btn text @click="closeDialog(false)">Close</v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "EditServable",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  computed: mapGetters(["getServable"]),
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  watch: {
    id: function (newId) {
      if (newId != null) {
        this.$store.dispatch("fetchServable", this.id).then((data) => {
          this.showDialog = true;
          this.servable.name = data.name;
          this.servable.desc = data.desc;
        });
      }
      else{
        this.showDialog = false;
      }
    },
  },
  data(){
    return {
      servable: {
        name: null,
        desc: null,
      },
      loading: false,
      showDialog: false,
    }
  },
  methods: {
    closeDialog: function (saved) {
      // this.showDialog = false;
      this.$emit("closed", saved);
    },
    submit() {
      this.loading = true;
      this.$store
        .dispatch("editServable", {
          id: this.id,
          data: this.servable,
        })
        .then(() => {
          this.closeDialog();
          this.$store.commit("showNotification", "Changes successfully saved");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
            this.closeDialog();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>

<style>

</style>