<template>
  <v-container fluid>
    <v-banner two-line v-if="getServable">
      <v-avatar
        slot="icon"
        :color="getServable.activated ? 'success' : 'secondary'"
        size="40"
        ><v-icon icon="mdi-lock" color="white"> mdi-package-variant </v-icon>
      </v-avatar>
      <div>
        {{ getServable.name }}
        <v-chip small outlined
          >v{{ getServable.major_version }}.{{
            getServable.minor_version
          }}</v-chip
        >
      </div>
      <div class="text-caption">{{ getServable.desc }}</div>
      <template v-slot:actions>
        <v-btn
          text
          color="secondary"
          @click="editServableId = getServable.uri.id"
        >
          Edit
        </v-btn>
        <v-btn
          v-if="getServable.activated"
          text
          color="primary"
          @click="deactivationId = getServable.uri.id"
        >
          Deactivate
        </v-btn>
        <v-btn
          v-else
          text
          color="primary"
          @click="activationId = getServable.uri.id"
        >
          Activate
        </v-btn>
      </template>
    </v-banner>
    <ServableActivation v-bind:id="activationId" @closed="resetFlags()" />
    <ServableDeactivation v-bind:id="deactivationId" @closed="resetFlags()" />
    <v-row class="mt-3 mb-3">
      <v-col class="shrink-col">
        <v-card :loading="this.loadingFlags.trial" min-width="380">
          <v-card-text>
            <div>Servable Summary</div>
            <v-list dense v-if="getServable && getTrial">
              <v-list-item>
                <v-list-item-subtitle>
                  Servable Created By:
                  <v-chip small> {{ getServable.created_by.username }} </v-chip>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  Trial Created By:
                  <v-chip small> {{ getTrial.created_by.username }} </v-chip>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  Servable Created At:
                  {{ getServable.created_on | localdatetime }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  Trial Created At:
                  {{ getTrial.created_on | localdatetime }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  Trial :
                  <v-chip
                    target="_blank"
                    label
                    small
                    color="primary"
                    :to="{ name: 'trial', params: { id: getTrial.uri.id } }"
                  >
                    {{ getTrial.name.toUpperCase() }}
                    <v-icon small right>mdi-open-in-new</v-icon></v-chip
                  >
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  Experiment :
                  <v-chip
                    target="_blank"
                    label
                    small
                    color="primary"
                    :to="{
                      name: 'experiments',
                      query: { name: getTrial.experiment.name },
                    }"
                  >
                    {{ getTrial.experiment.name.toUpperCase() }}
                    <v-icon small right>mdi-open-in-new</v-icon></v-chip
                  >
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="shrink-col">
        <v-card :loading="this.loadingFlags.trial" min-width="380">
          <v-card-text>
            <div>Hyper-parameters</div>
            <v-simple-table dense class="params-table" v-if="params">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in Object.entries(params)" :key="item[0]">
                    <td>{{ item[0] }}</td>
                    <td>{{ item[1] }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="shrink-col">
        <v-card :loading="this.loadingFlags.trial" min-width="380">
          <v-card-text>
            <div>Metrics Summary</div>
            <v-simple-table dense class="params-table" v-if="metrics">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in Object.entries(metrics)" :key="item[0]">
                    <td>{{ item[0] }}</td>
                    <td>{{ item[1] }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="shrink-col">
        <v-card :loading="this.loadingFlags.artifact" min-width="380">
          <v-card-text>
            <div>Artifact Summary</div>
            <v-list dense v-if="getArtifact">
              <v-list-item>
                <v-list-item-subtitle>
                  Object Name: {{ getArtifact.object_name }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  Size: {{ getArtifact.size | fileSize }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-title> Tarball Contents</v-list-item-title>
                </template>
                <v-list-group
                  v-for="entry in servableEntries"
                  :key="entry.name"
                  v-if="entry.isDir"
                  no-action
                  sub-group
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ entry.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="subDir in Object.entries(entry.value)"
                    :key="subDir[0]"
                    link
                  >
                    <v-list-item-content>
                      <v-list-item-subtitle>{{
                        subDir[0]
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <v-list-item
                  v-for="entry in servableEntries"
                  :key="entry.name"
                  v-if="!entry.isDir"
                >
                  <v-list-item-content>
                    <v-list-item-subtitle>{{
                      entry.name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col>
        <v-card
          :loading="this.loadingFlags.artifact"
          min-width="400"
          max-width="890"
        >
          <v-card-text>
            <div>Deployment Log</div>
            This data is not available at the moment.
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
    <EditServable
      @closed="modalClosed('edit')"
      v-bind:id="editServableId"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ServableActivation from "../../components/servables/ServableActivation.vue";
import ServableDeactivation from "../../components/servables/ServableDeactivation.vue";
import { reduceObject } from "@/store/common/utils";
import EditServable from "@/components/servables/EditServable.vue"

export default {
  name: "SevableDetail",
  computed: mapGetters(["getServable", "getTrial", "getArtifact"]),
  components: { ServableActivation, ServableDeactivation, reduceObject, EditServable },
  data: () => ({
    activationId: null,
    deactivationId: null,
    loadingFlags: {
      trial: false,
      artifact: false,
    },
    metrics: null,
    params: null,
    servableEntries: null,
    editServableId: null,
  }),
  created() {
    this.fetchServable();
  },
  methods: {
    fetchServable() {
      this.loadingFlags.trial = true;
      this.loadingFlags.artifact = true;
      this.$store.dispatch("fetchServable", this.$route.params.id).then(() => {
        this.$store
          .dispatch("fetchTrial", this.getServable.trial_uri.id)
          .then(() => {
            this.metrics = reduceObject(this.getTrial.metrics, null, 5);
            this.params = reduceObject(this.getTrial.params, null, 5);
          })
          .finally(() => (this.loadingFlags.trial = false));
        this.$store
          .dispatch("fetchArtifact", {
            id: this.getServable.artifact_uri.id,
            download: false,
            commit: true,
          })
          .then(() => {
            this.servableEntries = [];
            Object.entries(this.getArtifact.artifact_metadata).forEach((e) =>
              this.servableEntries.push({
                name: e[0],
                value: e[1],
                isDir: typeof e[1] == "object",
              })
            );
          })
          .finally(() => (this.loadingFlags.artifact = false));
      });
    },
    resetFlags() {
      this.activationId = null;
      this.deactivationId = null;
      this.fetchServable();
    },
    modalClosed(type){
      if(type=="edit"){
        this.editServableId = null;
      }
    }
  },
};
</script>

<style></style>
