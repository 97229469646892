<template>
  <v-container fluid>
    <v-banner two-line>
      <v-avatar slot="icon" color="primary" size="40">
        <v-icon color="white"> mdi-magnify </v-icon>
      </v-avatar>
      <div>
        <div class="text-body-1">OneML Artifacts</div>
      </div>
    </v-banner>
    <v-text-field
      v-model="artifact_id"
      append-icon="mdi-magnify"
      label="Search by Artifact Id"
      single-line
      hide-details
      @keyup.enter="fetchArtifact()"
      class="mt-2"
    ></v-text-field>
    <v-row class="mt-3 mb-3" v-if="getTrial">
      <v-col class="shrink-col">
        <v-card min-width="380">
          <v-card-title>
            <v-avatar color="success" size="35" class="ml-2">
              <v-icon color="white" size="25"> mdi-file-document </v-icon>
            </v-avatar>
            <div class="ml-2">Artifact Summary</div>
          </v-card-title>

          <v-card-text>
            <v-list dense v-if="getArtifact">
              <v-list-item>
                <v-list-item-subtitle>
                  Artifact Created By:
                  <v-chip small> {{ getArtifact.created_by.username }} </v-chip>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  Size: {{ getArtifact.size | fileSize }}
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-subtitle>
                  Artifact Created At:
                  {{ getArtifact.created_on | localdatetime }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  Artifact :
                  <v-chip
                    target="_blank"
                    label
                    small
                    color="primary"
                    :to="{
                      name: 'trial',
                      params: { id: getTrial.uri.id },
                      query: { tab: 'artifacts', artifact_id: artifact_id },
                    }"
                  >
                    {{ getArtifact.object_name }}
                    <v-icon small right>mdi-open-in-new</v-icon></v-chip
                  >
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="shrink-col">
        <v-card min-width="380">
          <v-card-title>
            <v-avatar color="success" size="35" class="ml-2">
              <v-icon color="white" size="25">
                mdi-text-box-search-outline
              </v-icon>
            </v-avatar>
            <div class="ml-2">Trial Summary</div>
          </v-card-title>
          <v-card-text>
            <v-list dense v-if="getArtifact">
              <v-list-item>
                <v-list-item-subtitle>
                  Trial Created By:
                  <v-chip small> {{ getTrial.created_by.username }} </v-chip>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  Trial Created At:
                  {{ getTrial.created_on | localdatetime }}
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-subtitle>
                  Trial :
                  <v-chip
                    target="_blank"
                    label
                    small
                    color="primary"
                    :to="{ name: 'trial', params: { id: getTrial.uri.id } }"
                  >
                    {{ getTrial.name.toUpperCase() }}
                    <v-icon small right>mdi-open-in-new</v-icon></v-chip
                  >
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ArtifactLookupView",
  computed: mapGetters(["getArtifact", "getTrial"]),
  data() {
    return {
      loading: false,
      artifact_id: "",
      //  artifact: {}
    };
  },

  beforeDestroy() {
    this.$store.dispatch("clearTrial");
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearArtifact");
    next();
  },
  methods: {
    async fetchArtifact() {
      console.log("fetching artifacts");
      await this.$store.dispatch("fetchArtifact", {
        id: this.artifact_id,
        download: false,
        commit: true,
      });
      //fetch trial details
      await this.$store.dispatch("fetchTrial", this.getArtifact.trial_id);
    },
  },
};
</script>

<style></style>
