<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-icon class="grey lighten-1" dark>mdi-numeric-{{ endpointTest.step }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ endpointTest.title }} Test</v-list-item-title>
      <v-list-item-subtitle v-if="endpointTest.status == 'pending'">Testing API {{ endpointTest.title }} endpoint</v-list-item-subtitle>
      <v-list-item-subtitle v-else-if="endpointTest.status == 'in_progress'">Testing in progress</v-list-item-subtitle>
      <v-list-item-subtitle v-else-if="endpointTest.status == 'success'">Endpoint testing successful</v-list-item-subtitle>
      <v-list-item-subtitle v-else-if="endpointTest.status == 'skipped'">
        <span v-if="endpointTest.msg">{{ endpointTest.msg }}</span>
        <span v-else>Endpoint testing was skipped due to unfavourable response</span>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-else-if="endpointTest.status == 'error'">
        <span v-if="endpointTest.msg">{{ endpointTest.msg }}</span>
        <span v-else>Endpoint testing failed</span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <div v-if="endpointTest.status == 'pending'">
        <v-icon color="grey lighten-1">mdi-clock-outline</v-icon>
      </div>
      <div v-else-if="endpointTest.status == 'success'">
        <v-icon color="success">mdi-check-circle</v-icon>
      </div>
      <div v-else-if="endpointTest.status == 'skipped'">
        <v-icon color="orange">mdi-alert-outline</v-icon>
      </div>
      <div v-else-if="endpointTest.status == 'error'">
        <v-icon color="error">mdi-alert-circle</v-icon>
      </div>
      <div v-else-if="endpointTest.status == 'in_progress'">
        <v-progress-circular :size="18" :width="2"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </v-list-item-action>  
  </v-list-item>
</template>

<script>
export default {
  name: "EndpointTest",
  props:{
    endpointTest: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      // endpointTest: {
      //   title: "Health",
      //   step: 1,
      //   status: "pending",
      //   statusCode: null,
      // }
    }
  }
}
</script>

<style>

</style>