<template>
  <v-container fluid>
    <v-banner two-line>
      <v-avatar slot="icon" color="primary" size="40">
        <v-icon color="white"> mdi-creation-outline </v-icon>
      </v-avatar>
      <div>
        <div class="text-body-1">OneML Toolkits</div>
        <div class="text-caption" v-if="getToolkits">
          {{ getToolkits.count }} evaluation kits found
        </div>
      </div>
    </v-banner>
    <v-text-field
      v-model="options.search"
      label="Search"
      single-line
      hide-details
      @keyup.enter="fetchToolkits()"
      class="mt-2"
    ></v-text-field>
    <v-row v-if="getToolkits" justify="start" class="mt-3">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="6"
        xl="4"
        class="shrink-col"
        v-for="(toolkit, index) in getToolkits.results"
        :key="index"
      >
        <v-card>
          <v-card-title>
            <v-avatar color="success" size="40">
              <v-icon color="white" size="30">
                {{ getToolkitIcon(toolkit) }}
              </v-icon>
            </v-avatar>
            <div class="ml-2">
              <router-link
                target="_blank"
                class="text-body-1 secondary-link"
                :to="getToolkitRoute(toolkit)"
                >{{ toolkit.name }}
              </router-link>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="text-caption">{{ toolkit.desc }}</div>
          </v-card-text>
          <v-card-actions>
            <div class="text-caption ml-2">
              Created {{ toolkit.created_on | localdatetime }} &nbsp;|&nbsp;
              {{ toolkit.is_active ? "Active" : "Inactive" }}
            </div>
            <v-spacer></v-spacer>
            <v-btn small text @click="openAnalytics(toolkit)">
              Analytics
              <v-icon small color="error">mdi-google-analytics</v-icon></v-btn
            >
          </v-card-actions>
          <div class="controls-top-right" v-if="isUserLevel2OrUp">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  link
                  :to="{ name: 'edit-toolkit', params: { id: toolkit.uri.id } }"
                >
                  <v-list-item-subtitle>Edit</v-list-item-subtitle>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-subtitle>
                    {{ toolkit.is_active ? "De-activate" : "Activate" }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center mt-3" v-if="options.numberOfPages > 1">
      <v-pagination
        v-model="options.page"
        :length="options.numberOfPages"
        :total-visible="6"
        @next="fetchToolkits()"
        @previous="fetchToolkits()"
      ></v-pagination>
    </div>
    <v-btn
      v-if="isUserLevel2OrUp"
      fab
      dark
      color="primary"
      class="floating-button"
      v-tooltip="'Create New Toolkit'"
      :to="{ name: 'create-toolkit' }"
    >
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ToolkitList",
  components: {},
  computed: mapGetters(["getToolkits", "isUserLevel2OrUp"]),
  data() {
    return {
      loading: false,
      options: {
        search: "",
        page: 1,
        init: true,
        itemsPerPage: 12,
        numberOfPages: 0,
      },
    };
  },
  async created() {
    await this.fetchToolkits();
  },
  watch: {
    options: {
      handler: function (oldVal, newOptions) {
        if (!this.options.init) {
          this.fetchToolkits();
        }
        this.options.init = false;
      },
    },
  },
  methods: {
    async fetchToolkits() {
      console.log("fetching toolkits");
      await this.$store.dispatch("fetchToolkits", this.options);
      console.log(this.getToolkits.count);
      this.options.numberOfPages = Math.ceil(
        this.getToolkits.count / this.options.itemsPerPage
      );
    },
    getToolkitRoute(toolkit) {
      if (toolkit.type == "search")
        return {
          name: "search-toolkit",
          params: { id: toolkit.uri.id },
        };
      return {
        name: "construction",
      };
    },
    getToolkitIcon(toolkit) {
      if (toolkit.type == "search") return "mdi-text-box-search-outline";
      return "mdi-package-variant-closed";
    },
    openAnalytics(toolkit) {
      const routeData = this.$router.resolve({
        name: "search-analytics",
        params: { id: toolkit.uri.id },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style>
.toolkit-card {
  min-width: 400px;
}
</style>
