import TrialDetail from "../views/trials/TrialDetail.vue";
import TrialComparisonView from "../views/trials/TrialComparisonView.vue";
import TrialList from "@/views/trials/TrialList.vue";

export default [
  {
    path: "/trials/:id",
    name: "trial",
    component: TrialDetail,
  },
  {
    path: "/trials-compare",
    name: "compare-trials",
    component: TrialComparisonView,
  },
  {
    path: "/trials/",
    name: "trials",
    component: TrialList,
  },
];