<template>
  <v-container fluid>
    <v-data-table
      v-if="getUsers"
      :headers="headers"
      :items="getUsers.results"
      :server-items-length="getUsers.count"
      :options.sync="options"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <div class="pa-4">Manage Users</div>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
          v-on:keyup.enter="fetchUsers()"
          clearable
        ></v-text-field>
      </template>
      <template v-slot:[`item.username`]="{ item }">
        <router-link :to="{ name: 'user', params: {id: item.uri.id} }" class="secondary-link">
          {{ item.username }}
        </router-link>
        <v-chip v-if="item.is_staff" small label color="secondary" class="ml-2">Staff</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex flex-row">
          <v-btn
            v-if="isUserLevel3OrUp"
            icon
            small
            v-tooltip="'Edit User'"
            class="user-edit-button"
            :to="{ name: 'edit-user', params: { id: item.uri.id } }"
            ><v-icon> mdi-pencil </v-icon></v-btn
          >
          <v-switch
            class="activation-toggle"
            dense
            v-if="isUserLevel3OrUp & !item.is_staff"
            inset
            v-model="item.is_active"
            @change="toggleUserState(item)"
          ></v-switch>
        </div>
      </template>
    </v-data-table>
    <v-btn
      fab
      dark
      color="primary"
      :to="{ name: 'create-user' }"
      class="floating-button"
      v-tooltip="'Create New User'"
      v-if="isUserLevel3AndStaff"
    >
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserListView",
  computed: {
    ...mapGetters(["isUserLevel3AndStaff", "getUsers", "isUserLevel3OrUp"]),
  },
  data() {
    return {
      loading: false,
      options: {
        init: true,
        itemsPerPage: 10,
      },
      search: "",
      headers:[
        { text: "Username", value: "username" },
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Actions", value: "actions", sortable: false },
      ]
    };
  },
  created() {
    this.fetchUsers();
  },
  watch:{
    options: {
      handler: function (oldVal, newOptions){
        if(!this.options.init){
          this.fetchUsers();
        }
        this.options.init = false;
      },
    },
  },
  methods: {
    fetchUsers() {
      console.log("fetchUsers");
      this.loading = true;
      let optionsCopy = structuredClone(this.options);
      optionsCopy.search = this.search;
      this.$store.dispatch("fetchUsers", optionsCopy)
        .finally(() => (this.loading = false));
    },
    toggleUserState(user){
      this.$store.dispatch("toggleUserState", user)
      .then(() => {
        this.$store.commit("showNotification", "Changes successfully saved");
      })
      .catch((error) => {
        this.$store.commit("showNotification", "Something went wrong");
      });
    }
  },
}
</script>

<style scoped>

.v-input {
  margin-top: 6px !important;
  margin-bottom: -10px !important;
}

.user-edit-button{
  margin-top: 10px;
  margin-right: 10px;
  padding-bottom: 10px;
}

</style>