<template>
  <v-dialog v-model="showDialog" max-width="700" persistent>
    <v-card>
      <v-card-title>
        <div class="text-body-1">Reset Feedback Data</div>
      </v-card-title>
      <v-card-text>
        <div class="text-body-2">
          Are you sure you want to reset the feedback data? This will delete all the user feedback & analytics data.
          It is recommended to create a snapshot of analytics metrics before resetting the data.
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" :loading="loading" @click="reset()">Reset</v-btn>
        <v-btn text @click="closeDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ResetAnalytics",
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  watch:{
    id: function(newId){
      this.showDialog = newId != null;
    }
  },
  data() {
    return {
      showDialog: false,
      loading: false,
    }
  },
  methods: {
    closeDialog(saved){
      this.$emit("closed", saved);
    },
    reset() {
      this.loading = true;
      this.$store
        .dispatch("resetToolkit", this.id)
        .then(() => {
          this.closeDialog();
          this.$store.commit("showNotification", "Feedback Analytics data reset successfully");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
            this.closeDialog();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
}
</script>

<style>

</style>