<template>
  <div class="mb-2" v-if="filter">
    <div v-if="filter.render_type === 'checkbox'">
      <div class="">
        Choose {{ filter.title }}
      </div>
      <v-chip-group
        v-model="filterSelection"
        column
        :multiple="filter.multi_select"
      >
        <v-chip filter outlined
          v-for="(choice, index) in filter.choices"
          :key="index"
          :value="choice.value"
        >
          {{ choice.name | charlimit30}}
        </v-chip>
      </v-chip-group>
    </div>
    <div v-else class="mt-3">
      <v-combobox
        clearable
        small-chips
        :multiple="filter.multi_select"
        :items="filter.choices"
        item-text="name"
        item-value="value"
        :label="`Choose ${filter.title}`"
        outlined
        v-model="filterSelection"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="filterSelection.length < 10"> {{ item.name }} </v-chip>
          <div v-else>
            <v-chip v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ filterSelection.length - 1 }} others)
            </span>
          </div>
        </template>
      </v-combobox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolkitFilter',
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    filterSelection: [null],
    init: true,
  }),
  watch: {
    filterSelection: {
      handler: function (val, oldVal) {
        if(this.init){
          this.init = false;
          return;
        }
        if(this.filter.multi_select){
          // multi-select related processing
          if(this.filter.render_type === 'dropdown'){
            val = val.map(v => v.value);
          }
        }
        else{
          // single-select related processing
          if(this.filter.render_type === 'dropdown'){
            // handle v-combobox
            val = val.value;
          }
        }
        this.$emit('filter-changed', {
          name: this.filter.name,
          value: val
        });
      },
      deep: true,
    },
    filter:{
      handler: function(val, oldVal){
        this.updateFilter();
      },
      deep: true,
    }
  },
  created() {
    this.updateFilter();
  },
  methods:{
    updateFilter(){
      if(this.filter.render_type === 'dropdown' && this.filter.pre_selected_value != null){
        if(this.filter.multi_select){
          // get choices with respect to pre_selected_value
          this.filterSelection = this.filter.choices.filter(choice => this.filter.pre_selected_value.includes(choice.value));
        }
        else{
          const preselected_choice = this.filter.choices.filter(choice => this.filter.pre_selected_value == choice.value);
          if (preselected_choice.length > 0)
            this.filterSelection = preselected_choice[0];
          else
            this.filterSelection = null;
        }
      }
      else{
        this.filterSelection = this.filter.pre_selected_value;
      }
    },
    filterWiseLog(msg){
      console.log(`${this.filter.name}: ${msg}`);
    }
  }
}
</script>

<style>

</style>