<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="showDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card v-if="document">
          <v-toolbar color="primary" dark>
            <v-btn icon @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ document.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <!-- <v-btn dark text @click="showDialog = false"> Save </v-btn> -->
            </v-toolbar-items>
          </v-toolbar>
          <div>
            <v-card-text>
              <!-- <div class="text-container">
              </div> -->
              <v-row>
                <v-col class="text-container">
                  <div v-if="document.body_html != null" v-html="document.body_html"></div>
                  <div v-else>{{ document.body_text }}</div>
                  <br/><div>Date: {{ document.date }}</div>
                </v-col>
                <v-col v-if="showMetadata" cols="2">
                  <!-- show attributes using v-list -->
                  <v-list dense>
                    <v-list-item-group>
                      <v-list-item v-for="(attribute, index) in attributes" :key="index">
                        <v-list-item-content>
                          <v-list-item-title>{{ attribute.key | status}}</v-list-item-title>
                          <v-list-item-subtitle>{{ attribute.value }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "DocumentView",
  props: {
    document: {
      type: Object,
      required: false,
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
      this.$emit("closed");
    },
  },
  watch: {
    document: function (val) {
      this.showDialog = val != null;
      this.attributes = [];
      // check if val.metadata exists and is not empty
      if (val.metadata && Object.keys(val.metadata).length > 0) {
        this.showMetadata = true;
        // populate attributes
        for (const [key, value] of Object.entries(val.metadata)) {
          this.attributes.push({ key: key, value: value });
        }
      } else {
        this.showMetadata = false;
      }
    },
  },
  data(){
    return {
      showDialog: false,
      showMetadata: false,
      attributes: []
    }
  },
};
</script>

<style>
.text-container {
  padding: 10px 15%;
  white-space: pre-wrap;
}
</style>
