<template>
  <v-container fluid v-if="getModelCard">
    <v-banner one-line>
      <v-avatar slot="icon" color="primary" size="40">
        <v-icon color="white"> mdi-package-variant </v-icon>
      </v-avatar>
      <div>
        <div class="text-body-1">{{ getModelCard.title }}</div>
        <div class="text-caption">
          Model Card URI: {{ getModelCard.uri.id }}
          <v-btn icon small @click="copyArtifactIdentifier()">
            <v-icon size="18">mdi-content-copy</v-icon>
          </v-btn>
        </div>
      </div>
      <template v-slot:actions>
        <v-chip class="ma-2" color="primary accent-4" text-color="white">
          <v-btn icon small dark><v-icon small>mdi-thumb-up</v-icon></v-btn> | {{ getModelCard.upvotes }} Upvotes
        </v-chip>
        <v-chip class="ma-2" color="green" text-color="white">
          {{ getModelCard.n_downloads }} Downloads
        </v-chip>
      </template>
    </v-banner>
    <v-row class="mt-3">
      <v-col cols="9">
        <v-card>
          <v-card-title>Summary</v-card-title>
          <v-card-text>
            {{ getModelCard.summary }}
          </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-title>Description</v-card-title>
          <div v-html="markdownToHtml" class="one-md markdown-renderer"></div>
        </v-card>
        <v-card :loading="this.loadingFlags.trial" min-width="380" class="mt-3">
          <v-card-title>Metrics Summary </v-card-title>
          <v-card-text>
            <span class="text-caption">Fetched from OneML-Tracking</span>
            <v-simple-table dense class="params-table" v-if="metrics">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in Object.entries(metrics)" :key="item[0]">
                    <td>{{ item[0] }}</td>
                    <td>{{ item[1] }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-banner>Tags</v-banner>
        <div class="ma-3 tag-container">
          <v-chip class="tag" outlined>OneML Artifact</v-chip>
          <v-chip class="tag" outlined>OneML-Deploy Compatible</v-chip>
          <!-- <v-chip class="tag" outlined>NLP</v-chip> -->
        </div>
        <!-- <v-banner>Metadata</v-banner> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import copy from "copy-to-clipboard";
import { reduceObject } from "@/store/common/utils";

export default {
  name: "ModelCardView",
  components: { reduceObject },
  computed: {
    ...mapGetters(["getModelCard", "getTrial"]),
  },
  data() {
    return {
      md: null,
      markdownToHtml: null,
      loadingFlags: {
        trial: true,
      },
      metrics: null,
      params: null,
    };
  },
  created() {
    this.md = require("markdown-it")({
      html: true,
      linkify: true,
      typographer: true,
      langPrefix: "oneml-",
    });
    this.$store
      .dispatch("fetchModelCard", this.$route.params.id)
      .then(() => {
        this.markdownToHtml = this.md.render(this.getModelCard.body);
      })
      .then(() => {
        this.$store
          .dispatch("fetchTrial", this.getModelCard.trial_uri.id)
          .then(() => {
            this.metrics = reduceObject(this.getTrial.metrics, null, 5);
            this.params = reduceObject(this.getTrial.params, null, 5);
          })
          .finally(() => (this.loadingFlags.trial = false));
      });
  },
  methods: {
    copyModelIdentifier() {
      copy(this.getArtifact.uri.identifier);
      this.$store.commit(
        "showNotification",
        "Servable Artifact identifier copied to clipboard"
      );
    },
  },
};
</script>

<style>
.markdown-renderer {
  padding: 2px;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  margin: 5px;
}
</style>