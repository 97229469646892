<template>
  <v-container fluid>
    <v-banner icon="mdi-creation-outline">
      Toolkit Update Wizard
    </v-banner>
    <v-stepper v-model="editStep">
      <v-stepper-header>
        <v-stepper-step
          editable
          :complete="editStep > 1"
          step="1"
        >
          Define Toolkit
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :editable="flagBaseToolkitReady"
          :complete="editStep > 2"
          step="2"
        >
          API Configuration
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :editable="flagBaseToolkitReady"
          :complete="editStep > 3"
          step="3"
        >
          UI Configuration
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4">
          Intergration Tests
        </v-stepper-step>
      </v-stepper-header>
        <v-stepper-items>
        <v-stepper-content step="1">
          <CreateOrEditToolkit 
            v-bind:id="toolkitId"
            v-bind:showRestorationAlert="false"
            @step-completed="stepCompleted"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <CreateOrEditSearchConfig 
            v-bind:id="apiToolkitId"
            v-bind:mode="'api'"
            @step-completed="stepCompleted"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <CreateOrEditSearchConfig 
            v-bind:id="uiToolkitId"
            v-bind:mode="'ui'"
            @step-completed="stepCompleted"
          />
        </v-stepper-content>
        <v-stepper-content step="4">
          <IntegrationTests v-bind:id="intTestsToolkitId"
            @step-completed="stepCompleted"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>


import CreateOrEditToolkit from "../../components/toolkits/CreateOrEditToolkit.vue"
import CreateOrEditSearchConfig from "../../components/toolkits/CreateOrEditSearchConfig.vue"
import IntegrationTests from "../../components/toolkits/IntegrationTests.vue"

export default {
  name: 'EditToolkit',
  components: {
    CreateOrEditToolkit,
    CreateOrEditSearchConfig,
    IntegrationTests
  },
  data(){
    return {
      editStep: 1,
      toolkitId: null,
      apiToolkitId: null,
      intTestsToolkitId: null,
      uiToolkitId: null,
      flagBaseToolkitReady: false,
    }
  },
  watch:{
    editStep: function(newStep){
      // console.log(`editStep changed to ${this.editStep}`);
      this.updateStep();
    }
  },
  created() {
    this.toolkitId = this.$route.params.id;
  },
  methods: {
    stepCompleted(increment){
      if(increment) this.editStep = parseInt(this.editStep) + 1;
    },
    updateStep(){
      if(this.editStep > 1) this.flagBaseToolkitReady = true;
      if(this.editStep == 2){
        this.apiToolkitId = this.toolkitId;
        this.uiToolkitId = this.intTestsToolkitId = null;
      }
      else if(this.editStep == 3){
        this.uiToolkitId = this.toolkitId;
        this.apiToolkitId = this.intTestsToolkitId = null;
      }
      else if(this.editStep == 4){
        this.intTestsToolkitId = this.toolkitId;
        this.apiToolkitId = this.uiToolkitId = null;
      }
    }
  }
}
</script>

<style>

</style>