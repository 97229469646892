<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="showDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar color="primary" dark>
            <v-btn icon @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <!-- <v-btn dark text @click="showDialog = false"> Save </v-btn> -->
            </v-toolbar-items>
          </v-toolbar>
          <div>
            <v-card-text>
              <div class="text-container">
                <div class="text-h6">System Generated Text</div>
                <div class="ma-2 mb-5 generative-result">
                  <div v-if="systemHtml" v-html="systemHtml" class="generative-html"></div>
                  <div v-else class="generative-text">{{ systemText }}</div>
                </div>
                <v-divider></v-divider>
                <div class="text-h6 mt-2">User Generated Text</div>
                <v-textarea
                  class="ma-2 feedback-textarea"
                  v-model="feedback.userText"
                  hint="System generated text has been used as a starting point for your text."
                  persistent-hint
                  rows="10"
                >
                </v-textarea>
                <v-slider
                  v-model="feedback.value"
                  :tick-labels="ticksLabels"
                  :max="4"
                  step="1"
                  ticks="always"
                  tick-size="4"
                  class="feedback-slider"
                  :thumb-size="30"
                  thumb-label="always"
                  hint="Rating for System Generated Text"
                >
                  <template v-slot:thumb-label="{ value }">
                    <span class="text-h6">{{ satisfactionEmojis[value] }}</span>
                  </template>
                </v-slider>
                <v-btn color="primary" class="mt-2" @click="submit()" :loading="loading">Submit</v-btn>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "ExemplifiedFeedback",
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    systemText: {
      type: String,
      required: false,
    },
    systemHtml: {
      type: String,
      required: false,
    },
  },
  watch: {
    showDialog: function (val) {
      this.loading = false;
      if (val) {
        const originalText = this.systemHtml ? this.systemHtml : this.systemText;
        // replace any html tag with a space using regex
        this.feedback.userText = originalText.replace(/<[^>]*>?/gm, ' ');
        // replace consecutive spaces with a single space
        this.feedback.userText = this.feedback.userText.replace(/\s\s+/g, ' ');
      }
    },
  },
  data(){
    return {
      feedback:{
        value: 3,
        systemText: null,
        userText: "",
      },
      loading: false,
      ticksLabels: [
        "Very Bad",
        "Bad",
        "Needs Improvement",
        "Good",
        "Very Good",
      ],
      satisfactionEmojis: ['😭', '😢', '😐', '😊', '😍'],
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closed");
    },
    submit(){
      this.loading = true;
      this.feedback.systemText = this.systemHtml ? this.systemHtml : this.systemText;
      this.$emit("submit-feedback", this.feedback);
    }
  },
};
</script>

<style>
.text-container {
  padding: 10px 10%;
  white-space: pre-wrap;
}

.feedback-slider{
  max-width: 650px;
  margin-top: 35px;
}

.feedback-textarea{
  font-size: 15px;
}
</style>
