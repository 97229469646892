<template>
  <v-container fluid>
    <div class="d-flex flex-column">
      <v-card class="profile-container" v-if="userObj">
        <v-card-title class="text-body-1"
          >Account Details <v-spacer></v-spacer>
          <!-- <v-btn icon small @click="( modelProps.editProfile = true )">
            <v-icon>mdi-pencil</v-icon>
          </v-btn> -->
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="( modelProps.editProfile = true )">
                <v-list-item-title>Edit Profile</v-list-item-title>
              </v-list-item>
              <v-list-item @click="modelProps.changePassword = true">
                <v-list-item-title>Change Password</v-list-item-title>
              </v-list-item>
              <v-list-item @click="modelProps.logoutfromAllDevices = true">
                <v-list-item-title>Logout From All Devices</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-text class="d-flex flex-row">
          <div>
            <v-img
              src="@/assets/homer.png"
              class="profile-img"
              width="65"
              :aspect-ratio="1 / 1"
            ></v-img>
          </div>
          <div>
            <div>Username: {{ userObj.username }}</div>
            <div>Name: {{ fullName }}</div>
            <div class="">Role: {{ userRole }}</div>
            <div class="">Email: {{ userObj.email }}</div>
          </div>
        </v-card-text>
      </v-card>
      <div v-if="getUserTokens">
        <v-data-table
          class="elevation-1 mt-5 token-table"
          :headers="tokenHeaders"
          :items="getUserTokens.results"
          :items-per-page="10"
          dense
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                <div class="text-body-1">Your SDK Tokens</div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                small
                color="primary"
                dark
                class="mb-2"
                @click="modelProps.createToken = true"
              >
                New Token
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            {{ item.created | localdatetime }}
          </template>
          <template v-slot:[`item.expiry`]="{ item }">
            {{ item.expiry | localdatetime }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              icon
              small
              :loading="modelProps.deleteTokenName == item.name"
              @click="
                {
                  modelProps.confirmTokenDeletion = true;
                  modelProps.deleteTokenName = item.name;
                }
              "
              ><v-icon> mdi-delete </v-icon></v-btn
            >
          </template>
        </v-data-table>
      </div>
    </div>
    <NewToken
      v-bind:showDialog="modelProps.createToken"
      @closed="tokenCreated()"
    />
    <EditUser
      v-bind:showDialog="modelProps.editProfile"
      @closed="( modelProps.editProfile = false)"
    />
    <ChangePassword 
      v-bind:showDialog="modelProps.changePassword"
      @closed="( modelProps.changePassword = false)"
    />
    <LogoutFromAllDevices 
      v-bind:showDialog="modelProps.logoutfromAllDevices"
      @closed="( modelProps.logoutfromAllDevices = false)"
    />
    <v-dialog v-model="modelProps.confirmTokenDeletion" max-width="350">
      <v-card>
        <v-card-title>Alert</v-card-title>
        <v-card-text>
          Are you sure you want to delete this token ? <br />
          This might affect ongoing trainings with edith clients where this
          token is being used
        </v-card-text>
        <v-card-actions class="d-flex flex-row justify-end">
          <v-btn small dark color="red" @click="deleteToken()">Delete</v-btn>
          <v-btn
            small
            depressed
            @click="
              {
                modelProps.confirmTokenDeletion = false;
                modelProps.deleteTokenName = null;
              }
            "
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import NewToken from "../../components/users/NewToken.vue";
import EditUser from "../../components/users/EditUser.vue";
import ChangePassword from "../../components/users/ChangePassword.vue";
import LogoutFromAllDevices from "../../components/users/LogoutFromAllDevices.vue";

export default {
  name: "ProfileView",
  components: { NewToken, EditUser, ChangePassword, LogoutFromAllDevices },
  data() {
    return {
      flags: {
        loadingTokens: false,
      },
      modelProps: {
        createToken: false,
        deleteTokenName: null,
        confirmTokenDeletion: false,
        editProfile: false,
        changePassword: false,
        logoutfromAllDevices: false,
      },
      tenantOptions: {},
      temp: -1,
      tokenHeaders: [
        { text: "Name", value: "name" },
        { text: "Created On", value: "created" },
        { text: "Expires On", value: "expiry" },
        { text: "Action", value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchTokens();
  },
  computed: {
    ...mapGetters(["userObj", "getUserGroups", "getUserTokens"]),
    fullName: function () {
      var fullName = "";
      if (this.userObj) {
        fullName = this.userObj.first_name + " " + this.userObj.last_name;
      }
      if (fullName.trim().length == 0) fullName = "Unknown";
      return fullName;
    },
    userRole: function () {
      if (this.getUserGroups.length >= 1) return this.getUserGroups.join(" | ");
      return "No Role Assigned";
    },
  },
  methods: {
    fetchTokens() {
      this.$store
        .dispatch("fetchUserTokens", this.tenantOptions)
        .finally(() => {
          this.flags.loadingTokens = false;
        });
    },
    tokenCreated() {
      this.modelProps.createToken = false;
      this.fetchTokens();
    },
    deleteToken() {
      this.modelProps.confirmTokenDeletion = false;
      this.$store
        .dispatch("deleteToken", {
          name: this.modelProps.deleteTokenName,
        })
        .then(() => {
          this.$store.commit("showNotification", "Token has been deleted");
          this.fetchTokens();
        })
        .finally(() => {
          this.modelProps.deleteTokenName = null;
        });
    },
  },
};
</script>

<style>
.profile-page {
  margin: 2% 2%;
}

.profile-container {
  max-width: 500px;
}

.profile-img {
  margin: 10px 20px;
  border-radius: 50%;
}

.token-table {
  max-width: 60%;
}
</style>
