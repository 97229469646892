import { AuthClient } from "@/store/common/axio-api";
import * as utils from "@/store/common/utils";

const state = {
  authToken: localStorage.getItem("authToken") || null,
  authUser: utils.retriveLocalObject("authUser"),
  userGroups: utils.retriveLocalObject("userGroups"),
  authUserTenants: null,
  selectedTenant: utils.retriveLocalObject("tenant"),
  userTokens: [],
};

const getters = {
  getAuthHeaders(state) {
    return {
      headers: {
        Authorization: "token " + state.authToken,
      },
    };
  },
  isAuthenticated: (state) =>
    !(state.authToken == null || state.authToken == ""),
  userObj: (state) => state.authUser,
  getUserGroups: (state) => state.userGroups,
  isUserLevel3AndStaff: (state) => {
    if (state.userGroups == null || state.userGroups.length == 0 || state.authUser == null) return false;
    if ((
      state.userGroups.indexOf("Data Scientist-3") >= 0 ||
      state.userGroups.indexOf("ML Engineer-1") >= 0
    ) && state.authUser.is_staff)
      return true;
    return false;
  },
  isUserLevel3OrUp: (state) => {
    if (state.userGroups == null || state.userGroups.length == 0) return false;
    if (
      state.userGroups.indexOf("Data Scientist-3") >= 0 ||
      state.userGroups.indexOf("ML Engineer-1") >= 0
    )
      return true;
    return false;
  },
  isUserLevel2OrUp: (state) => {
    if (state.userGroups == null || state.userGroups.length == 0) return false;
    if (
      state.userGroups.indexOf("Data Scientist-3") >= 0 ||
      state.userGroups.indexOf("Data Scientist-2") >= 0 ||
      state.userGroups.indexOf("ML Engineer-1") >= 0
    )
      return true;
    return false;
  },
  isStaff: (state) => {
    if (state.authUser == null) return false;
    return state.authUser.is_staff
  },
  isSpectator: (state) => {
    if (state.userGroups == null || state.userGroups.length == 0) return false;
    if (
      state.userGroups.indexOf("Spectator") >= 0
    )
      return true;
    return false;
  },
  isSME: (state) => {
    if (state.userGroups == null || state.userGroups.length == 0) return false;
    if (
      state.userGroups.indexOf("SME") >= 0
    )
      return true;
    return false;
  },
  isSMEOrSpecator: (state) => {
    if (state.userGroups == null || state.userGroups.length == 0) return false;
    if (
      state.userGroups.indexOf("SME") >= 0 ||
      state.userGroups.indexOf("Spectator") >= 0
    )
      return true;
    return false;
  },
  canGiveFeedbackInToolkits: (state) => {
    if (state.userGroups == null || state.userGroups.length == 0) return false;
    if (
      state.userGroups.indexOf("Data Scientist-3") >= 0 ||
      state.userGroups.indexOf("Data Scientist-2") >= 0 ||
      state.userGroups.indexOf("Data Scientist-1") >= 0 ||
      state.userGroups.indexOf("ML Engineer-1") >= 0 ||
      state.userGroups.indexOf("SME") >= 0
    )
      return true;
    return false;
  },
  getAuthUserTenants: (state) => state.authUserTenants,
  getSelectedTenant: (state) => state.selectedTenant,
  getUserTokens: (state) => state.userTokens,
};

const mutations = {
  SET_TOKEN(state, authToken) {
    state.authToken = authToken;
    if (authToken) localStorage.setItem("authToken", authToken);
    else localStorage.removeItem("authToken");
  },
  SET_AUTH_USER(state, authUser) {
    state.authUser = authUser;
    if (authUser == null) localStorage.removeItem("authUser");
    else localStorage.setItem("authUser", JSON.stringify(authUser));
  },
  SET_AUTH_USER_GROUPS(state, userGroups) {
    state.userGroups = userGroups;
    if (userGroups == null) localStorage.removeItem("userGroups");
    else localStorage.setItem("userGroups", JSON.stringify(userGroups));
  },
  SET_SELECTED_TENANT(state, tenant) {
    state.selectedTenant = tenant;
    if (tenant == null) localStorage.removeItem("tenant");
    else localStorage.setItem("tenant", JSON.stringify(tenant));
  },
  SET_AUTH_USER_TENANTS: (state, tenants) => (state.authUserTenants = tenants),
  SET_USER_TOKENS: (state, tokens) => (state.userTokens = tokens),
};

const actions = {
  logout({ commit }) {
    commit("SET_TOKEN", null);
    commit("SET_AUTH_USER", null);
    commit("SET_SELECTED_TENANT", null);
  },
  logoutFromAllDevices({ rootGetters }) {
    return new Promise((resolve, reject) => {
      AuthClient.post(`tokens/clear-primary-tokens/`, {}, rootGetters.getAuthHeaders)
        .then(function (reponse) {
          resolve(reponse.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  login({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      AuthClient.post("user/token-auth/", credentials)
        .then(function (reponse) {
          commit("SET_TOKEN", reponse.data.token);
          commit("SET_AUTH_USER", reponse.data.user);
          commit("SET_AUTH_USER_GROUPS", reponse.data.user_groups);
          resolve(reponse.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  editSelf({ commit, rootGetters }, user) {
    return new Promise((resolve, reject) => {
      AuthClient.put(`users/self/`, user, rootGetters.getAuthHeaders)
        .then(function (reponse) {
          let userObjCopy = structuredClone(rootGetters.userObj);
          userObjCopy.first_name = user.first_name;
          userObjCopy.last_name = user.last_name;
          commit("SET_AUTH_USER", userObjCopy);
          resolve(reponse.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  changePassword({ commit, rootGetters }, user) {
    return new Promise((resolve, reject) => {
      AuthClient.post(`user/reset-password/`, user, rootGetters.getAuthHeaders)
        .then(function (reponse) {
          resolve(reponse.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchAuthUserTenants({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      var baseUrl = utils.prepareURL(`user/my-tenants?`, options);
      AuthClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_AUTH_USER_TENANTS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchUserTokens({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      var baseUrl = utils.prepareURL(`tokens`, options);
      AuthClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_USER_TOKENS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  createNewToken({ rootGetters }, tokenOptions) {
    return new Promise((resolve, reject) => {
      AuthClient.post(`tokens/`, tokenOptions, rootGetters.getAuthHeaders)
        .then(function (reponse) {
          resolve(reponse.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  deleteToken({ rootGetters }, tokenOptions) {
    return new Promise((resolve, reject) => {
      AuthClient.post(`tokens/delete/`, tokenOptions, rootGetters.getAuthHeaders)
        .then(function (reponse) {
          resolve(reponse.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
