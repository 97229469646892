<template>
  <div>
    <v-expansion-panels flat v-model="panels.custom" multiple>
      <v-expansion-panel class="expansion-panel">
        <v-expansion-panel-header> Custom Charts </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-if="getProjectCharts" justify="start">
            <v-col
              class="shrink-col"
              v-for="(chart, index) in getProjectCharts.results"
              :key="'key-1-chart-' + index"
            >
              <LineChart
                :config="chart"
                :flagVisibility="flagVisibility"
                :chartId="'custom-chart-' + index"
                class="grid-chart"
                @deleted="modalClosed('chartDeleted')"
                @edit="triggerChartEdit"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels flat v-model="panels.system" multiple class="mb-3">
      <v-expansion-panel class="expansion-panel">
        <v-expansion-panel-header> Default Charts </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-if="systemCharts" justify="start">
            <v-col
              class="shrink-col"
              v-for="(chart, index) in systemCharts"
              :key="'key-2-chart-' + index"
            >
              <LineChart
                v-if="!chart.custom"
                :config="chart"
                :flagVisibility="flagVisibility"
                :chartId="'sys-chart-' + index"
                class="grid-chart"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <NewChart
      v-bind:flagVisibility="modalFlags.create"
      @closed="modalClosed('chartCreated')"
    />
    <EditChart
      v-bind:id="modalFlags.editId"
      @closed="modalClosed('chartEdited')"
    />
    <v-btn
      elevation="2"
      color="primary"
      fab
      class="floating-btn"
      v-tooltip="'Add Custom Chart'"
      @click="modalFlags.create = true"
    >
      <v-icon>mdi-chart-box-plus-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LineChart from "../../components/trials/LineChart.vue";
import NewChart from "../../components/trials/NewChart.vue";
import EditChart from "../../components/trials/EditChart.vue";

export default {
  name: "TrialCharts",
  computed: {
    ...mapGetters([
      "getTrialSteps",
      "getTrialStepDatasets",
      "getProjectCharts",
    ]),
  },
  components: { LineChart, NewChart, EditChart },
  data() {
    return {
      modalFlags: {
        create: false,
        editId: -1,
      },
      flagVisibility: false,
      panels: {
        custom: [0],
        system: [0],
      },
      systemCharts: [],
    };
  },
  created() {
    this.fetchSteps();
    this.fetchUserCharts();
  },
  methods: {
    fetchSteps() {
      // console.log("fetching steps");
      this.$store
        .dispatch("fetchTrialSteps", {
          trial_id: this.$route.params.id,
          itemsPerPage: 1000,
        })
        .then(() => {
          Object.keys(this.getTrialStepDatasets).forEach((metric) => {
            if (metric != "steps") {
              this.systemCharts.push({
                y_series: [{ name: metric, min: null, max: null }],
                x_series: { name: "steps", min: null, max: null },
                log_scale: false,
                smoothing: 0,
                title: metric,
                custom: false,
              });
            }
          });
          this.flagVisibility = true;
        });
    },
    fetchUserCharts() {
      // console.log("fetching charts");
      this.$store.dispatch("fetchUserCharts", {
        itemsPerPage: 25,
      });
    },
    triggerChartEdit(id) {
      this.modalFlags.editId = id;
    },
    modalClosed(eventType) {
      // console.log(eventType);
      if (eventType == "chartCreated") {
        this.modalFlags.create = false;
        this.fetchUserCharts();
      } else if (eventType == "chartDeleted") {
        this.fetchUserCharts();
      } else if (eventType == "chartEdited") {
        this.modalFlags.editId = -1;
        this.fetchUserCharts();
        console.log("chartedited");
      }
    },
  },
};
</script>

<style>
.grid-chart {
  max-height: fit-content;
  max-width: fit-content;
}

.expansion-panel {
  background-color: transparent !important;
}
</style>
