import { TrackingClient } from "@/store/common/axio-api";
import * as utils from "@/store/common/utils";

const state = {
  projects: null,
  project: null,
  selectedProject: utils.retriveLocalObject("selectedProject"),
  projectCharts: null,
  projectChart: null,
  dashboard: null,
};

const getters = {
  getProjects: (state) => state.projects,
  getProject: (state) => state.project,
  getSelectedProject: (state) => state.selectedProject,
  getProjectCharts: (state) => state.projectCharts,
  getProjectChart: (state) => state.projectChart,
  getDashboard: (state) => state.dashboard,
};

const mutations = {
  SET_PROJECTS: (state, projects) => (state.projects = projects),
  SET_PROJECT: (state, project) => (state.project = project),
  SET_SELECTED_PROJECT(state, selectedProject) {
    state.selectedProject = selectedProject;
    if (selectedProject == null) localStorage.removeItem("selectedProject");
    else
      localStorage.setItem("selectedProject", JSON.stringify(selectedProject));
  },
  SET_PROJECT_CHARTS(state, charts) {
    charts.results.forEach((e) => (e.custom = true));
    state.projectCharts = charts;
  },
  SET_PROJECT_CHART(state, chart) {
    chart.custom = true;
    state.projectChart = chart;
  },
  SET_DASHBOARD: (state, dashboard) => (state.dashboard = dashboard),
};

const actions = {
  resetSelectedProject({ commit }) {
    commit("SET_SELECTED_PROJECT", null);
  },
  fetchProjectsbyTenant({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      var baseUrl = utils.prepareURL(
        `tenants/${options.id}/projects?`,
        options
      );
      TrackingClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_PROJECTS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchProject({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      TrackingClient.get(`projects/${id}/`, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_PROJECT", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  createNewProject({ rootGetters }, project) {
    return new Promise((resolve, reject) => {
      TrackingClient.post(
        `tenants/${project.tenantId}/projects/`,
        project,
        rootGetters.getAuthHeaders
      )
        .then(function (reponse) {
          resolve(reponse.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  editProject({ rootGetters }, project) {
    return new Promise((resolve, reject) => {
      TrackingClient.put(
        `projects/${project.id}/`,
        project.data,
        rootGetters.getAuthHeaders
      )
        .then(function (reponse) {
          resolve(reponse);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  createNewChart({ rootGetters }, chartOptions) {
    return new Promise((resolve, reject) => {
      TrackingClient.post(
        `projects/${rootGetters.getSelectedProject.uri.id}/charts/`,
        chartOptions,
        rootGetters.getAuthHeaders
      )
        .then(function (reponse) {
          resolve(reponse.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchUserCharts({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      var baseUrl = utils.prepareURL(
        `projects/${rootGetters.getSelectedProject.uri.id}/charts/?`,
        options
      );
      TrackingClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_PROJECT_CHARTS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  deleteChart({ rootGetters }, id) {
    return new Promise((resolve, reject) => {
      TrackingClient.delete(`charts/${id}`, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  editChart({ rootGetters }, chart) {
    return new Promise((resolve, reject) => {
      TrackingClient.put(
        `charts/${chart.id}/`,
        chart.data,
        rootGetters.getAuthHeaders
      )
        .then(function (reponse) {
          resolve(reponse);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  getProjectChart({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      TrackingClient.get(`charts/${id}/`, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
          commit("SET_PROJECT_CHART", response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  getProjectDashboard({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      TrackingClient.get(`projects/${id}/dashboard`, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
          commit("SET_DASHBOARD", response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
