import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { ValidationProvider, extend } from "vee-validate";
import { required, max } from "vee-validate/dist/rules";
import {
  localdatetime_fn,
  charLimit_100,
  charLimit_30,
  charLimit_20,
  status,
  fileSize,
} from "./filters";
//#region CSS imports
import VTooltip from "v-tooltip";
import "./styles/main.css";
import "./styles/experiments.css";
import "./styles/tooltip.css";
import "./styles/tenant-selection.css";
import "./styles/trial.css";
import "./styles/artifacts.css";
import "./styles/markdown.less";
import "./styles/toolkit.css";
//#endregion

Vue.config.productionTip = false;
Vue.use(VTooltip);
Vue.filter("localdatetime", localdatetime_fn);
Vue.filter("charlimit100", charLimit_100);
Vue.filter("charlimit30", charLimit_30);
Vue.filter("charlimit20", charLimit_20);
Vue.filter("status", status);
Vue.filter("fileSize", fileSize);

extend("required", {
  ...required,
  message: "This field is required",
});

extend("max", {
  ...max,
  message: "Max allowed character count exceeded",
});

new Vue({
  components: {
    ValidationProvider,
  },
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
