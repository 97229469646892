<template>
  <v-container fluid>
    <v-card max-width="600">
      <v-btn
        icon
        class="float-right ma-1"
        v-tooltip="'Back to list of users'"
        :to="{ name: 'users' }"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        <div class="text-body-1">Add New User</div>
      </v-card-title>
      <v-card-text>
        <validation-observer ref="observer">
          <form @submit.prevent="submit">
            <validation-provider
              v-slot="{ errors }"
              name="username"
              rules="required"
            >
              <v-text-field
                name="username"
                label="Username*"
                v-model="user.username"
                :error-messages="errors"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="email"
              rules="required"
            >
              <v-text-field
                name="email"
                label="Email*"
                v-model="user.email"
                :error-messages="errors"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="first_name"
              rules="required"
            >
              <v-text-field
                name="first_name"
                label="First Name*"
                v-model="user.first_name"
                :error-messages="errors"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="last_name"
              rules="required"
            >
              <v-text-field
                name="last_name"
                label="Last Name*"
                v-model="user.last_name"
                :error-messages="errors"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="initial_role"
              rules="required"
            >
              <v-select
                name="initial_role"
                :items="availableRoles"
                v-model="user.initial_role"
                :error-messages="errors"
                label="Initial Role*"
              ></v-select>
            </validation-provider>
            <div v-if="isStaff">
              <validation-provider
                v-slot="{ errors }"
                name="is_staff"
                rules="required"
              >
                <v-checkbox
                  v-model="user.is_staff"
                  :label="'Is Staff'"
                  :error-messages="errors"
                ></v-checkbox>
              </validation-provider>
            </div>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
            >
              Submit
            </v-btn>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { oneMLRoles } from "@/store/common/metadata.js";
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "NewUserView",
  components: {
    ValidationProvider,
    ValidationObserver,
    oneMLRoles
  },
  computed: {
    ...mapGetters(["isStaff"]),
  },
  data() {
    return {
      loading: false,
      user: {
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        is_staff: false,
        initial_role: "Spectator"
      },
      availableRoles: oneMLRoles
    };
  },
  created(){
  },
  methods: {
    submit() {
      this.loading = true;
      this.$store
        .dispatch("createUser", this.user)
        .then((data) => {
          this.$store.commit(
            "showNotification",
            "New User Successfully Created"
          );
          this.$router.push({
            name: "user",
            params: { id: data.uri.id },
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
}
</script>

<style>

</style>