<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <div class="d-flex flex-row pane-input-container bottom-border">
          <v-icon>mdi-magnify</v-icon>
          <input
            type="text"
            class="pane-input width-fill"
            placeholder="Select/search columns that should be visible"
            v-model="search"
            v-on:keyup="searchColumns"
          />
        </div>
        <div v-if="getExperimentColumns">
          <v-list dense class="list-container">
            <v-list-item
              link
              v-for="item in filteredColumns"
              :key="item.value"
              @click="itemClicked(item)"
            >
              <v-list-item-subtitle class="primary-link">
                {{ item.text }}
                <v-icon small v-if="item.selected" color="success"
                  >mdi-check</v-icon
                >
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <div class="text-caption mt-1 ml-4 mb-2 mr-3">
            There are {{ getExperimentColumns.length }} user-column<span
              v-if="getExperimentColumns.length != 1"
              >s</span
            >
            in this experiment/s.
            <span v-if="getExperimentColumns.length == 0">
              We could not identifty any columns in your experiments/s.
            </span>
          </div>
        </div>
        <v-card-actions class="d-flex flex-row justify-end">
          <v-btn small color="primary" @click="save()">Save</v-btn>
          <v-btn small depressed @click="closeDialog('closed')">Cancel </v-btn>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="ml-1">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item dense link @click="toggleColumns(false)">
                <v-list-item-title>Unselect All</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="toggleColumns(true)">
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ColumnSelection",
  props: {
    flagVisibility: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["getExperimentColumns", "getSelectedProject"]),
  },
  data() {
    return {
      dialog: false,
      search: "",
      loading: false,
      filteredColumns: [],
    };
  },
  watch: {
    flagVisibility: async function (newflagVisibility) {
      if (newflagVisibility) {
        this.dialog = true;
        await this.searchColumns();
      }
    },
  },
  methods: {
    itemClicked(item) {
      item.selected = !item.selected;
    },
    toggleColumns(state) {
      this.filteredColumns.forEach((e) => {
        e.selected = state;
      });
    },
    async searchColumns() {
      let availableColumns = null;
      if (this.search.trim().length > 0) {
        let newColumns = [];
        this.getExperimentColumns.forEach((e) => {
          if (e.text.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
            newColumns.push(e);
          }
        });
        availableColumns = newColumns;
      } else availableColumns = this.getExperimentColumns;
      this.filteredColumns = availableColumns;
    },
    closeDialog: function (eventType = "closed") {
      this.dialog = false;
      this.$emit(eventType);
    },
    save() {
      this.$store.commit("SET_EXPERIMENT_COLUMNS", this.getExperimentColumns);
      this.closeDialog("saved");
    },
  },
};
</script>

<style>
.list-container {
  height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}
</style>
