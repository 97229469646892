import { AuthClient } from "@/store/common/axio-api";
import * as utils from "@/store/common/utils";

const state = {
  user: null,
  users: null,
  userTenants: null
};

const getters = {
  getUser: (state) => state.user,
  getUsers: (state) => state.users,
  getUserTenants: (state) => state.userTenants
};

const mutations = {
  SET_USER: (state, user) => (state.user = user),
  SET_USERS: (state, users) => (state.users = users),
  SET_USER_TENANTS: (state, tenants) => (state.userTenants = tenants)
};

const actions = {
  fetchUser({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      AuthClient.get(`users/${id}`, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_USER", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchUsers({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      var baseUrl = utils.prepareURL(`users?`, options);
      AuthClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_USERS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  toggleUserState({ rootGetters }, user) {
    return new Promise((resolve, reject) => {
      const action = user.is_active ? "activate" : "deactivate";
      AuthClient.post(`users/${user.uri.id}/${action}/`,
        {}, rootGetters.getAuthHeaders)
        .then(function (reponse) {
          resolve(reponse.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  createUser({ rootGetters }, user) {
    return new Promise((resolve, reject) => {
      AuthClient.post(`users/`, user, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  resetPassword({ rootGetters }, id) {
    return new Promise((resolve, reject) => {
      AuthClient.post(`users/${id}/reset-password/`, {}, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  editUser({ rootGetters }, user) {
    return new Promise((resolve, reject) => {
      AuthClient.put(`users/${user.id}/`, user.data, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchUserTenants({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      var baseUrl = utils.prepareURL(`users/${options.id}/tenants?`, options);
      AuthClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_USER_TENANTS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};