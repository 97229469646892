<template>
  <div>
    <v-row justify="center">
      <v-dialog persistent max-width="600px" v-model="showDialog">
        <v-card>
          <v-card-title>
            <span class="headline">Add New Servable</span>
          </v-card-title>
          <v-card-text>
            <validation-observer ref="observer">
              <form @submit.prevent="submit">
                <validation-provider
                  v-slot="{ errors }"
                  name="metadata_name"
                  rules="required"
                >
                  <v-text-field
                    v-if="metadata"
                    name="metadata_name"
                    label="Artifact Name*"
                    v-model="metadata.artifact"
                    :error-messages="errors"
                    :disabled="true"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="version"
                  rules="required"
                >
                  <v-text-field
                    v-if="metadata"
                    name="version"
                    label="New Version*"
                    v-model="newVersion"
                    :error-messages="errors"
                    :disabled="true"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                >
                  <v-text-field
                    name="name"
                    label="Name*"
                    v-model="servable.name"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="desc"
                  rules="required"
                >
                  <v-text-field
                    name="desc"
                    label="Description*"
                    v-model="servable.desc"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="servable.activated"
                  rules="required"
                >
                  <v-checkbox
                    v-model="servable.activated"
                    label="Pre-activate Servable"
                    :error-messages="errors"
                  ></v-checkbox>
                </validation-provider>
                <v-btn type="submit" color="primary" :loading="loading"
                  >Save</v-btn
                >
                <v-btn text @click="closeDialog(false)">Close</v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "NewProject",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  watch: {
    id: function (newId) {
      //artifact id
      if (newId != null) {
        this.$store
          .dispatch("fetchNewServableMetadata", this.id)
          .then((data) => {
            this.metadata = data;
            this.newVersion = `${data.major_version}.${data.minor_version}`;
            this.showDialog = true;
          });
      }
    },
  },
  data: () => ({
    loading: false,
    showDialog: false,
    metadata: null,
    newVersion: null,
    servable: {
      name: null,
      desc: null,
      activated: false,
      artifactId: -1,
    },
  }),
  methods: {
    closeDialog: function (saved) {
      this.clear();
      this.$emit("closed", saved);
      this.showDialog = false;
    },
    clear() {
      this.metadata = null;
      this.newVersion = null;
      this.servable = {
        name: null,
        desc: null,
        activated: false,
        artifactId: null,
      };
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    submit() {
      this.loading = true;
      this.servable.artifactId = this.id;
      this.$store
        .dispatch("createNewServable", this.servable)
        .then(() => {
          this.closeDialog();
          this.clear();
          this.$store.commit(
            "showNotification",
            "New Servable Successfully Created"
          );
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
            this.closeDialog();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
