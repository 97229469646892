<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-banner two-line>
          <v-avatar slot="icon" color="primary" size="40">
            <v-icon color="white"> mdi-store </v-icon>
          </v-avatar>
          <div>
            <div class="text-body-1">OneML Model Store</div>
            <div class="text-caption">
              {{ modelCardCount }} model cards found
            </div>
          </div>
        </v-banner>
        <v-text-field
          v-model="searchOptions.search"
          label="Search"
          single-line
          hide-details
          @keyup.enter="retrieveStoreEntries"
          class="mt-2"
        ></v-text-field>
        <!-- grid of rows x 2 cards  as result, keep the cols dynamic depending on screen size-->
        <v-row class="mt-3" v-if="getModelCards">
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="4"
            v-for="entry in getModelCards.results"
            :key="entry.uri.id"
          >
            <v-card>
              <v-card-title>
                <v-avatar color="success" size="40">
                  <v-icon color="white" size="30">
                    mdi-package-variant-closed
                  </v-icon>
                </v-avatar>
                <div class="ml-2">
                  <router-link
                    target="_blank"
                    class="text-body-1 secondary-link"
                    :to="{
                      name: 'model-card',
                      params: { id: entry.uri.id },
                    }"
                    >{{ entry.title }}</router-link
                  >
                  <div class="text-caption">{{ entry.summary }}</div>
                </div>
              </v-card-title>
              <v-card-actions>
                <div class="text-caption">
                  <span class="ml-2">
                    Created {{ entry.created_at | localdatetime }}
                  </span>
                  <span class="ml-3">
                    <v-icon class="thin-icon" size="18"> mdi-download </v-icon>
                    {{ entry.n_downloads }}
                  </span>
                  <span class="ml-3">
                    <v-icon class="thin-icon" size="16">
                      mdi-thumb-up-outline
                    </v-icon>
                    {{ entry.upvotes }}
                  </span>
                  <!-- <span class="ml-2">
                    <v-icon class="thin-icon" size="16">
                      mdi-clock-time-eight-outline
                    </v-icon>
                    {{ entry.created_at | localdatetime }}
                  </span> -->
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <div class="text-center mt-3">
          <v-pagination
            v-model="searchOptions.page"
            :length="searchOptions.numberOfPages"
            :total-visible="searchOptions.itemsPerPage"
            @next="retrieveStoreEntries()"
            @previous="retrieveStoreEntries()"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { localdatetime_fn } from "@/filters";
import { mapGetters } from "vuex";

export default {
  name: "ModelStoreView",
  data() {
    return {
      searchOptions: {
        search: "",
        itemsPerPage: 10,
        page: 1,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getModelCards"]),
    numberOfPages() {
      if (this.getModelCards == null) return 0;
      return Math.ceil(
        this.getModelCards.count / this.searchOptions.itemsPerPage
      );
    },
    modelCardCount() {
      if (this.getModelCards == null) return 0;
      return this.getModelCards.count;
    },
  },
  created() {
    this.retrieveStoreEntries();
  },
  methods: {
    retrieveStoreEntries() {
      const optionsCopy = structuredClone(this.searchOptions);
      this.loading = true;
      this.$store
        .dispatch("fetchModelCards", optionsCopy)
        .then(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>