<template>
  <v-container fluid>
    <v-data-table
      v-if="getTenantUsers"
      :headers="headers"
      :items="getTenantUsers.results"
      :server-items-length="getTenantUsers.count"
      :options.sync="options"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <div class="pa-4" v-if="getTenant">Users allocated to <b>{{ getTenant.name }}</b></div>
        <v-text-field
          v-model="search"
          label="Search existing allocations"
          class="mx-4"
          v-on:keyup.enter="fetchAllTenants()"
          clearable
        ></v-text-field>
      </template>
      <template v-slot:[`item.fullname`]="{ item }">
        {{ item.user.first_name }} {{ item.user.last_name }}
      </template>       
      <template v-slot:[`item.actions`]="{ item }">
        <v-switch
          class="activation-toggle"
          dense
          inset
          v-model="item.is_active"
          @change="toggleAllocation(item)"
        ></v-switch>
      </template>
    </v-data-table> 
    <v-btn
      fab
      dark
      color="primary"
      class="floating-button"
      v-tooltip="'Add New User'"
      v-if="isUserLevel3OrUp"
      @click="tenantIdForSelection= $route.params.id"
    >
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
    <AddUserToTenant :tenantId="tenantIdForSelection" 
      @closed="modalClosed(false)" 
      @added="modalClosed(true)"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AddUserToTenant from "../../components/tenant/AddUserToTenant.vue"

export default {
  name: 'TenantUserListView',
  components: {
    AddUserToTenant
  },
  computed: mapGetters(["getTenantUsers", "getTenant", "isUserLevel3OrUp"]),
  data() {
    return {
      loading: false,
      search: "",
      options: {
        init: true,
        itemsPerPage: 10,
      },
      tenantIdForSelection: null,
      headers: [
        { text: "Username", value: "user.username" },
        { text: "Full Name", value: "fullname" },
        // { text: "Allocation Status", value: "is_active" },
        { text: "Action", value: "actions" },
      ],
    };
  },
  created() {
    this.$store.dispatch("fetchTenant", this.$route.params.id)
    .then(()=> {
      this.fetchTenantUsers();
    });
  },
  watch:{
    options: {
      handler: function (oldVal, newOptions){
        if(!this.options.init){
          this.fetchTenantUsers();
        }
        this.options.init = false;
      },
    },
  },
  methods: {
    fetchTenantUsers(){
      console.log('fetching all tenants');
      this.loading = true;
      let optionsCopy = structuredClone(this.options);
      optionsCopy.search = this.search;
      optionsCopy.id = this.$route.params.id;
      this.$store
        .dispatch("fetchTenantUsers", optionsCopy)
        .finally(() => (this.loading = false));
    },
    toggleAllocation(item){
      const action = item.is_active ? "activate" : "deactivate";
      this.$store.dispatch("toggleTenantUserAllocation", 
        { 
          tenantId: this.$route.params.id,
          itemId: item.uri.id, 
          action: action
        })
      .then(() => {
        this.$store.commit("showNotification", "Changes successfully saved");
      })
      .catch((error) => {
        this.$store.commit("showNotification", "Something went wrong");
      });
    },
    modalClosed(refresh){
      this.tenantIdForSelection = null;
      if (refresh) this.fetchTenantUsers();
    }
  },
}
</script>

<style scoped>
.v-input {
  margin-top: 4px !important;
  margin-bottom: -10px !important;
}

</style>