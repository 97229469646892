import { ToolingClient, ToolingClientV2 } from "@/store/common/axio-api";
import * as utils from "@/store/common/utils";

const state = {
  searchkitConfig: null,
  feedbackCache: utils.retriveLocalObject('oneMLSearchFeedbackCache')
};

const getters = {
  getActiveSearchkitId: (state) => state.activeSearchkitId,
  getSearchkitConfig: (state) => state.searchkitConfig,
  getFeedbackCache: (state) => state.feedbackCache,
};

const mutations = {
  SET_SEARCHKIT_CONFIG: (state, config) => (state.searchkitConfig = config),
  SET_FEEDBACK_CACHE: (state, cache) => {
    state.feedbackCache = cache;
    utils.setLocalObject('oneMLSearchFeedbackCache', cache);
  }
};

const actions = {
  createSearchkit({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const tenantId = rootGetters.getSelectedTenant.uri.id;
      const url = `toolkits/${payload.toolkitId}/search-kit/`;
      ToolingClient.post(url, payload, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit('SET_SEARCHKIT_CONFIG', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchSearchkitByToolkitId({ commit, rootGetters }, toolkitId) {
    return new Promise((resolve, reject) => {
      const url = `toolkits/${toolkitId}/search-kit/config`;
      ToolingClient.get(url, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit('SET_SEARCHKIT_CONFIG', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchSearchkitByToolkitIdV2({ commit, rootGetters }, toolkitId) {
    return new Promise((resolve, reject) => {
      const url = `toolkits/${toolkitId}/search-kit/config`;
      ToolingClientV2.get(url, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit('SET_SEARCHKIT_CONFIG', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchSearchkit({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      const url = `search-kit/${id}/`;
      ToolingClient.get(url, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit('SET_SEARCHKIT_CONFIG', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  updateSearchkit({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const url = `search-kits/${payload.uri.id}/`;
      ToolingClient.put(url, payload, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit('SET_SEARCHKIT_CONFIG', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  submitSearchFeedback({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const url = `toolkits/${payload.toolkitId}/feedbacks/search/`;
      ToolingClient.post(url, payload, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchSearchAnalytics({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const url = `toolkits/${payload.toolkitId}/feedbacks/search/analytics/?type=${payload.type}`;
      ToolingClient.post(url, {}, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  exportSearchFeedback({ rootGetters }, toolkitId) {
    return new Promise((resolve, reject) => {
      const url = `toolkits/${toolkitId}/feedbacks/search/export`;
      ToolingClient.get(url, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};