<template>
  <v-row>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card class="tenant-user-card">
        <div class="d-flex flex-row pane-input-container bottom-border">
          <v-icon>mdi-magnify</v-icon>
          <input
            type="text"
            class="pane-input width-fill"
            placeholder="Select/search user for allocation"
            v-model="search"
            v-on:keyup.enter="fetchUsers()"
          />
        </div>
        <div v-if="getUsers">
          <v-list dense class="list-container">
            <v-list-item link 
              v-for="item in userList" 
              :key="item.username"
              @click="itemClicked(item)"
            >
            <v-list-item-subtitle class="">
              {{ item.username }} 
              <span class="text-caption">({{ item.first_name + ' ' + item.last_name | charlimit30 }})</span>
              <v-icon small v-if="item.selected || item.existingUser" color="success"
                  >mdi-check</v-icon
                >
            </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </div>
        <v-card-actions class="d-flex flex-row justify-end">
          <v-btn small color="primary" 
            :disabled="selectedUser == null" 
            @click="submit()"
            :loading="loading"
            >Add</v-btn
          >
          <v-btn small depressed @click="closeDialog('closed')">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AddUserToTenant",
  computed: {
    ...mapGetters(["getUsers", "getTenantUsers"]),
  },
  props:{
    tenantId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      search: "",
      loading: false,
      userList: null,
      existingUserLookup: null,
      userUpdates: {},
      selectedUser: null,
    };
  },
  watch: {
    tenantId: function (newTenantId) {
      if (this.tenantId != null) {
        this.dialog = true;
        this.createExistingUserLookup();
        this.fetchUsers();
        this.userUpdates = {};
        this.selectedUser = null;
      }
    },
  },
  methods: {
    closeDialog(eventType = "closed") {
      this.dialog = false;
      this.$emit(eventType);
    },
    itemClicked(item) {
      if(item.existingUser) return;
      //iterate thr existing users & update selected property
      this.userList.forEach((item) => {
        item.selected = false;
      });
      item.selected = true;
      this.selectedUser = item;
    },
    createExistingUserLookup(){
      this.existingUserLookup = {};
      this.getTenantUsers.results.forEach((item) => {
        if(item.is_active) this.existingUserLookup[item.user.username] = true;
      });
    },
    fetchUsers() {
      console.log("fetching all users");
      this.loading = true;
      this.$store
        .dispatch("fetchUsers", {
          search: this.search,
          itemsPerPage: 10,
        })
        .then(() => {
          //iterate through users & assign selected property
          this.userList = [];
          this.getUsers.results.forEach((user) => {
            const userCopy = structuredClone(user);
            userCopy.selected = false;
            if(userCopy.username in this.existingUserLookup)
              userCopy.existingUser = true;
            this.userList.push(userCopy);
          });
        })
        .finally(() => (this.loading = false));
    },
    submit(){
      console.log(this.selectedUser.uri.id);
      if(this.selectedUser == null) return;
      this.loading = true;
      this.$store.dispatch("addUserToTenant", {
        tenantId: this.tenantId,
        data: { user: this.selectedUser.uri.id  }
      }).then(() => {
        this.$store.commit("showNotification", "Changes successfully saved");
        this.closeDialog('added');
      })
      .catch((error) => {
        this.$store.commit("showNotification", "Something went wrong");
      }).finally(() => (this.loading = false));
    }
  },
}
</script>

<style>
.list-container {
  height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}
</style>