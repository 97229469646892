<template>
  <div class="ma-2">
    <v-card>
      <v-card-text>
        <v-banner single-line>
          <v-icon slot="icon" color="primary" size="36">
            mdi-store-plus
          </v-icon>
          <div class="text-body-1">Publish to Model Store</div>
          <template v-slot:actions v-if="getTrial">
            <v-btn
              color="primary"
              text
              :to="{ name: 'trial', params: { id: getTrial.uri.id } }"
              target="_blank"
            >
              {{ getTrial.name }}
              <v-icon right>mdi-open-in-new</v-icon>
            </v-btn>
            <v-btn color="primary" text @click="renderMarkdown()"
              >Preview
              <v-icon right size="30">mdi-language-markdown</v-icon>
            </v-btn>
            <v-btn color="primary" text @click="submit" :loading="loading"
              >Submit
              <v-icon right>mdi-publish</v-icon>
            </v-btn>
          </template>
        </v-banner>
        <div>
          <!-- For for artifact publishing -->
          <validation-observer ref="observer">
            <form @submit.prevent="submit">
              <validation-provider
                v-slot="{ errors }"
                name="title"
                rules="required"
              >
                <v-text-field
                  name="title"
                  label="Title*"
                  v-model="modelCard.title"
                  :error-messages="errors"
                  required
                  counter="300"
                  hint="Title of the artifact, useful for search & discovery"
                ></v-text-field>
              </validation-provider>
              <!-- text-area for summary -->
              <validation-provider
                v-slot="{ errors }"
                name="summary"
                rules="required"
              >
                <v-textarea
                  name="summary"
                  label="Summary*"
                  v-model="modelCard.summary"
                  :error-messages="errors"
                  required
                  rows="3"
                  counter="1024"
                  hint="A short summary of the artifact, diplayed in search results"
                  class="custom-line-height"
                ></v-textarea>
              </validation-provider>
              <!-- text-area for body -->
              <validation-provider
                v-slot="{ errors }"
                name="description"
                rules="required"
              >
                <v-textarea
                  flat
                  dense
                  :auto-grow="true"
                  name="body"
                  label="Body*"
                  v-model="modelCard.body"
                  :error-messages="errors"
                  required
                  rows="16"
                  counter="4096"
                  class="mt-3"
                ></v-textarea>
              </validation-provider>
            </form>
          </validation-observer>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="previewDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="previewDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Body Preview (Markdown)</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <div v-html="markdownToHtml" class="one-md"></div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapGetters } from "vuex";

setInteractionMode("eager");

export default {
  name: "ArtifactPublishView",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      modelCard: {
        title: "",
        summary:"",
        body: "",
      },
      md: null,
      markdownToHtml: null,
      previewDialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getTrial"]),
  },
  created() {
    this.md = require("markdown-it")({
      html: true,
      linkify: true,
      typographer: true,
      langPrefix: "oneml-",
    });
    //fetch trial details
    this.$store.dispatch("fetchTrial", this.$route.query.trial);
  },
  methods: {
    renderMarkdown() {
      this.previewDialog = true;
      let htmlStr = this.md.render(this.modelCard.body);
      this.markdownToHtml = htmlStr;
    },
    submit() {
      this.loading = true;
      this.$store
        .dispatch("publishToStore", {
          id: this.$route.params.id,
          modelCard: this.modelCard,
        })
        .then((data) => {
          this.$store.commit(
            "showNotification",
            "Servable artifact has been published to the model store."
          );
          const modelCardId = data.uri.id;
          this.$router.push({
            name: "model-card",
            params: { id: this.$route.params.id },
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.v-textarea textarea {
  line-height: 1.25rem !important;
}
</style>>
