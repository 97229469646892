<template>
  <v-row>
    <v-col cols="8">
      <div class="ma-2">
        <v-alert type="success" v-if="showRestorationAlert && (!creationMode)">
          We've restored your previous creation wizard session.
        </v-alert>
        <validation-observer ref="observer">
          <form @submit.prevent="submit">
            <validation-provider
              v-slot="{ errors }"
              name="project_id"
              rules="required"
            >
              <v-text-field
                name="project_id"
                label="Project*"
                v-model="toolkit.project_name"
                :error-messages="errors"
                hint="Currently selected project will be automatically copied here"
                persistent-hint
                required
                disabled
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="type"
              rules="required"
            >
              <v-select
                name="type"
                label="Toolkit Type*"
                :items="toolkitTypeChoices"
                v-model="toolkit.type"
                :error-messages="errors"
                required
                :disabled="!creationMode"
              >
              </v-select>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="name"
              rules="required"
            >
              <v-text-field
                name="name"
                label="Name*"
                v-model="toolkit.name"
                :error-messages="errors"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="desc"
              rules="required"
            >
              <v-textarea
                name="desc"
                label="Description*"
                v-model="toolkit.desc"
                :error-messages="errors"
                required
                rows="2"
              >
              </v-textarea>
            </validation-provider>
            <v-btn type="submit" color="primary" :loading="loading"
              >Save</v-btn>
            <v-btn v-if="!creationMode" class="ml-2" @click="nextStep()">Next</v-btn>
          </form>
        </validation-observer>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "CreateOrEditToolkit",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    showRestorationAlert: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data(){
    return {
      toolkitTypeChoices: [
        { text: "Search", value: "search" },
        // { text: "Document Classification", value: "classification" },
      ],
      creationMode: true,
      toolkit: {
        type: null,
        name: null,
        desc: null,
        tenant_id: null, // this will be set in [toolkit]store
        project_id: null,
        project_name: null,
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters(["getSelectedProject"]),
  },
  async created(){
    if(this.getSelectedProject == null){
      // select project here
      return;
    }
    if(this.id == null){
      // creation mode
      this.creationMode = true;
      this.toolkit.project_id = this.getSelectedProject.uri.id;
      this.toolkit.project_name = this.getSelectedProject.name;
    }
    else{
      // update mode
      this.creationMode = false;
      this.toolkit = await this.fetchToolkit();
      this.toolkit.project_id = this.toolkit.project.uri.id;
      this.toolkit.project_name = this.toolkit.project.name;
    }
  },
  methods:{
    async submit(){
      this.loading = true;
      let incrementStep = false;
      try{
        if (this.creationMode) {
          const result = await this.$store.dispatch("createToolkit", this.toolkit)
          incrementStep = true;
          this.$store.commit("SET_ACTIVE_TOOLKIT_CREATION_ID", result.uri.id);
        } else {
          await this.$store.dispatch("updateToolkit", this.toolkit);
          incrementStep = false;
        }
        this.$store.commit(
          "showNotification",
          "Toolkit details successfully saved"
        );
        this.$emit("step-completed", incrementStep);
      }
      catch(err){
        if (err.response.status == 400) {
          this.$refs.observer.setErrors(err.response.data);
        } else {
          this.$store.commit("showNotification", "Something went wrong");
          this.closeDialog();
        }
      }
      finally{
        this.loading = false;
      }
    },
    async fetchToolkit(){
      return this.$store.dispatch("fetchToolkit", this.id);
    },
    nextStep(){
      this.$emit("step-completed", true);
    }
  }
}
</script>

<style>

</style>