<template>
  <v-chip
    v-on:mouseout="toggleTenantSelection(false)"
    v-on:mouseover="toggleTenantSelection(true)"
    outlined
    small
    label
    :close="chooseTenant"
    color="primary"
    close-icon="mdi-pencil-outline"
    @click:close="changeTenant()"
  >
    Account {{ name }}
  </v-chip>
</template>

<script>
export default {
  name: "TenantSelectionBtn",
  data() {
    return {
      chooseTenant: false,
    };
  },
  props: {
    name: {
      type: String,
      required: false,
    },
  },
  methods: {
    toggleTenantSelection(flag) {
      if (!flag)
        setTimeout(() => {
          this.chooseTenant = false;
        }, 2000);
      else this.chooseTenant = true;
    },
    changeTenant() {
      this.$emit("changeTenant");
    },
  },
};
</script>

<style></style>
