<template>
  <div class="text-center">
    <v-dialog width="500" v-model="showDialog" persistent>
      <v-card>
        <v-card-title class="text-body-2 grey lighten-2"> Alert </v-card-title>
        <v-card-text class="mt-3">
          Are you sure you want to activate this servable ?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            small
            @click="updateServable()"
            :loading="loading"
          >
            Activate
          </v-btn>
          <v-btn text color="" @click="closeDialog()" small> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ServableActivation",
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  watch: {
    id: function (newId) {
      this.showDialog = newId != null;
    },
  },
  data: () => ({
    loading: false,
    showDialog: false,
  }),
  methods: {
    closeDialog: function (saved) {
      this.showDialog = false;
      this.$emit("closed", saved);
    },
    updateServable() {
      this.loading = true;
      this.$store
        .dispatch("patchServable", {
          id: this.id,
          activated: true,
        })
        .then(() => {
          this.$store.commit(
            "showNotification",
            "Servable has been successfully activated!"
          );
        })
        .catch(() => {
          this.$store.commit("showNotification", "Something went wrong");
        })
        .finally(() => {
          this.loading = false;
          this.closeDialog();
        });
    },
  },
};
</script>

<style></style>
