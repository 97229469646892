<template>
  <v-container fluid>
    <v-card max-width="550px" v-if="getUser">
      <v-card-title>User Details</v-card-title>
      <v-card-text class="d-flex flex-row">
        <div>
          <v-img
            src="@/assets/homer.png"
            class="profile-img"
            width="65"
            :aspect-ratio="1 / 1"
          ></v-img>
        </div>
        <div>
          <div>Username: {{ getUser.username }}</div>
          <div>First Name: {{ getUser.first_name }}</div>
          <div>Last Name: {{ getUser.last_name }}</div>
          <div class="">Email: {{ getUser.email }}</div>
          <div class="">Role: {{ getUser.user_role }}</div>
          <div class="mt-2">
            <v-chip color="success" v-if="getUser.is_active">Active</v-chip>
            <v-chip color="error" v-else>Inactive</v-chip>
            <v-chip color="secondary" class="ml-2" v-if="getUser.is_staff">Staff</v-chip>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn small text color="orange darken-2"
          @click="flags.confirmPasswordReset = true"
          >Reset Passward
          <v-icon
            right
            dark
          >
            mdi-lock-reset
          </v-icon>
        </v-btn>
        <v-btn small text color="priamry"
          :to="{ name: 'edit-user', params: { id: getUser.uri.id } }"
         >Edit
          <v-icon right dark small>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card :loading="flags.loadingTenants" class="mt-3" max-width="650px">
      <v-data-table
      v-if="getUser"
      :headers="tenantHeaders"
      :items="getUserTenants ? getUserTenants.results : []"
      :server-items-length="getUserTenants ? getUserTenants.count: 0"
      :options.sync="tenantOptions"
      dense
    >
      <template v-slot:[`item.created_on`]="{ item }">
        {{ item.created_on | localdatetime }}
      </template>
      <template v-slot:[`item.is_active`]="{ item }">
        <v-chip color="success" v-if="item.is_active" small>Active</v-chip>
        <v-chip color="error" v-else small>Inactive</v-chip>
      </template>
      <template v-slot:top>
        <div class="pa-4">User Allocations</div>
      </template>
    </v-data-table>
    </v-card>
    <v-row justify="center">
      <v-dialog persistent v-model="flags.confirmPasswordReset" max-width="500px">
        <!-- This dialog confirm before password reset -->
        <v-card>
          <v-card-title>Confirm Password Reset</v-card-title>
          <v-card-text>
            <p>
              Are you sure you want to reset the password for this user?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text small @click="resetPassword()" 
              color="orange darken-2"
              :loading="flags.resettingPassword"
              >
              Reset
              <v-icon right dark>mdi-lock-open</v-icon>
            </v-btn>
            <v-btn text small 
              @click="flags.confirmPasswordReset = false"
              >Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="flags.passwordReset" max-width="500px">
        <!-- This dialog confirm before password reset -->
        <v-card>
          <v-card-title>Password Reset</v-card-title>
          <v-card-text>
            <p>
              The password for this user has been reset, the new password is:
              <v-text-field class="mt-2" dense outlined append-icon="mdi-content-copy" 
                @click:append="copyNewPassword()"
                type="text" v-model="newPassword">
              </v-text-field>
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text small 
              @click="flags.passwordReset = false"
              >Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import copy from "copy-to-clipboard";

export default {
  name: "UserDetailView",
  computed: {
    ...mapGetters(["getUser", "getUserTenants"]),
  },
  data() {
    return {
      flags: {
        confirmPasswordReset: false,
        resettingPassword: false,
        passwordReset: false,
        loadingTenants: false,
      },
      newPassword: null,
      tenantHeaders: [
        { text: "Tenant", value: "tenant.name" },
        { text: "Added On", value: "created_on" },
        { text: "Allocation", value: "is_active" },
      ],
      tenantOptions: {
        init: true,
        itemsPerPage: 10,
      }
    };
  },
  created() {
    this.fetchUser();
  },
  methods: {
    fetchUser() {
      this.flags.loadingTenants = true;
      this.$store.dispatch("fetchUser", this.$route.params.id)
      .then(() => {
        setTimeout(() => {
          this.fetchUserTenants();
        }, 1000);
      });
    },
    resetPassword() {
      this.flags.resettingPassword = true;
      this.$store
        .dispatch("resetPassword", this.$route.params.id)
        .then((data) => {
          console.log(data);
          this.flags.passwordReset = true;
          this.newPassword = data.new_password;
        })
        .catch(() => {
          this.$store.commit("showNotification", "Something went wrong");
        }).finally(() => {
          this.flags.resettingPassword = false;
          this.flags.confirmPasswordReset = false;
        });
    },
    copyNewPassword() {
      copy(this.newPassword);
      this.$store.commit("showNotification", "Password copied to clipboard");
    },
    fetchUserTenants() {
      this.flags.loadingTenants = true;
      let optionsCopy = structuredClone(this.tenantOptions);
      optionsCopy.id = this.$route.params.id;
      this.$store.dispatch("fetchUserTenants", optionsCopy)
        .catch(() => {
          this.$store.commit("showNotification", "Something went wrong");
        }).finally(() => {
          this.flags.loadingTenants = false;
        });
    },
  },
}
</script>

<style>

</style>