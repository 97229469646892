<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" width="500" persistent>
			<v-card :loading="loading">
				<div class="d-flex flex-row pane-input-container bottom-border">
					<v-icon>mdi-magnify</v-icon>
					<input
						type="text"
						class="pane-input width-fill"
						placeholder="Select/search a project to work on"
						v-model="search"
						v-on:keyup="fetchProjectsFromTenant()"
					/>
				</div>
				<div v-if="getProjects">
					<v-list dense class="project-selection-list">
						<v-list-item
							link
							v-for="item in getProjects.results"
							:key="item.name"
							@click="selectProject(item)"
						>
							<v-list-item-subtitle class="primary-link">
								{{ item.name }}
							</v-list-item-subtitle>
						</v-list-item>
					</v-list>
					<div class="text-caption mt-1 ml-4 mb-2 mr-3">
						showing {{ getProjects.results.length }} projects out of
						{{ getProjects.count }} in this tenant
						<span v-if="getProjects.count == 0">
							Contact adminstrator or user with access "data-scientist 3" to
							create a project.
						</span>
					</div>
				</div>
				<v-card-actions class="d-flex flex-row justify-end">
					<v-btn small color="primary" @click="manageProjects()"
						>Manage Projects</v-btn
					>
					<v-btn small depressed @click="closeDialog()">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "ProjectSelection",
	data() {
		return {
			dialog: false,
			search: "",
			loading: false,
		};
	},
	computed: {
		...mapGetters(["getSelectedTenant", "getProjects"]),
	},
	props: {
		flagVisibility: {
			type: Boolean,
			required: false,
		},
	},
	watch: {
		flagVisibility: function (newflagVisibility) {
			if (newflagVisibility) {
				this.dialog = true;
				this.fetchProjectsFromTenant();
			}
		},
	},
	methods: {
		selectProject(project) {
			this.$store.commit("SET_SELECTED_PROJECT", project);
			this.closeDialog("selected");
		},
		fetchProjectsFromTenant() {
			const options = {
				id: this.getSelectedTenant.uri.id,
				search: this.search,
				itemsPerPage: 10,
			};
			this.loading = true;
			this.$store
				.dispatch("fetchProjectsbyTenant", options)
				.catch((err) => {
					this.$store.commit("showNotification", "Something went wrong");
				})
				.finally(() => {
					this.loading = false;
				});
		},
		closeDialog: function (eventType = "closed") {
			this.dialog = false;
			this.$emit(eventType);
		},
		manageProjects() {
			this.dialog = false;
			this.$emit("manageProjects");
		},
	},
};
</script>

<style>
.project-selection-list {
	min-height: 150px;
	max-height: 500px;
}
</style>
