import ProjectList from "../views/projects/ProjectList.vue";
import NewProject from "../views/projects/NewProject.vue";
import ProjectEditView from "../views/projects/ProjectEditView.vue";
import ProjectLanding from "@/views/projects/ProjectLanding.vue";

export default [
  {
    path: "/tenants/:id/projects/create",
    name: "create-project",
    component: NewProject,
  },
  {
    path: "/tenants/:id/projects/",
    name: "projects",
    component: ProjectList,
  },
  {
    path: "/projects/:id/edit",
    name: "edit-project",
    component: ProjectEditView,
  },
  {
    path: "/projects/:projectId/landing",
    name: "view-project",
    component: ProjectLanding,
  }
];