<template>
  <v-card class="d-flex flex-column tenant-select-card" elevation="2" rounded :loading="cardLoading">
    <div class="d-flex flex-row pane-input-container bottom-border">
      <v-icon>mdi-magnify</v-icon>
      <input type="text" class="pane-input width-fill" placeholder="Select/search an account to work on"
        v-model="search" v-on:keyup.enter="fetchUserTenants" />
    </div>
    <div v-if="getAuthUserTenants">
      <v-list dense>
        <v-list-item link v-for="item in getAuthUserTenants.results" :key="item.name" @click="selectTenant(item.tenant)">
          <v-list-item-subtitle class="primary-link">
            {{ item.tenant.name }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
      <div class="text-caption mt-1 ml-4 mb-2 mr-3">
        <div v-if="!isSearchApplied">
          <span v-if="getAuthUserTenants.count == 0">
            Contact adminstrator or user with access "data-scientist 3" or above
            to add you to an account.
          </span>
          <div v-else>
            You have access to {{ getAuthUserTenants.count }} tenant<span v-if="getAuthUserTenants.count != 1">s</span>.
            <span v-if="getAuthUserTenants.count > 7">(Showing top 7)</span>
          </div>
        </div>
        <div v-else>
          <span v-if="getAuthUserTenants.count == 0">
            No tenant found for your search.
          </span>
          <div v-else>
            {{ getAuthUserTenants.count }} tenant<span v-if="getAuthUserTenants.count != 1">s</span> found.
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TenantSelection",
  computed: {
    ...mapGetters(["getAuthUserTenants"]),
  },
  data() {
    return {
      loading: true,
      cardLoading: false,
      search: "",
      isSearchApplied: false,
      options: {
        itemsPerPage: 7,
      },
      headers: [
        { text: "Name", value: "tenant.name" },
        { text: "Action", value: "actions" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchUserTenants();
      },
      deep: true,
    },
  },
  created() {
    this.cardLoading = true;
    // setTimeout(() => {
    //   this.fetchUserTenants();
    // }, 1000);
    this.fetchUserTenants();
  },
  methods: {
    fetchUserTenants() {
      this.cardLoading = true;
      let optionsCopy = JSON.parse(JSON.stringify(this.options));
      optionsCopy.search = this.search;
      this.$store.dispatch("fetchAuthUserTenants", optionsCopy)
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.dispatch("logout");
            this.$router.push({ name: "landing" });
          }
        })
        .finally(() => {
          this.cardLoading = false;
          this.isSearchApplied = (this.search.length > 0) ? true : false;
        });
    },
    selectTenant(tenant) {
      // call store commit
      this.$store.commit("SET_SELECTED_TENANT", tenant);
      this.$store.commit("SET_SELECTED_PROJECT", null);
      this.$store.commit("SET_PROJECTS", null);
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style>

</style>
