<template>
  <v-container fluid>
    <v-card max-width="600">
      <v-btn
        icon
        class="float-right ma-1"
        v-tooltip="'Back to list of projects'"
        :to="{ name: 'projects', params: { id: tenantId } }"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        <div class="text-body-1">Add New Project</div>
      </v-card-title>
      <v-card-text>
        <validation-observer ref="observer">
          <form @submit.prevent="submit">
            <validation-provider
              v-slot="{ errors }"
              name="tenant_name"
              rules="required"
            >
              <v-text-field
                v-if="getTenant"
                name="tenant_name"
                label="Tenant Name*"
                v-model="getTenant.name"
                :error-messages="errors"
                :disabled="true"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="name"
              rules="required"
            >
              <v-text-field
                name="name"
                label="Name*"
                v-model="project.name"
                :error-messages="errors"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="desc"
              rules="required"
            >
              <v-text-field
                name="desc"
                label="Description*"
                v-model="project.desc"
                :error-messages="errors"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="servable_metric"
              rules="required"
            >
              <v-text-field
                name="servable_metric"
                label="Selection Metric*"
                v-model="project.servable_metric"
                :error-messages="errors"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="metric_direction"
              rules="required"
            >
              <v-select
                name="metric_direction"
                :items="metrics_direction_choices"
                v-model="project.metric_direction"
                :error-messages="errors"
                label="Metric Direction"
              ></v-select>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="confluence_link">
              <v-text-field
                name="confluence_link"
                label="Confluence Link"
                v-model="project.confluence_link"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="git_repo_link">
              <v-text-field
                name="git_repo_link"
                label="Git Repository"
                v-model="project.git_repo_link"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
            <v-row>
              <v-col cols="5">
                <v-switch
                  v-model="project.model_store_flag"
                  :label="'Model Store Integration'"
                ></v-switch>
              </v-col>
              <v-col>
                <v-switch
                  v-model="project.auto_publish_flag"
                  :label="'Auto Publish'"
                ></v-switch>
              </v-col>
            </v-row>
            <v-btn type="submit" color="primary" :loading="loading">Save</v-btn>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "NewProject",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: mapGetters(["getTenant"]),
  data() {
    return {
      loading: false,
      tenantId: -1,
      project: {
        tenantId: -1,
        name: "",
        desc: "",
        servable_metric: "",
        metric_direction: "maximize",
        confluence_link: "",
        git_repo_link: "",
        model_store_flag: true,
        auto_publish_flag: false,
      },
      metrics_direction_choices: [
        { text: "Maximize", value: "maximize" },
        { text: "Minimize", value: "minimize" },
      ],
    };
  },
  created() {
    this.tenantId = this.$route.params.id
    this.$store.dispatch("fetchTenant", this.tenantId);
  },
  methods: {
    submit() {
      this.loading = true;
      this.project.tenantId = this.tenantId;
      this.$store
        .dispatch("createNewProject", this.project)
        .then(() => {
          this.$store.commit(
            "showNotification",
            "New Tenant Successfully Created"
          );
          this.$router.push({
            name: "projects",
            params: { id: this.tenantId },
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
