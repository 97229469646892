<template>
  <div>
    <v-row justify="center">
      <v-dialog persistent max-width="500px" v-model="uiFlags.dialogConfirmDeletion">
        <v-card>
          <v-card-title>
            <span class="headline">Confirm Deletion</span>
          </v-card-title>
          <v-card-text>
            <div v-if="uiFlags.isValidating" class="d-flex">
              <!-- progress-circular with text -->
              <span>
                Verifying if your access to delete these trials..
              </span>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <div v-else>
              <div v-if="uiFlags.hasValidationErrors">
                <div>We've found below issues with your deletion request:</div>
                <div class="font-weight-medium red--text"> {{ validationError }}  </div>
                <div class="text-caption mt-2">Contact admin or any 'Data-Scientist 3' to get elevated access required for this deletion.</div>
              </div>
              <div v-else>
                <p>
                  Are you sure you want to delete the following trials?
                </p>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" small 
              v-if="(! uiFlags.isValidating) & (!uiFlags.hasValidationErrors)"
              @click="deleteTrials()"
              :loading="uiFlags.isDeleting"
              >Delete
            </v-btn>
            <v-btn text small @click="closeDialog(false)">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent max-width="500px" v-model="uiFlags.dialogDeletionSuccess">
        <v-card>
          <v-card-title>
            <span class="headline">Alert</span>
          </v-card-title>
          <v-card-text>
            A request to delete the said trials has been raised, and will be processed shortly.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text small @click="closeDialog(true)">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
//import vuex getter
import { mapGetters } from "vuex";

export default {
  name: 'TrialDeletionView',
  computed: {
    ...mapGetters([ "getSelectedProject" ]),
  },
  props: {
    // accept a list of trial ids
    trialIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      uiFlags: {
        isValidating: false,
        isDeleting: false,
        hasValidationErrors: false,
        dialogConfirmDeletion: false,
        dialogDeletionSuccess: false,
      },
      validationError: null
    };
  },
  watch: {
    trialIds() {
      if (this.trialIds.length > 0) {
        // console.log('deleting:'+ this.trialIds.toString());
        console.log(this.trialIds);
        this.validateDeletion();
      } else {
        this.resetFlags();
      }
    },
  },
  methods: {
    closeDialog(deleted) {
      // console.log('close dialog:'+ deleted.toString());
      if (deleted) {
        this.$emit('deleted');
      } else {
        this.$emit('closed');
      }
    },
    resetFlags(){
      this.uiFlags.isDeleting = false;
      this.uiFlags.isValidating = false;
      this.uiFlags.dialogConfirmDeletion = false;
      this.uiFlags.dialogDeletionSuccess = false;
      this.uiFlags.hasValidationErrors = false;
    },
    validateDeletion() {
      this.resetFlags()
      this.uiFlags.dialogConfirmDeletion = true;
      this.uiFlags.isValidating = true;
      //delay for ui jitters
      setTimeout(() => {
        // this.uiFlags.isValidating = false;
        this.$store.dispatch("validateDeletion", {
          project_id: this.getSelectedProject.uri.id,
          trial_idx: this.trialIds,
        })
        .catch((error) => {
          this.uiFlags.isValidating = false;
          this.uiFlags.hasValidationErrors = true;
          console.log(error);
          this.validationError = error.response.data['trial_idx'];
        })
        .finally(() => {
          this.uiFlags.isValidating = false;
        });
      }, 1000);
    },
    deleteTrials(){
      this.uiFlags.isDeleting = true;
      this.$store.dispatch("deleteTrials", {
        project_id: this.getSelectedProject.uri.id,
        trial_idx: this.trialIds,
      })
      .catch((err) => {
        console.log(err);
        this.$store.commit("showNotification", "Something went wrong");
        this.closeDialog(false);
      })
      .finally(() => {
        this.resetFlags();
        this.uiFlags.dialogDeletionSuccess = true;
      });
    }
  },
}
</script>

<style>

</style>