<template>
	<v-app>
		<v-app-bar app color="blue accent-4" dense dark>
			<v-btn icon :loading="getGlobalLoading && getAllowGlobalLoading"
				><v-icon>mdi-recycle-variant</v-icon></v-btn
			>
			<v-toolbar-title class="custom-toolbar-title">
				<router-link :to="{ name: 'about' }" class="ordinary-link">
					OneML <span class="text-caption">v1.3</span>
				</router-link>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<div>
				<v-tooltip bottom transition="fade-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							depressed
							outlined
							tile
							small
							class="left-rounded"
							v-bind="attrs"
							v-on="on"
							:to="{ name: 'landing' }"
						>
							<v-icon left> mdi-account-multiple </v-icon>
							{{ accountName }}
						</v-btn>
					</template>
					<span>Change Tenant</span>
				</v-tooltip>
				<v-tooltip bottom transition="fade-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							depressed
							outlined
							tile
							small
							class="right-rounded"
							v-bind="attrs"
							v-on="on"
							@click="flags.selectProject = true"
						>
							<v-icon left> mdi-folder-open-outline </v-icon>
							{{ projectName }}
						</v-btn>
					</template>
					<span>Set Project From Selected Tenant</span>
				</v-tooltip>
			</div>
			<!-- <v-chip class="" outlined pill link @click="flags.selectProject = true">
        <v-icon left> mdi-folder-open-outline </v-icon>
        {{ projectName }}
      </v-chip> -->
			<!-- <TenantSelectionBtn
        :name="accountName"
        class=""
        @changeTenant="$router.push({ name: 'landing' })"
      /> -->
		</v-app-bar>
		<v-navigation-drawer app :mini-variant="miniDrawer">
			<v-list>
				<v-tooltip right transition="fade-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item class="px-2" v-bind="attrs" v-on="on">
							<v-list-item-avatar>
								<router-link :to="{ name: 'profile' }">
									<v-icon large color="blue-grey darken-2"
										>mdi-account-circle</v-icon
									>
								</router-link>
							</v-list-item-avatar>
						</v-list-item>
					</template>
					<div>{{ fullName }}</div>
					<div class="text-caption">{{ userRole }}</div>
				</v-tooltip>
			</v-list>
			<v-divider></v-divider>
			<v-list dense nav>
				<v-tooltip right transition="fade-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item link :to="{ name: 'home' }" v-bind="attrs" v-on="on">
							<v-list-item-icon>
								<v-icon> mdi-view-dashboard </v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Project Dahsboard</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span>Project Dashboard</span>
				</v-tooltip>
				<v-tooltip right transition="fade-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item
							link
							:to="{ name: 'artifacts' }"
							v-bind="attrs"
							v-on="on"
						>
							<v-list-item-icon>
								<v-icon> mdi-magnify</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Artifacts</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span>Artifacts</span>
				</v-tooltip>
				<v-tooltip right transition="fade-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item
							link
							:to="{ name: 'experiments' }"
							v-bind="attrs"
							v-on="on"
							:inactive="isSME"
						>
							<v-list-item-icon>
								<v-icon> mdi-beaker-outline </v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Experiments</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span>Experiments</span>
				</v-tooltip>
				<v-tooltip right transition="fade-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item
							link
							:to="{ name: 'trials' }"
							v-bind="attrs"
							v-on="on"
							:inactive="isSME"
						>
							<v-list-item-icon>
								<v-icon> mdi-flask-outline </v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Trials</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span>Trials</span>
				</v-tooltip>
				<v-tooltip right transition="fade-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item
							link
							:to="{ name: 'toolkits' }"
							v-bind="attrs"
							v-on="on"
						>
							<v-list-item-icon>
								<v-icon> mdi-creation </v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>OneML Tools</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span>OneML Tools</span>
				</v-tooltip>
				<v-tooltip right transition="fade-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item
							link
							:to="{ name: 'servables' }"
							v-bind="attrs"
							v-on="on"
							:inactive="isSME"
						>
							<v-list-item-icon>
								<v-icon> mdi-package-variant-closed </v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Project Servables</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span>Project Servables</span>
				</v-tooltip>
				<v-tooltip right transition="fade-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item
							link
							:to="{ name: 'tenant-servables' }"
							v-bind="attrs"
							v-on="on"
						>
							<v-list-item-icon>
								<v-icon> mdi-list-box-outline </v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Tenant Servables</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span>Tenant Servables</span>
				</v-tooltip>
				<v-tooltip right transition="fade-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item
							link
							:to="{ name: 'admin' }"
							v-bind="attrs"
							v-on="on"
							v-if="isUserLevel3OrUp"
						>
							<v-list-item-icon>
								<v-icon> mdi-cog </v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Admin</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span>OneML Adminstration</span>
				</v-tooltip>
			</v-list>
			<template v-slot:append>
				<v-list dense>
					<v-tooltip right transition="fade-transition">
						<template v-slot:activator="{ on, attrs }">
							<v-list-item
								link
								v-if="isAuthenticated"
								@click="logout()"
								v-bind="attrs"
								v-on="on"
							>
								<v-list-item-icon>
									<v-icon color="red darken-4"> mdi-logout-variant </v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>Logout</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
						<span>Logout</span>
					</v-tooltip>
				</v-list>
			</template>
		</v-navigation-drawer>
		<v-main class="background">
			<!-- <v-progress-linear
        indeterminate
        class="loader"
        v-show="getGlobalLoading && getAllowGlobalLoading"
      ></v-progress-linear> -->
			<router-view></router-view>
			<ProjectSelection
				v-bind:flagVisibility="flags.selectProject"
				@closed="modelClosed('selectionCancelled')"
				@manageProjects="modelClosed('manageProject')"
				@selected="modelClosed('projectSelected')"
			></ProjectSelection>
			<Snackbar></Snackbar>
		</v-main>
		<v-footer app> </v-footer>
	</v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Snackbar from "../components/SnackbarComponent.vue";
import ProjectSelection from "../components/projects/ProjectSelection.vue";
import TenantSelectionBtn from "../components/tenant/TenantSelectionBtn.vue";
import { charLimit_30, charLimit_20 } from "../filters.js";

export default {
	name: "MasterView",
	components: { Snackbar, ProjectSelection, TenantSelectionBtn },
	computed: {
		...mapGetters([
			"userObj",
			"getUserGroups",
			"isAuthenticated",
			"useDarkTheme",
			"getSelectedTenant",
			"getSelectedProject",
			"getGlobalLoading",
			"getAllowGlobalLoading",
			"isUserLevel3OrUp",
			"isSME",
		]),
		userIinitials: function () {
			var initials = "??";
			if (this.userObj) {
				var firstChar =
					this.userObj.first_name && this.userObj.first_name.length > 0
						? this.userObj.first_name[0]
						: "?";
				var lastChar =
					this.userObj.last_name && this.userObj.last_name.length > 0
						? this.userObj.last_name[0]
						: this.userObj.first_name && this.userObj.first_name.length > 1
						? this.userObj.first_name[1]
						: "?";
				initials = firstChar + lastChar;
				initials = initials.toUpperCase();
			}
			return initials;
		},
		fullName: function () {
			var fullName = "";
			if (this.userObj) {
				fullName = this.userObj.first_name + " " + this.userObj.last_name;
				if (fullName.trim().length == 0) fullName = this.userObj.username;
			}
			return fullName;
		},
		accountName: function () {
			return charLimit_30(this.getSelectedTenant.name);
		},
		projectName: function () {
			if (this.getSelectedProject == null) return "Select Project";
			return charLimit_30(this.getSelectedProject.name);
		},
	},
	data: () => ({
		miniDrawer: true,
		flags: {
			chooseTenant: false,
			selectProject: false,
		},
		userRole: "Unknown",
	}),
	async created() {
		// set allow global loading to true
		this.$store.commit("updateGlobalLoading", true);
		//check if selected tenant is null
		if (!this.isAuthenticated || this.userObj == null) {
			console.log(
				"not authenticated or user details missing, redirecting to landing"
			);
			await this.logout();
			this.$router.push({ name: "landing" });
			return;
		}
		if (this.getSelectedTenant == null) {
			console.log("tenant not selected, redirecting to landing");
			this.$router.push({ name: "landing" });
			return;
		}
		if (
			this.getUserGroups == null ||
			(this.getUserGroups != null && this.getUserGroups.length == 0)
		) {
			console.log(
				"user-role not assigned, redirecting to role-assignment-issue"
			);
			this.$router.push({ name: "role-assignment-issue" });
			return;
		} else {
			this.userRole = this.getUserGroups[0];
		}
		// this.$store.subscribe((mutation, state) => {
		//   // console.log(mutation.type);
		//   if (mutation.type === "hideDrawer") {
		//     this.miniDrawer = true;
		//   }
		// });
	},
	mounted() {
		if (this.$route.name == "home" && this.getSelectedProject == null) {
			this.flags.selectProject = true;
		}
	},
	methods: {
		async logout() {
			await this.$store.dispatch("logout");
			await this.$store.dispatch("resetSelectedProject");
			this.$router.push({ name: "landing" });
		},
		modelClosed(key) {
			this.flags.selectProject = false;
			switch (key) {
				case "selectionCancelled":
					if (this.getSelectedProject == null)
						this.$router.push({ name: "about" });
					break;
				case "projectSelected":
					if (this.$route.name != "home") this.$router.push({ name: "home" });
					break;
				case "manageProject":
					this.$router.push({
						name: "projects",
						params: { id: this.getSelectedTenant.uri.id },
					});
					break;
			}
		},
	},
};
</script>

<style>
.custom-toolbar-title {
	padding-left: 0px !important;
}
</style>
