import { ToolingClient } from "@/store/common/axio-api";
// import * as utils from "@/store/common/utils";

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
  submitGenAIFeedback({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const url = `toolkits/${payload.toolkitId}/feedbacks/genai/`;
      ToolingClient.post(url, payload, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  exportGenAIFeedback({ rootGetters }, toolkitId) {
    return new Promise((resolve, reject) => {
      const url = `toolkits/${toolkitId}/feedbacks/genai/export/`;
      ToolingClient.get(url, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchGenAIAnalytics({ rootGetters }, toolkitId) {
    return new Promise((resolve, reject) => {
      const url = `toolkits/${toolkitId}/feedbacks/genai/analytics/`;
      ToolingClient.post(url, {}, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
