<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="showDialog" max-width="500" persistent>
        <v-card>
          <v-card-title>
            <div class="text-body-1">Edit Profile</div>
          </v-card-title>
          <v-card-text v-if="user">
            <validation-observer ref="observer">
              <form @submit.prevent="submit">
                <validation-provider
                  v-slot="{ errors }"
                  name="first_name"
                  rules="required"
                >
                  <v-text-field
                    name="first_name"
                    label="First Name*"
                    v-model="user.first_name"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="last_name"
                  rules="required"
                >
                  <v-text-field
                    name="last_name"
                    label="Last Name*"
                    v-model="user.last_name"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <v-btn type="submit" color="primary" :loading="loading"
                  >Save</v-btn
                >
                <v-btn text @click="closeDialog(false)">Close</v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "EditUser",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: mapGetters(["userObj"]),
  props: {
    showDialog: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    showDialog: function (newShowDialogValue) {
      if (newShowDialogValue) {
        this.user = { ...this.userObj };
      }
    },
  },
  data() {
    return {
      user: null,
      loading: false,
    };
  },
  created() {},
  methods: {
    closeDialog: function (saved) {
      this.$emit("closed", saved);
    },
    submit() {
      this.loading = true;
      this.$store
        .dispatch("editSelf", this.user)
        .then(() => {
          this.closeDialog();
          this.$store.commit("showNotification", "Changes successfully saved");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
            this.closeDialog();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
