import ServableList from "../views/servables/ServableList.vue";
import ServableDetail from "../views/servables/ServableDetail.vue";

export default [
  {
    path: "/servables",
    name: "servables",
    component: ServableList,
  },
  {
    path: "/servables/:id",
    name: "servable",
    component: ServableDetail,
  }
];