import Home from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import UnderConstruction from "../views/UnderConstruction.vue";
import GenericErrorView from "../views/errors/GenericErrorView.vue";
import RoleAssignmentIssue from "../views/errors/RoleAssignmentIssue.vue";


export default [
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/error",
    name: "genericError",
    component: GenericErrorView,
  },
  {
    path: "/construction",
    name: "construction",
    component: UnderConstruction,
  },
  {
    path: "/unauthorized",
    name: "role-assignment-issue",
    component: RoleAssignmentIssue,
  }
];