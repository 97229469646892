<template>
  <div>
    <!-- <div>{{ $route.params.title }}</div>
    <div>{{ $route.params.body }}</div> -->
    <v-card class="ma-3" id="error-card">
      <v-card-title>
        <div>{{ $route.params.title }}</div>
      </v-card-title>
      <v-card-text>
        {{ $route.params.body }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "GenericErrorView",
};
</script>

<style>
#error-card {
  max-width: 600px;
}
</style>
