/* eslint-disable */
<template>
  <div>
    <v-row justify="center">
      <v-dialog persistent max-width="600px" v-model="showDialog">
        <v-card>
          <v-card-title>
            <span class="headline">Add New Project</span>
          </v-card-title>
          <v-card-text>
            <validation-observer ref="observer">
              <form @submit.prevent="submit">
                <validation-provider
                  v-slot="{ errors }"
                  name="tenant_name"
                  rules="required"
                >
                  <v-text-field
                    v-if="tenant"
                    name="tenant_name"
                    label="Tenant Name*"
                    v-model="tenant.name"
                    :error-messages="errors"
                    :disabled="true"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                >
                  <v-text-field
                    name="name"
                    label="Name*"
                    v-model="project.name"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                >
                  <v-text-field
                    name="desc"
                    label="Description*"
                    v-model="project.desc"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="servable_metric"
                  rules="required"
                >
                  <v-text-field
                    name="servable_metric"
                    label="Selection Metric*"
                    v-model="project.servable_metric"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="metric_direction"
                  rules="required"
                >
                  <v-select
                    name="metric_direction"
                    :items="metrics_direction_choices"
                    v-model="project.metric_direction"
                    label="Metric Direction"
                  ></v-select>
                </validation-provider>
                <v-btn type="submit" color="primary" :loading="loading"
                  >Save</v-btn
                >
                <v-btn text @click="closeDialog(false)">Close</v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: "NewProject",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  watch: {
    id: function (newId) {
      if (newId != -1) {
        this.$store.dispatch("fetchTenant", newId).then((data) => {
          this.tenant = data;
          this.showDialog = true;
        });
      }
    },
  },
  data: () => ({
    loading: false,
    tenant: null,
    showDialog: false,
    project: {},
    metrics_direction_choices: [
      ("maximize", "Maximize"),
      ("minimize", "Minimize"),
    ],
  }),
  methods: {
    closeDialog: function (saved) {
      this.clear();
      this.$emit("closed", saved);
      this.showDialog = false;
    },
    clear() {
      this.tenant = null;
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    submit() {
      this.loading = true;
      this.$store
        .dispatch("createNewProject", this.tenant)
        .then(() => {
          this.closeDialog();
          this.clear();
          this.$store.commit(
            "showNotification",
            "New Project Successfully Created"
          );
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
            this.closeDialog();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
