<template>
  <v-container fluid>
    <v-card>
      <v-tabs v-model="tab" @change="tabChanged()">
        <v-tab href="#overview"> Overview </v-tab>
        <v-tab href="#charts"> Charts </v-tab>
        <v-tab href="#artifacts"> Artifacts </v-tab>
      </v-tabs>
    </v-card>

    <v-tabs-items v-model="tab" class="tab-container mt-3 mb-2">
      <v-tab-item value="overview">
        <TrialOverview />
      </v-tab-item>
      <v-tab-item value="charts">
        <TrialCharts />
      </v-tab-item>
      <v-tab-item value="artifacts">
        <ArtifactView :artifactId="artifactId" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import TrialOverview from "../../components/trials/TrialOverview.vue";
import TrialCharts from "../../components/trials/TrialCharts.vue";
import ArtifactView from "../../components/artifacts/ArtifactView.vue";

export default {
  name: "TrialDetail",
  components: { TrialOverview, TrialCharts, ArtifactView },
  data() {
    return {
      tab: "overview",
      artifactId: null,
      tabs: ["overview", "charts", "artifacts"],
    };
  },
  async created() {
    const query = this.$route.query;
    if (query != undefined) {
      const queryTab = query.tab;
      if (this.tabs.indexOf(queryTab) >= 0) {
        this.tab = queryTab;
      }
      if (query.artifact_id) {
        this.artifactId = query.artifact_id;
      }
    }
  },
  methods: {
    tabChanged() {
      this.$router.replace({
        name: this.$route.name,
        params: { id: this.$route.params.id },
        query: { tab: this.tab, artifact_id: this.artifactId },
      });
    },
  },
};
</script>

<style scoped>
.tab-container {
  background-color: transparent !important;
}
</style>
