<template>
  <div>
    <v-card class="ma-3" id="error-card">
      <v-card-title>
        <div>User Role Issue</div>
      </v-card-title>
      <v-card-text>
        You've not be been assigned any role.<br/>
        Contact adminstrator or user with role "data-scientist 3" or above to assign one.<br/>
        Once you've been added to an account, please logout and login again.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text small @click="logout()">Logout</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AccountAllocationIssue",
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "landing" });
      });
    },
  },
};
</script>

<style>
#error-card {
  max-width: 600px;
}
</style>
