<template>
  <v-card
    elevation="2"
    class="mx-auto login-card"
    max-width="450"
    outlined
    :loading="loading"
  >
    <v-card-title>
        <span class="centered-span-title" v-text="cardTitle"></span>
    </v-card-title>
    <v-card-text v-if="!accessRequested">
      <validation-observer ref="observer">
        <form @submit.prevent="submit">
          <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="required"
          >
            <v-text-field
              v-model="accessRequest.email"
              :error-messages="errors"
              label="Email*"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="first_name"
            rules="required"
          >
            <v-text-field
              v-model="accessRequest.first_name"
              :error-messages="errors"
              label="First Name*"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="last_name"
            rules="required"
          >
            <v-text-field
              v-model="accessRequest.last_name"
              :error-messages="errors"
              label="Last Name*"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="team_name"
            rules="required"
          >
            <v-text-field
              v-model="accessRequest.team_name"
              :error-messages="errors"
              label="Team*"
              required
            ></v-text-field>
          </validation-provider>
          <div class="">
            <v-btn
              type="submit"
              :loading="loading"
              color="primary"
              class="width-fill ma-1"
            >
              Submit
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-card-text>
    <v-card-text v-else>
      <p class="ma-2">
        Thank you for your interest in OneML. Either Admin or anyone with role 'Data-Scientist 3' 
        will review your request & take necessary action.
      </p>
      <div class="d-flex flex-row justify-end">
        <!-- <a class="primary-link" href="#" @click.prevent="requestAccess()">Request for access</a> -->
        <v-btn text color="primary" @click="closeDialog()">Log In
          <v-icon right>mdi-open-in-new</v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </v-card> 
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
  name: 'CreateAccessRequest',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed:{
    cardTitle(){
      return this.accessRequested ? 'Access Requested' : 'Request Access to OneML'
    }
  },
  data: () => ({
    loading: false,
    accessRequest:{
      email: '',
      first_name: '',
      last_name: '',
      team_name: '',
    },
    accessRequested: false
  }),
  methods:{
    closeDialog(){
      this.$emit('closed');
    },
    submit(){
      this.loading = true;
      setTimeout(() => {
        this.createAccessRequest();
      }, 1000);
    },
    createAccessRequest(){
      this.$refs.observer.validate();
      this.$store
        .dispatch("createAccessRequest", this.accessRequest)
        .then(() => {
          this.accessRequested = true;
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.$refs.observer.setErrors(err.response.data);
          } else {
            this.$store.commit("showNotification", "Something went wrong");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style>

</style>