<template>
	<div>
		<v-row justify="center">
			<v-dialog v-model="showDialog" max-width="700" persistent>
				<v-card>
					<v-card-title>
						<div class="text-body-1">Edit Experiment</div>
					</v-card-title>
					<v-card-text v-if="getExperiment">
						<validation-observer ref="observer">
							<form @submit.prevent="submit">
								<validation-provider
									v-slot="{ errors }"
									name="name"
									rules="required"
								>
									<v-text-field
										name="name"
										label="Name*"
										v-model="experiment.name"
										:error-messages="errors"
										required
									></v-text-field>
								</validation-provider>
								<validation-provider
									v-slot="{ errors }"
									name="desc"
									rules="required"
								>
									<v-text-field
										name="desc"
										label="Description*"
										v-model="experiment.desc"
										:error-messages="errors"
										required
									></v-text-field>
								</validation-provider>
								<validation-provider
									v-slot="{ errors }"
									name="confluence_link"
									rules="required"
								>
									<v-text-field
										name="confluence_link"
										label="Confluence Link*"
										v-model="experiment.confluence_link"
										:error-messages="errors"
										required
									></v-text-field>
								</validation-provider>
								<validation-provider
									v-slot="{ errors }"
									name="jira_link"
									rules="required"
								>
									<v-text-field
										name="jira_link"
										label="Jira Link*"
										v-model="experiment.jira_link"
										:error-messages="errors"
										required
									></v-text-field>
								</validation-provider>
								<!-- <validation-provider
                  v-slot="{ errors }"
                  name="story_points"
                  rules="required"
                >
                  <v-text-field
                    name="story_points"
                    label="Story Points*"
                    v-model="experiment.story_points"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider> -->
								<v-btn type="submit" color="primary" :loading="loading"
									>Submit</v-btn
								>
								<v-btn text @click="closeDialog(false)">Close</v-btn>
							</form>
						</validation-observer>
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import {
	ValidationObserver,
	ValidationProvider,
	setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

export default {
	name: "ExperimentEditView",
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	computed: mapGetters(["getExperiment"]),
	props: {
		id: {
			type: Number,
			required: false,
		},
	},
	watch: {
		id: function (newId) {
			if (newId != -1) {
				this.$store.dispatch("fetchExperiment", this.id).then((data) => {
					this.showDialog = true;
					this.experiment.name = data.name;
					this.experiment.desc = data.desc;
					this.experiment.confluence_link = data.confluence_link;
					this.experiment.jira_link = data.jira_link;
					this.experiment.story_points = data.story_points;
				});
			}
		},
	},
	data() {
		return {
			experiment: {
				name: null,
				desc: null,
				confluence_link: null,
				jira_link: null,
				story_points: null,
			},
			loading: false,
			showDialog: false,
		};
	},
	created() {},
	methods: {
		closeDialog: function (saved) {
			this.showDialog = false;
			this.$emit("closed", saved);
		},
		async submit() {
			this.loading = true;
			try {
				await this.$store.dispatch("editExperiment", {
					id: this.id,
					data: this.experiment,
				});
				this.closeDialog();
				this.$store.commit("showNotification", "Changes successfully saved");
			} catch (err) {
				console.log(err);
				if (err.response && err.response.status === 400) {
					this.$refs.observer.setErrors(err.response.data);
				} else {
					this.$store.commit("showNotification", "Something went wrong");
					this.closeDialog();
				}
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style></style>
