import { AuthClient } from "@/store/common/axio-api";
import * as utils from "@/store/common/utils";

const state = {
  accessRequests: null
};

const getters = {
  getAccessRequests: (state) => state.accessRequests
};

const mutations = {
  SET_ACCESS_REQUESTS: (state, accessRequests) => (state.accessRequests = accessRequests)
};

const actions = {
  createAccessRequest({ rootGetters }, accessRequest) {
    return new Promise((resolve, reject) => {
      AuthClient.post(`access-requests/`, accessRequest)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchAllAccessRequests({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      var baseUrl = utils.prepareURL(`access-requests/?`, options);
      if (options.status != null) baseUrl += `&status=${options.status}`;
      AuthClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_ACCESS_REQUESTS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  processAccessRequest({ rootGetters }, options) {
    return new Promise((resolve, reject) => {
      const url = `access-requests/${options.id}/${options.action}/`;
      AuthClient.post(url, options, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};