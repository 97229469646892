import TenantList from "../views/tenants/TenantList.vue";
import TenantUserListView from "../views/tenants/TenantUserListView.vue"
import TenantServables from "../views/tenants/TenantServables.vue"

export default [
  {
    path: "/tenants",
    name: "tenants",
    component: TenantList,
  },
  {
    path: "tenants/:id/users",
    name: "tenant-users",
    component: TenantUserListView
  },
  {
    path: "/tenants/servables",
    name: "tenant-servables",
    component: TenantServables,
  },
];