<template>
	<div class="about">
		<!-- A beautiful about page with "OneML" h1 -->
		<h1 class="oneml-title">
			OneML
			<!-- Unicode symbol for re-cycling -->
			<span class="mdi mdi-recycle-variant oneml-logo"></span>
			<span class="text-caption ml-2">v1.3</span>
		</h1>
		<div class="body-2">An end-to-end MLOps framework</div>
		<div class="body-2 mt-2">
			<a
				href="https://confluence.wolterskluwer.io/display/CCAI/1.+OneML%3A+Introduction"
				target="_blank"
				class="primary-link"
				>Introduction</a
			>
			|
			<a
				href="https://confluence.wolterskluwer.io/pages/viewpage.action?pageId=502440005"
				target="_blank"
				class="primary-link"
				>Technical Details</a
			>
			|
			<a
				href="https://confluence.wolterskluwer.io/display/CCAI/4.3+OneML+%3A+Tutorials"
				target="_blank"
				class="primary-link"
				>Tutorials</a
			>
		</div>
	</div>
</template>

<script>
export default {
	name: "AboutView",
};
</script>

<style scoped>
/* about class ensures that it's content are at center horizontally*/
.about {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 768px;
}

/* font size of 55px & non-bold text */
.oneml-title {
	font-size: 55px;
	font-weight: 400;
}

.oneml-logo {
	font-size: 48px;
	margin-left: -10px;
	color: #1565c0;
}
</style>
