import { openDB, deleteDB, wrap, unwrap } from 'idb';

const DATABASE_NAME = 'oneml';
const DATABASE_VERSION = 1;
const STORE_NAME = 'tracker_templates';

const state = {
  templateDB: null,
};

const mutations = {
  SET_TEMPLATE_DB: (state, db) => (state.templateDB = db),
};

const getters = {};

const actions = {
  async initTemplateStore({ commit }) {
    const db = await openDB(DATABASE_NAME, DATABASE_VERSION, {
      upgrade(db) {
        db.createObjectStore(STORE_NAME);
      },
    });
    commit('SET_TEMPLATE_DB', db);
  },
  async getTemplate({ state }, id) {
    return await state.templateDB.get(STORE_NAME, id);
  },
  async addTemplate({ state }, wrapperObj) {
    return await state.templateDB.put(STORE_NAME, wrapperObj.data, wrapperObj.id);
  },
  async deleteTemplate({ state }, id) {
    return await state.templateDB.delete(STORE_NAME, id);
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};