<template>
	<div class="height-fill">
		<div>
			<v-card>
				<v-banner two-line>
					<v-avatar slot="icon" color="primary" size="40">
						<v-icon color="white"> mdi-beaker-outline </v-icon>
					</v-avatar>
					<div>
						<div class="text-body-1">Experiments</div>
						<div class="text-caption" v-if="getExperiments">
							{{ getExperiments.count }} experiments found
						</div>
					</div>
				</v-banner>
				<div class="experiment-delete">
					<v-btn
						icon
						v-if="
							selectedExperiments.length >= 1 &&
							selectedExperiments.length <= 100
						"
						color="red"
						@click="deleteExperiments()"
					>
						<v-icon
							style="margin-bottom: 12px !important"
							v-tooltip="'Delete Selected Experiments'"
							>mdi-delete-outline</v-icon
						>
					</v-btn>
				</div>
				<v-card-title>
					<v-text-field
						single-line
						v-on:keyup.enter="fetchExperiments()"
						v-model="experimentData.query"
						:append-icon="queryIcon"
						label="Search By Experiment Identifier , Name , Description"
						class="query-field"
						placeholder="Search By Experiment Identifier , Name , Description"
					></v-text-field>
				</v-card-title>
				<v-data-table
					v-if="experimentData.result"
					:headers="experimentSchema.staticHeaders"
					:items="experimentData.result"
					:server-items-length="experimentData.count"
					class="trial-table mb-2"
					:fixed-header="true"
					show-select
					v-model="selectedExperiments"
					:single-select="false"
					:options.sync="experimentOptions"
					item-key="uri.id"
					:footer-props="{
						'items-per-page-options': [25, 50, 75, 100],
					}"
					dense
				>
					<!-- Name Column -->
					<template v-slot:[`item.name`]="{ item }">
						<router-link
							class="primary-link"
							:to="{
								name: 'trials',
								query: { id: item.uri.id },
							}"
							>{{ item.name }}</router-link
						>

						<v-btn
							small
							icon
							@click="copyToClipboard(item.uri.id)"
							v-tooltip="'Copy Experiment ID'"
						>
							<v-icon small>mdi-content-copy</v-icon>
						</v-btn>
					</template>

					<!-- Created on Column -->
					<template v-slot:[`item.created_on`]="{ item }">
						{{ item.created_on | localdatetime }}
					</template>

					<!-- Description Column -->
					<template v-slot:[`item.desc`]="{ item }">
						<div class="description-cell">
							{{ item.desc }}
						</div>
					</template>

					<!-- Action Column -->
					<template v-slot:[`item.action`]="{ item }">
						<v-btn
							icon
							v-if="selectedExperiments.length == 0"
							@click="editExperiment(item.uri.id)"
						>
							<template v-if="experimentData.experimentEditId !== item.uri.id">
								<v-icon small>mdi-pencil</v-icon>
							</template>
							<template v-else>
								<v-progress-circular
									indeterminate
									size="20"
									color="primary"
									:width="2"
								></v-progress-circular>
							</template>
						</v-btn>
						<v-btn
							icon
							v-if="selectedExperiments.length == 0"
							color="red"
							@click="deleteExperiments(item.uri.id)"
						>
							<v-icon v-tooltip="'Delete Experiment'"
								>mdi-delete-outline</v-icon
							>
						</v-btn>
					</template>
				</v-data-table>
				<EditExperiment
					@closed="modalClosed('editExperiment')"
					v-bind:id="experimentData.experimentEditId"
				/>
			</v-card>
		</div>
		<ExperimentDeletionView
			:experiment-ids="experimentsTobeDeleted"
			@closed="modalClosed('deletionCancelled')"
			@deleted="modalClosed('experimentsDeleted')"
		/>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import ExperimentDeletionView from "../../components/experiments/ExperimentDeletionView.vue";
import EditExperiment from "../../components/experiments/ExperimentEdit.vue";

export default {
	name: "ExperimentList",
	components: {
		ExperimentDeletionView,
		EditExperiment,
	},
	computed: {
		...mapGetters(["getSelectedProject", "getExperiments"]),
	},
	data() {
		return {
			loading: false,
			selectedExperiments: [],
			experimentsTobeDeleted: [],
			experimentOptions: {
				id: null,
				page: 1,
				itemsPerPage: 25,
				search: null,
			},

			experimentSchema: {
				staticHeaders: [
					{ text: "Experiment", value: "name" },
					{ text: "Trials Count", value: "trial_count" },
					{ text: "Created On", value: "created_on" },
					{ text: "Description", value: "desc" },
					{ text: "Action", value: "action", sortable: false },
				],
			},
			experimentData: {
				result: [],
				query: null,
				count: 0,
				experimentEditId: -1,
			},
		};
	},
	watch: {
		experimentOptions: function () {
			this.fetchExperiments();
		},
	},
	async created() {
		await this.init();
	},
	methods: {
		async init() {
			this.experimentOptions.id = this.getSelectedProject.uri.id;
			await this.fetchExperiments();
		},
		prepareExperimentOptions() {
			let optionsCopy = structuredClone(this.experimentOptions);
			optionsCopy.search = this.experimentData.query;
			return optionsCopy;
		},
		async fetchExperiments(loadingAttribute) {
			const optionsCopy = this.prepareExperimentOptions();
			this.experimentData.selection = [];
			try {
				await this.$store.dispatch(
					"fetchExperimentsIndexByProject",
					optionsCopy
				);
				this.experimentData.result = [...this.getExperiments.results];
				this.experimentData.count = this.getExperiments.count;
			} catch {
				this.$store.commit("showNotification", "Oops! Something went wrong.");
			} finally {
				this.loading = false;
			}
		},

		async deleteExperiments(experimentId) {
			if (experimentId) {
				this.experimentsTobeDeleted = [experimentId];
			} else {
				this.experimentsTobeDeleted = this.selectedExperiments.map(
					(experiment) => experiment.uri.id
				);
			}
		},
		modalClosed(type) {
			if (type == "deletionCancelled") {
				this.experimentsTobeDeleted = [];
			} else if (type == "editExperiment") {
				this.fetchExperiment(this.experimentData.experimentEditId);
				this.fetchExperiments();
				this.experimentData.experimentEditId = -1;
			} else if (type == "experimentsDeleted") {
				this.experimentsTobeDeleted = [];
				this.selectedExperiments = [];
				this.fetchExperiments();
			}
		},
		async fetchExperiment(id) {
			this.loading = true;
			try {
				await this.$store.dispatch("fetchExperiment", id);
			} finally {
				this.loading = false;
			}
		},
		async copyToClipboard(experimentId) {
			try {
				await navigator.clipboard.writeText(experimentId);
				this.$store.commit(
					"showNotification",
					"Experiment ID copied to clipboard!"
				);
			} catch (err) {
				this.$store.commit(
					"showNotification",
					"Failed to copy ID. Please try again."
				);
			}
		},
		async editExperiment(experimentId) {
			this.experimentData.experimentEditId = experimentId;
		},
	},
};
</script>
<style scoped>
.personalization-switch {
	margin-top: 6px !important;
	margin-bottom: -10px !important;
}
.description-cell {
	max-width: 300px;
	white-space: normal;
	word-wrap: break-word;
}
.experiment-name-cell {
	display: flex;
	align-items: center;
	gap: 8px; /* Space between name and copy button */
}
</style>
